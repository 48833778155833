import React from 'react'
import { Popover } from 'antd'
import { getUserDetails } from '@/components/form/form-control-panel/form-actions/common/utils'
import style from './popup.module.sass'
import { MonthData } from './segment'

type Props = {
	monthData: MonthData
}
export const Popup = ({ monthData }: Props) => {
	const types = [
		{ title: 'Escalation', data: monthData.escalation },
		{ title: 'Overdue', data: monthData.overdue },
		{ title: 'Completed', data: monthData.completedOn },
		{ title: 'Upcoming', data: monthData.dueInFuture },
		{ title: 'Waiting for', data: monthData.span },
		{ title: 'Created', data: monthData.created },
	]
	const toShow = types.filter((t) => Object.keys(t.data).length > 0)

	return (
		<Popover
			title={''}
			open
			content={
				toShow.length === 0 ?
					<div>No activity</div>
				:	toShow.map((t) => (
						<div key={t.title}>
							<b>{t.title}</b>
							<ul style={{ marginTop: 0 }}>
								{Object.values(t.data).map((a) => {
									const details = getUserDetails(a)
									return <li key={`${t.title}-${a.id}`}>{details.name}</li>
								})}
							</ul>
						</div>
					))
			}
		/>
	)
}
