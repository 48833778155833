import React from 'react'
import { FieldFieldTypeChoices, FieldTableFieldTypeChoices } from '@/__gen/types'
import { isAnswerValid } from '@/state/answer/utils'
import { FieldType, TableFieldType } from '@/state/main-form/types'
import { useAppSelector } from '@/utils/hooks'
import commonStyle from './commonStyle.module.sass'
import { selectAnswer } from '@/state/answer/selectors'
import { Alert } from 'antd'

type Props = {
	answerId: string
	field: TableFieldType | FieldType
}

export const InvalidAnswer = ({ field, answerId }: Props) => {
	if (field.type == FieldFieldTypeChoices.Table) {
		throw Error('Table fields should not be passed to this function ' + field.title)
	}

	const isValid = useAppSelector((s) => !isAnswerValid(field.type as FieldTableFieldTypeChoices, selectAnswer(s, answerId)))

	return (
		<div className={commonStyle.errorRow}>
			<Alert
				message={field.title}
				type={!isValid ? "error" : "success"}
			/>
		</div>
	)
}
