import React from 'react'
import gql from "graphql-tag"
import c from "classnames"
import { Formik, Field } from 'formik'
import { TextField } from '@/components/common/formik'
import { SpinnerButton } from '@/components/common/button'
import * as Yup from 'yup'
import { Tooltip } from 'antd'
import { useGlobalContext, GlobalContextProps, useRouter } from '@/components/context'
import { useAppDispatch } from '@/utils/hooks'
import { teamMetricsDefaultUrl } from '@/utils/url'
import { showNotification } from '@/state/user-interface'
import { apolloClient } from '@/state/apollo-client'
import buttonStyle from '@sass/common/button.module.sass'
import generalStyle from '@sass/common/general.module.sass'
import formStyle from '@sass/common/form.module.sass'
import { useTeamSettingsUpdateMutation } from './__gen/team-form'


gql`
	mutation teamSettingsUpdate($id: ID!, $name: String!, $reminderFrequencyDays: Int, $active: Boolean!) {
		team_Update(input: {id: $id, name: $name, reminderFrequencyDays: $reminderFrequencyDays, active: $active}) {
			object {
				id
				name
				reminderFrequencyDays
			}
		}
	}
`

type Props = {
	team: NonNullable<GlobalContextProps["currentTeam"]>
}

export default ({team}: Props) => {
	const [updateTeam, {}] = useTeamSettingsUpdateMutation()
	const globalContext = useGlobalContext()
	const router = useRouter()
	const dispatch = useAppDispatch()

	return (
		<>
			<Formik
				initialValues={{
					name: team.name,
					reminderFrequencyDays: team.reminderFrequencyDays,
				}}
				validationSchema={Yup.object().shape({
					name: Yup.string().required('Required'),
					reminderFrequencyDays: Yup.number().min(1, "Frequency cannot be less than a day").max(365, "Frequency cannot be more than 1 year").required('Required'),
				})}
				onSubmit={(values, actions) => {
					updateTeam({variables: {id: team.id, active: true, ...values}}).then(() => {
					}).catch((r) => {
						console.error(r)
						actions.setFieldError('all', 'There was a problem saving data')
					}).finally(() => {
						actions.setSubmitting(false)
					})
				}}
			>
				{({ errors, isSubmitting, handleSubmit }) => (
					<form onSubmit={handleSubmit} className={formStyle.commonForm}>
						<h3 className={formStyle.commonFormTitle}>{globalContext.currentModule.teamWord} settings</h3>
						<Field
							name="name"
							title="Name"
							placeholder="Name"
							component={TextField}
						/>

						<Tooltip
							placement={"bottom"}
							title={globalContext.user.topLevelManager ? "" : "This field is only editable by admins"}
						>
							<div style={{width: '100%'}}> {/* Complains about findDomNode if not in div */}
								<Field
									className={generalStyle.numberInput}
									name="reminderFrequencyDays"
									title="Reminde Frequency (days)"
									placeholder="Reminder Frequency"
									component={TextField}
									disabled={!globalContext.user.topLevelManager}
									type="number"
								/>
							</div>
						</Tooltip>

						<div className={generalStyle.error}>{
							//@ts-ignore
							errors.all}</div>

						<div
							style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}
							className={formStyle.formButton}
						>
							{globalContext.user.topLevelManager &&
								<button
									className={c(buttonStyle.small, buttonStyle.red)}
									onClick={async () => {
										if (window.confirm(`Are you sure you want to archive ${team.name}?`)) {
											await updateTeam({variables: {...team, active: false}})
											router.history.push(teamMetricsDefaultUrl(globalContext))
											apolloClient.resetStore()
											dispatch(showNotification({
												'content': `${team.name} archived`
											}))
										}
									}}
									type="button"
								>
									Archive
								</button>
							}

							<SpinnerButton loading={isSubmitting} type="submit">
								Save
							</SpinnerButton>
						</div>
					</form>
				)}
			</Formik>
		</>
	)
}