import { PayloadAction, createAsyncThunk, unwrapResult } from '@reduxjs/toolkit'
import _debounce from 'lodash/debounce'
import _defer from 'lodash/defer'
import _partition from 'lodash/partition'
import _sortBy from 'lodash/sortBy'
import { FORM_ANSWERS_AUTH, FORM_ANSWERS_PUBLIC } from '@/state/answer/get-form-answers'
import { SAVE_ANSWERS_AUTH } from '@/state/answer/save-answers-auth'
import { SUBMIT_PUBLIC_FORM } from '@/state/answer/submit-public-form'
import { apolloClient } from '@/state/apollo-client'
import { GetFormParams } from '@/state/main-form/thunks'
import { AppDispatch, RootState } from '@/state/redux-store'
import history from '@/utils/history'
import { publicFormSuccessUrl } from '@/utils/url'
import { toggleFormValidationSideBar } from '../user-interface'
import {
	FormAnswersAuthQuery,
	FormAnswersAuthQueryVariables,
	FormAnswersPublicQuery,
} from './get-form-answers/__gen'
import { answerSlice } from './reducer'
import { SaveAnswersAuthMutation, SaveAnswersAuthMutationVariables } from './save-answers-auth/__gen'
import { SAVE_ANSWERS_PUBLIC } from './save-answers-public'
import { SaveAnswersPublicMutation, SaveAnswersPublicMutationVariables } from './save-answers-public/__gen'
import {
	selectAnswersForField,
	selectInvalidAnswers,
	selectMissingMandatoryFields,
	selectObjectIdMap,
} from './selectors'
import { PublicFormSubmitMutation, PublicFormSubmitMutationVariables } from './submit-public-form/__gen'
import { GenericAnswer, SaveDebounce, SaveImmediate, SaveType, ToSaveAnswer } from './types'
import {
	addBlockNavigationListener,
	createMissingAnswers,
	extractSaveAnswerPubResponse,
	extractSaveAnswerResponse,
	getSaveAnswerVariables,
	waitForSave,
} from './utils'

export const addInitialAnswersThunk = () => (dispatch: AppDispatch, getState: () => RootState) => {
	const state = getState()
	if (!state.mainForm.data) {
		return
	}
	const objectIdMap = selectObjectIdMap(state)
	dispatch(
		answerSlice.actions.addInitialAnswers({
			objectIdMap,
			fields: state.mainForm.data.fields,
			initialAnswers: state.mainForm.data.initialAnswers,
		}),
	)
	const missingAnswers = createMissingAnswers({ state, objectIdMap })
	if (missingAnswers.length > 0) {
		dispatch(answerSlice.actions.addMissingAnswers({ answers: missingAnswers }))
	}
}

export const addAnswerRowThunk = (fieldId: string) => (dispatch: AppDispatch, getState: () => RootState) => {
	const state = getState()
	if (!state.mainForm.data) {
		throw Error('mainForm.data should not be empty here!')
	}
	const objectIdMap = selectObjectIdMap(state)
	dispatch(
		answerSlice.actions.addAnswerRow({
			objectIdMap,
			mainField: state.mainForm.data.fields[fieldId],
		}),
	)
	queueSave(state, dispatch, SaveDebounce)
}

export const getAnswersThunk = (params: GetFormParams) => async (dispatch: AppDispatch) => {
	let promise =
		'publicFormCode' in params ?
			dispatch(getAnswersPublicThunk(params))
		:	dispatch(getAnswersAuthThunk(params))

	try {
		const result =await promise
		const here = unwrapResult(result)
		dispatch(addInitialAnswersThunk())
	} catch (error) {
		if (error == DOUBLE_FORM_SWITCH_ERROR) {
			return
		}
		throw error
	}
}

export const DOUBLE_FORM_SWITCH_ERROR = 'Ignorring answers due to form switch'

export const getAnswersAuthThunk = createAsyncThunk<
	{ answers: GenericAnswer[] },
	{ recordId: string; formId: string },
	{ state: RootState }
>('answers/getAuth', async ({ recordId, formId }, { dispatch, rejectWithValue, getState }) => {
	const { error, data } = await apolloClient.query<FormAnswersAuthQuery, FormAnswersAuthQueryVariables>({
		query: FORM_ANSWERS_AUTH,
		variables: { record: recordId, form: formId },
		fetchPolicy: 'no-cache',
	})
	const state = getState()
	if (formId !== state.mainForm.data?.form.id) {
		// When switching between forms quickly, we need to ignore the previous fetch
		return rejectWithValue(DOUBLE_FORM_SWITCH_ERROR)
	}
	if (
		error ||
		!data?.answerBoolean_List?.objects ||
		!data?.answerDate_List?.objects ||
		!data?.answerDecimal_List?.objects ||
		!data?.answerFile_List?.objects ||
		!data?.answerText_List?.objects
	) {
		return rejectWithValue(null)
	}
	return {
		answers: [
			...data.answerBoolean_List.objects,
			...data.answerDate_List.objects,
			...data.answerDecimal_List.objects,
			...data.answerFile_List.objects,
			...data.answerText_List.objects,
		],
	}
})

export const getAnswersPublicThunk = createAsyncThunk<
	{ answers: GenericAnswer[] },
	{ publicFormCode: string },
	{ state: RootState }
>(
	'answers/getPublic',
	async ({ publicFormCode }: { publicFormCode: string }, { dispatch, rejectWithValue }) => {
		const { error, data } = await apolloClient.query<FormAnswersPublicQuery, {}>({
			query: FORM_ANSWERS_PUBLIC,
			context: { headers: { 'X-Public-Form-Code': publicFormCode } },
			fetchPolicy: 'no-cache',
		})
		if (
			error ||
			!data?.answerBooleanPub_List?.objects ||
			!data?.answerDatePub_List?.objects ||
			!data?.answerDecimalPub_List?.objects ||
			!data?.answerFilePub_List?.objects ||
			!data?.answerTextPub_List?.objects
		) {
			return rejectWithValue(null)
		}
		return {
			answers: [
				...data.answerBooleanPub_List.objects,
				...data.answerDatePub_List.objects,
				...data.answerDecimalPub_List.objects,
				...data.answerFilePub_List.objects,
				...data.answerTextPub_List.objects,
			],
		}
	},
)

export const saveAnswersThunk = createAsyncThunk<
	GenericAnswer[],
	void,
	{ state: RootState; dispatch: AppDispatch }
>('answers/save', async (__, { dispatch, getState }) => {
	let state: RootState = getState()
	if (!state.mainForm.data) {
		alert('Unable to save answers. Form not loaded.')
		throw Error('Unable to save answers. Form not loaded.')
	}
	const variables = getSaveAnswerVariables(state)

	let objects = []
	if (state.mainForm.data.publicForm?.code) {
		let resp = await apolloClient.query<SaveAnswersPublicMutation, SaveAnswersPublicMutationVariables>({
			query: SAVE_ANSWERS_PUBLIC,
			variables,
			fetchPolicy: 'no-cache',
			context: { headers: { 'X-Public-Form-Code': state.mainForm.data.publicForm.code } },
		})
		objects = extractSaveAnswerPubResponse(resp)
	} else {
		let resp = await apolloClient.query<SaveAnswersAuthMutation, SaveAnswersAuthMutationVariables>({
			query: SAVE_ANSWERS_AUTH,
			variables,
			fetchPolicy: 'no-cache',
		})
		objects = extractSaveAnswerResponse(resp)
	}

	return objects
})

const debounceSave = _debounce((dispatch) => {
	dispatch(saveAnswersThunk())
}, 1000)

export const saveFulfilledListener = (state: RootState, dispatch: AppDispatch) => {
	if (state.answers.saving) {
		// Because debounce and the listener aren't in sync, this can happen
		// TODO: Make sure calls to saveAnswersThunk are strictly serialized
		queueSave(state, dispatch, SaveDebounce)
	} else if (state.answers.queuedSave) {
		queueSave(state, dispatch, state.answers.queuedSave)
	} else if (state.answers.queuedSubmit) {
		// If this save was triggered by clicking submit, run submit after save
		dispatch(doPublicFormSubmit(state.answers.queuedSubmit))
	}
}

const queueSave = (state: RootState, dispatch: AppDispatch, saveType: SaveType) => {
	if (state.answers.saving) {
		dispatch(answerSlice.actions.setQueuedSave(saveType))
	} else if (saveType === SaveImmediate) {
		// Changes were made while this request was in flight, so save again when
		// after this request is finished
		dispatch(saveAnswersThunk())
	} else if (saveType === SaveDebounce) {
		debounceSave(dispatch)
	}
}

const preUpdateGuardAndValidation = ({
	getState,
	toUpdateAnswers,
}: {
	getState: () => RootState
	toUpdateAnswers: ToSaveAnswer[]
}) => {
	// We don't want to save invalid answers to the backend (ie if we save an email, it should be valid)
	const state = getState()
	addBlockNavigationListener(getState)
	const invalidAnswers = selectInvalidAnswers(state)

	const updateInvalidAnswers = toUpdateAnswers.filter((a) => invalidAnswers.find((i) => i.id === a.id))

	return updateInvalidAnswers.length > 0
}

export type UpdateAnswerParams = { answers: ToSaveAnswer[]; saveType?: SaveType }

export const updateAnswersThunk =
	({ answers, saveType = SaveDebounce }: UpdateAnswerParams) =>
	(dispatch: AppDispatch, getState: () => RootState) => {
		dispatch(answerSlice.actions.updateAnswers({ answers, saveType }))
		const state = getState()
		if (preUpdateGuardAndValidation({ getState: getState, toUpdateAnswers: answers })) {
			return
		}

		queueSave(state, dispatch, saveType)
	}

export const validateFlushAndWaitForSaveThunk = createAsyncThunk<
	{ answersValid: boolean},
	{ skipMissingAnswerCheck?: boolean },
	{ state: RootState; dispatch: AppDispatch }
>(
	'answers/validateFlushAndWaitForSaveThunk',
	async (config = {}, { dispatch, getState, fulfillWithValue, rejectWithValue }) => {
		const { skipMissingAnswerCheck = false } = config
		const state = getState()

		if (!skipMissingAnswerCheck) {
			const { valid } = dispatch(validateAnswers())
			dispatch(toggleFormValidationSideBar(!valid))
			if (!valid) {
				return fulfillWithValue({ answersValid: false })
			}
		}

		// Could have dirty changes that aren't saving (due to initial answers)
		const changesNeedSaving = !state.answers.touched && Object.keys(state.answers.status.dirtyQueued).length
		if (changesNeedSaving) {
			dispatch(updateAnswersThunk({ answers: [], saveType: SaveImmediate }))
		}
		const { success } = await waitForSave(getState)
		if (!success) {
			return rejectWithValue({ answersValid: false })
		}
		return fulfillWithValue({ answersValid: true })
	},
)

export const reorderAnswersThunk =
	({ rankChanges, fieldId }: { rankChanges: { [rank: number]: number }; fieldId: string }) =>
	(dispatch: AppDispatch, getState: () => RootState) => {
		const state = getState()
		const answers = []
		for (const answer of Object.values(state.answers.objects)) {
			if (
				answer.fieldId == fieldId &&
				answer.rank in rankChanges &&
				!answer.purgeable &&
				answer.rank != rankChanges[answer.rank]
			) {
				answers.push({
					__typename: answer.__typename,
					id: answer.id,
					rank: rankChanges[answer.rank],
				})
			}
		}
		dispatch(
			updateAnswersThunk({
				answers,
				saveType: SaveImmediate, // Save immediately as we will set
			}),
		)
	}

export type ToggleAnswerRowParams = { rank: number; fieldId: string; deactivated: string | null }
export const toggleAnswerRowDeactivatedThunk =
	({ rank, fieldId, deactivated }: ToggleAnswerRowParams) =>
	(dispatch: AppDispatch, getState: () => RootState) => {
		const state = getState()

		let fieldAnswers = selectAnswersForField(state, fieldId).filter((a) => a.rank == rank)
		let answers = _sortBy(
			fieldAnswers.filter((a) => !!a.deactivated != !!deactivated),
			['deactivated'],
		).reverse()

		if (!deactivated) {
			// only reactivate the most recent answer per table fields
			const tableFields = new Set()
			answers = answers.filter((a) => {
				if (tableFields.has(a.tableFieldId)) {
					return false
				}
				tableFields.add(a.tableFieldId)
				return true
			})
		}

		dispatch(
			updateAnswersThunk({
				answers: answers.map((a) => ({ ...a, deactivated })),
			}),
		)
	}

type ValidateAnswerParams = { skipMissingAnswerCheck?: boolean }

export const validateAnswers =
	({ skipMissingAnswerCheck = false }: ValidateAnswerParams = {}) =>
	(dispatch: AppDispatch, getState: () => RootState) => {
		let state = getState()

		const invalidAnswers = selectInvalidAnswers(state)
		const missingFields = skipMissingAnswerCheck ? [] : selectMissingMandatoryFields(state)

		const valid = invalidAnswers.length === 0 && missingFields.length === 0

		return { valid }
	}

export const showErrorsAndFlush = (...params: Parameters<typeof validateFlushAndWaitForSaveThunk>) => async (dispatch: AppDispatch, getState: () => RootState) => {
	const result = await dispatch(validateFlushAndWaitForSaveThunk(...params))

	try {
		const valid = unwrapResult(result)
		return valid.answersValid
	} catch {
		return false
	}
}

export const doPublicFormSubmit = createAsyncThunk<
	{ success: boolean },
	{ submitterEmail: string },
	{ state: RootState; dispatch: AppDispatch }
>('answers/submit', async ({ submitterEmail }, { dispatch, getState, rejectWithValue }) => {
	const valid = await dispatch(showErrorsAndFlush({}))
	if (!valid) {
		return rejectWithValue({ answersValid: false })
	}

	let state = getState()
	if (state.answers.saving || Object.keys(state.answers.status.dirtyQueued).length > 0) {
		dispatch(answerSlice.actions.setQueuedSubmit({ submitterEmail }))
	}

	const { error, data } = await apolloClient.query<
		PublicFormSubmitMutation,
		PublicFormSubmitMutationVariables
	>({
		query: SUBMIT_PUBLIC_FORM,
		variables: {
			submitterEmail: submitterEmail || '',
			publicFormCode: state.mainForm.data!.publicForm!.code,
		},
		fetchPolicy: 'no-cache',
	})

	if (data.publicForm_Submit?.success) {
		history.push(publicFormSuccessUrl())
		return { success: true }
	} else {
		alert(
			'Error submitting public form. Please reload the page and try again. If this issue persists please contact support.',
		)
		return { success: false }
	}
})
