import * as Types from '@/__gen/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FormActionDashboardDataQueryVariables = Types.Exact<{
  moduleId: Types.Scalars['ID']['input'];
  teamId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  offset: Types.Scalars['Int']['input'];
  yearMonth: Types.Scalars['String']['input'];
  hasActions?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
  actionDashboardSearch: Types.Scalars['String']['input'];
}>;


export type FormActionDashboardDataQuery = { __typename: 'PharosQuery', record_List: { __typename: 'RecordTypeListBase', objects: Array<{ __typename: 'RecordType', id: string, name: string, template: { __typename: 'TemplateType', id: string }, formActions: Array<{ __typename: 'FormActionType', id: string, completedOn: string | null, created: string, deleted: boolean, assignedAnonEmail: string, assignedAnonName: string, record: { __typename: 'RecordType', id: string }, form: { __typename: 'FormType', id: string }, assignedUser: { __typename: 'UserType', id: string, firstName: string, lastName: string, email: string } | null, assignedAnonEmailAnswer: { __typename: 'AnswerTextPubType', id: string, content: string | null, contactName: string | null } | null }>, formActionSchedules: Array<{ __typename: 'FormActionScheduleType', id: string, firstDueAt: string, assignedAnonEmail: string, assignedAnonName: string, dueAt: string, unitAmount: number, unitName: Types.FormActionFormActionScheduleUnitNameChoices, assignedAnonEmailAnswer: { __typename: 'AnswerTextPubType', id: string, content: string | null, contactName: string | null } | null, assignedUser: { __typename: 'UserType', id: string, firstName: string, lastName: string, email: string } | null, record: { __typename: 'RecordType', id: string }, form: { __typename: 'FormType', id: string } }>, formActionEscalationEvents: Array<{ __typename: 'FormActionEscalationEventType', id: string, created: string, assignedAnonEmail: string, assignedAnonName: string, record: { __typename: 'RecordType', id: string }, form: { __typename: 'FormType', id: string }, assignedUser: { __typename: 'UserType', id: string, firstName: string, lastName: string, email: string } | null, assignedAnonEmailAnswer: { __typename: 'AnswerTextPubType', id: string, content: string | null, contactName: string | null } | null }> }>, pageInfo: { __typename: 'PageInfo', hasNextPage: boolean | null, total: number | null } } | null };


export const FormActionDashboardDataDocument = gql`
    query formActionDashboardData($moduleId: ID!, $teamId: ID, $offset: Int!, $yearMonth: String!, $hasActions: Boolean, $actionDashboardSearch: String!) {
  record_List(
    limit: 3
    offset: $offset
    module: $moduleId
    team: $teamId
    archived: false
    hasActions: $hasActions
    actionDashboardSearch: $actionDashboardSearch
    orderBy: [{field: name, modifiers: [CASE_INSENSITIVE]}]
  ) {
    objects {
      id
      name
      template {
        id
      }
      formActions(yearMonth: $yearMonth) {
        id
        completedOn
        created
        deleted
        record {
          id
        }
        form {
          id
        }
        assignedUser {
          id
          firstName
          lastName
          email
        }
        assignedAnonEmail
        assignedAnonName
        assignedAnonEmailAnswer {
          id
          content
          contactName
        }
      }
      formActionSchedules(yearMonth: $yearMonth) {
        id
        firstDueAt
        assignedAnonEmail
        assignedAnonName
        dueAt
        unitAmount
        unitName
        assignedAnonEmailAnswer {
          id
          content
          contactName
        }
        assignedUser {
          id
          firstName
          lastName
          email
        }
        record {
          id
        }
        form {
          id
        }
      }
      formActionEscalationEvents(yearMonth: $yearMonth) {
        id
        created
        record {
          id
        }
        form {
          id
        }
        assignedUser {
          id
          firstName
          lastName
          email
        }
        assignedAnonEmail
        assignedAnonName
        assignedAnonEmailAnswer {
          id
          content
          contactName
        }
      }
    }
    pageInfo {
      hasNextPage
      total
    }
  }
}
    `;

/**
 * __useFormActionDashboardDataQuery__
 *
 * To run a query within a React component, call `useFormActionDashboardDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useFormActionDashboardDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFormActionDashboardDataQuery({
 *   variables: {
 *      moduleId: // value for 'moduleId'
 *      teamId: // value for 'teamId'
 *      offset: // value for 'offset'
 *      yearMonth: // value for 'yearMonth'
 *      hasActions: // value for 'hasActions'
 *      actionDashboardSearch: // value for 'actionDashboardSearch'
 *   },
 * });
 */
export function useFormActionDashboardDataQuery(baseOptions: Apollo.QueryHookOptions<FormActionDashboardDataQuery, FormActionDashboardDataQueryVariables> & ({ variables: FormActionDashboardDataQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FormActionDashboardDataQuery, FormActionDashboardDataQueryVariables>(FormActionDashboardDataDocument, options);
      }
export function useFormActionDashboardDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FormActionDashboardDataQuery, FormActionDashboardDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FormActionDashboardDataQuery, FormActionDashboardDataQueryVariables>(FormActionDashboardDataDocument, options);
        }
export function useFormActionDashboardDataSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FormActionDashboardDataQuery, FormActionDashboardDataQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FormActionDashboardDataQuery, FormActionDashboardDataQueryVariables>(FormActionDashboardDataDocument, options);
        }
export type FormActionDashboardDataQueryHookResult = ReturnType<typeof useFormActionDashboardDataQuery>;
export type FormActionDashboardDataLazyQueryHookResult = ReturnType<typeof useFormActionDashboardDataLazyQuery>;
export type FormActionDashboardDataSuspenseQueryHookResult = ReturnType<typeof useFormActionDashboardDataSuspenseQuery>;
export type FormActionDashboardDataQueryResult = Apollo.QueryResult<FormActionDashboardDataQuery, FormActionDashboardDataQueryVariables>;