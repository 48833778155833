import React, { useState } from 'react'
import _pick from 'lodash/pick'
import { DatePicker, Modal, Select, Typography } from 'antd'
import dateFnsGenerateConfig from 'rc-picker/lib/generate/dateFns'

import { FormActionFormActionScheduleUnitNameChoices } from '@/__gen/types'
import { TEMP_ID_PREFIX } from '@/utils/constants'
import buttonStyle from '@sass/common/button.module.sass'
import generalStyle from '@sass/common/general.module.sass'
import { emptyAssignee } from '../../common/select-assignee'
import { Assignee, SelectAssignee, isAssigneeValid } from '../../common/select-assignee'
import { CYCLE_MAP, getStringFromCycle } from '../common'
import { MiscDetails, SetMiscDetails, emptyDetails } from '../../common/misc-details'

const { Title } = Typography


export type AddFormState = {
	id: string
	deleted: boolean,
	dueAt: Date,
	unitAmount: number,
	unitName: FormActionFormActionScheduleUnitNameChoices,
} & Assignee & MiscDetails

export type PartialScheduleState = { [K in keyof AddFormState]?: AddFormState[K] }

type Props = {
	initialState: AddFormState | null
	stageChange: (s: AddFormState | null) => void
	show: boolean
	setShow: (show: boolean) => void
}

const DateFnsDatePicker = DatePicker.generatePicker<Date>(dateFnsGenerateConfig);



export const Add = ({ initialState, stageChange, show, setShow }: Props) => {
	const [formState, setFormState] = useState<AddFormState>({
		id: `${TEMP_ID_PREFIX}${Math.floor(Math.random() * 9999999)}`,
		deleted: false,
		dueAt: new Date(),
		unitAmount: 12,
		unitName: FormActionFormActionScheduleUnitNameChoices.Months,
		...emptyAssignee(),
		...emptyDetails(),
	})
	const [error, setError] = useState<boolean>(false)
	const onDatePick = (date: Date | null, dateString: string | string[]) => {
		if (date) {
			// Don't allow null dates
			setFormState({ ...formState, dueAt: date })
		}
	}

	const onCycleChange = (cycleString: string) => {
		const cycleObj = CYCLE_MAP[cycleString]
		if (cycleObj) {
			setFormState({ ...formState, ...cycleObj })
		}
	}

	const commit = () => {
		if (!isAssigneeValid(formState)) {
			setError(true)
			return
		}
		stageChange(formState)
		setShow(false)
	}

	return (
		<Modal
			title="Add Schedule"
			open={show}
			maskClosable
			onCancel={() => setShow(false)}
			footer={null}
			width={800}
		>
			<Title level={5}>Start Date</Title>
			<DateFnsDatePicker onChange={onDatePick} value={formState.dueAt} placeholder='Start Date' />
			<br /><br />
			<Title level={5}>Period</Title>
			<Select
				style={{ width: 100 }}
				onChange={onCycleChange}
				value={getStringFromCycle(formState)}
				options={Object.entries(CYCLE_MAP).map(([key, value], i) => ({ value: getStringFromCycle(value), label: value.label }))}
			/>
			<br /><br />
			<SelectAssignee
				update={a => setFormState({ ...formState, ...a })}
				assignee={formState}
				disabledUsers={[]}
				disabledAnswers={[]}
			/>
			<SetMiscDetails
					update={(a) => {setFormState({...formState, ...a})}}
					details={formState}
				/>
			{error && <div className={generalStyle.error}>Please ensure all fields are correct</div>}
			<button className={buttonStyle.primarySolid} onClick={commit}>Save</button>
		</Modal>
	)
}
