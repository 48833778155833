import React, { useRef } from 'react'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { useAppSelector } from '@/utils/hooks'
import { AnswerErrorDisplay } from './answer-error-display'
import style from './index.module.sass'

const animationEnterTime = parseInt(style.animationEnterMs)
const animationExitTime = parseInt(style.animationExitMs)

export const FormValidation = () => {
	const show = useAppSelector((state) => state.userInterface.formValidationSidebar.show)
	const nodeRef = useRef(null)
	return (
		<TransitionGroup>
			{show && (
				<CSSTransition
					nodeRef={nodeRef}
					classNames="form-validation-sidebar"
					timeout={{enter: animationEnterTime, exit: animationExitTime}}
					in={!!show}
				>
					<div ref={nodeRef}>
						{/* Show is an integer that increments. This replaces the component and recalculates
						the set of invalid fields whenever a user re-submits. */}
						<AnswerErrorDisplay key={show} />
					</div>
				</CSSTransition>
			)}
		</TransitionGroup>
	)
}
