import React, { useState } from 'react'
import { Modal } from 'antd'
import { SpinnerButton } from '@/components/common/button'
import buttonStyle from '@sass/common/button.module.sass'
import commonStyle from '../../common/common.module.sass'
import { ModeType, RowType, getRecordFormKey } from '../../common/const'
import { DataItem } from '../../common/data-item'
import { Row } from '../../common/row'
import { FormActionDashboardToUpdateQuery } from './__gen'
import { EditHeaders } from './headers'
import style from './index.module.sass'
import { ScheduleRow, ScheduleRowProps } from './schedule-row'
import { RowFields, useBulkSaveSchedules } from './use-bulk-save-schedules'

type Props = {
	rows: RowType[]
	schedules: Record<string, RowFields>
	answerOptions: Record<string, NonNullable<FormActionDashboardToUpdateQuery['formActionContact_List']>>
	setMode: (mode: ModeType) => void
	resetList: () => void
}

export const TableInner = ({ rows, schedules, answerOptions, setMode, resetList }: Props) => {
	const [rowStates, setRowStates] = useState<{
		[id: string]: RowFields
	}>(() => {
		// Initialize from row.data if we have actual existing data
		const initial: { [id: string]: RowFields } = {}
		rows.forEach((r) => {
			const key = getRecordFormKey(r)
			const schedule = schedules[key]
			const fields =
				schedule ||
				({
					dueAt: null,
					unitAmount: null,
					unitName: null,
					requestSignature: false,
					userSuppliedContext: '',
					assignedAnonEmailAnswer: null,
					escalationEmailAnswer: null,
					escalationDeltaDays: null,
				} satisfies RowFields)

			initial[getRecordFormKey(r)] = fields
		})
		return initial
	})

	// Helpers to handle changes to row fields:
	const updateRowField: ScheduleRowProps['updateRowField'] = ({ rowId, data }) => {
		setRowStates((prev) => ({
			...prev,
			[rowId]: {
				...prev[rowId],
				...data,
			},
		}))
	}

	const [showSaveCompletedModal, setShowSaveCompletedModal] = useState(false)
	const { validRows, save, savingSet, errorMap, showErrors, setShowErrors } = useBulkSaveSchedules({
		rows,
		rowStates,
		setShowSaveCompletedModal,
	})

	let prev = ''

	let elements: React.ReactNode[] = []
	rows.forEach((row) => {
		const rowId = getRecordFormKey(row)
		const fields = rowStates[rowId]
		const recordAnswerOptions = answerOptions[row.record.id]
		const show = prev != row.record.id
		prev = row.record.id
		if (!fields) {
			elements.push(<div key={rowId}>Error no fields</div>)
			return
		}
		if (show) {
			elements.push(
				<Row
					key={row.record.id}
					left={
						<div className={style.recordName}>
							<b>&nbsp;&nbsp;{row.record.name || <i>No Name</i>}</b>
						</div>
					}
					right={null}
				/>,
			)
		}
		elements.push(
			<ScheduleRow
				key={rowId}
				row={row}
				fields={rowStates[rowId]}
				saving={savingSet.has(rowId)}
				error={errorMap[rowId]}
				saved={savingSet.size > 0 && !savingSet.has(rowId)}
				updateRowField={updateRowField}
				recordAnswerOptions={recordAnswerOptions}
			/>,
		)
	})

	return (
		<div className={style.container}>
			<div className={commonStyle.controlsRow}>
				<DataItem
					left={'Valid Rows'}
					right={
						<>
							{validRows} / {rows.length}
						</>
					}
				/>
				<div className={commonStyle.controlButtons}>
					<button className={buttonStyle.greySolid} onClick={() => setMode('select')}>
						Back
					</button>
					<SpinnerButton disabled={savingSet.size > 0} loading={savingSet.size > 0} onClick={save}>
						Save
					</SpinnerButton>
				</div>
			</div>

			<EditHeaders rows={rows} fields={rowStates} setFields={setRowStates} answerOptions={answerOptions} />
			{elements}

			{showSaveCompletedModal && (
				<Modal
					title="Save Completed"
					open
					onOk={resetList}
					onCancel={() => setShowSaveCompletedModal(false)}
					okText="View list"
				>
					<p>Your changes have been saved successfully.</p>
				</Modal>
			)}
			{showErrors && (
				<Modal title="Error" open footer={null} onCancel={() => setShowErrors(false)}>
					<ul>
						{Object.values(errorMap).map((e) => (
							<li key={e}>{e}</li>
						))}
					</ul>
				</Modal>
			)}
		</div>
	)
}
