import React from 'react'
import style from './index.module.sass'

export type RightEditRowProps = {
	dueAt: React.ReactNode
	duration: React.ReactNode
	requestSignature: React.ReactNode
	userSuppliedContext: React.ReactNode
	assignedAnonEmailAnswer: React.ReactNode
	escalationEmail: React.ReactNode
	escalationDeltaDays: React.ReactNode
}

export const RightEditRow = ({
	dueAt,
	duration,
	requestSignature,
	userSuppliedContext,
	assignedAnonEmailAnswer,
	escalationEmail,
	escalationDeltaDays,
}: RightEditRowProps) => {
	return (
		<div className={style.container}>
			<div className={style.dueAt}>{dueAt}</div>
			<div className={style.duration}>{duration}</div>
			<div className={style.assignedAnonEmailAnswer}>{assignedAnonEmailAnswer}</div>
			<div className={style.requestSignature}>{requestSignature}</div>
			<div className={style.userSuppliedContext}>{userSuppliedContext}</div>
			<div className={style.escalationEmail}>{escalationEmail}</div>
			<div className={style.escalationDeltaDays}>{escalationDeltaDays}</div>
		</div>
	)
}
