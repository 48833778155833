import React from 'react'
import { AppDispatch } from '@/state/redux-store'
import { showNotification } from '@/state/user-interface'
import iconStyle from '@sass/common/icon.module.sass'

const copyStringToClipboard = (link: string | null, reduxDispatch: AppDispatch) => {
	if (link) {
		navigator.clipboard.writeText(link)
		reduxDispatch(showNotification({ content: `Copied to clipboard` }))
	} else {
		reduxDispatch(showNotification({ content: `Copied to clipboard`, type: 'error' }))
	}
}

type Props = {
	link: string | null
	reduxDispatch: AppDispatch
}

export const copyLinkAvailable = (formAction: {
	completedOn: string | null
	assignedUser: { id: string } | null
}) => !formAction.completedOn && !formAction.assignedUser

export const CopyLinkText = ({ link, reduxDispatch }: Props) => (
	<div className={iconStyle.link} onClick={() => (link ? copyStringToClipboard(link, reduxDispatch) : null)}>
		{link ? 'Copy link' : 'Link available after saving'}
	</div>
)
