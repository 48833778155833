import React, { useCallback } from 'react'
import { Route } from 'react-router-dom'

import { useFormContext } from '@/state/main-form/reducer'
import { fromCompleteActionUrl, publicFormCompleteActionUrl } from '@/utils/url'
import { useGlobalContext, useRouter } from '@/components/context'
import buttonStyle from '@sass/common/button.module.sass'
import { CompleteModal } from './complete-modal'
import { useAppDispatch } from '@/utils/hooks'
import { showErrorsAndFlush } from '@/state/answer/thunks'


type Props = {
	formAction: {
		id: string;
		requestSignature: boolean;
		form: {
			pdfGenerators: {
				id: string
				name: string
			}[]
		}
		isScheduled: boolean
	}
}

export const CompleteFormActionButton = ({ formAction }: Props) => {
	const formContext = useFormContext()
	const globalContext = useGlobalContext()
	const router = useRouter()
	const code = formContext.publicForm?.code || null

	const dispatch = useAppDispatch()

	const url = code
		? publicFormCompleteActionUrl({publicFormCode: code, actionId: formAction.id})
		: fromCompleteActionUrl({
			...globalContext,
			recordId: formContext.record.id,
			formId: formContext.form.id,
			actionId: formAction.id,
		})

	const onClick = useCallback(async () => {
		const valid = await dispatch(showErrorsAndFlush({}))
		if (valid) {
			router.history.push(url)
		}
	}, [url, dispatch])

	return (
		<div>
			<button className={buttonStyle.primarySolid} onClick={onClick}>
				{formContext.form.publicLinkSubmitButtonText || 'Complete'}
			</button>

			<Route
				path={url}
				component={() => <CompleteModal formAction={formAction} />}
			/>
		</div>
	)
}