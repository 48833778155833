import React from 'react'
import { SingleValueProps, OptionProps, components, InputProps, StylesConfig } from 'react-select'
import c from 'classnames'
import iconStyle from '@sass/common/icon.module.sass'

import style from './option.module.sass'

export interface IOption {
	labelDisplay: React.ReactNode
	label: string
	color: string | null
}

export const OTHER_OPTION: IOption = {
	labelDisplay: (
		<div className={style.other}>
			<span className={c(iconStyle.pencil, style.pencil)} /> Other
		</div>
	),
	label: '_other',
	color: null,
}

const dot = (color: string | null = null) => ({
	alignItems: 'center',
	display: 'flex',
 
	':before': color ? {
	  backgroundColor: color,
	  borderRadius: 10,
	  content: '" "',
	  display: 'block',
	  marginRight: 8,
	  height: 14,
	  width: 14,
	}: {},
 });

export const colourStyles: StylesConfig<IOption> = {
	control: (styles) => ({ ...styles, cursor: 'pointer' }),
	option: (styles, { data, isDisabled, isFocused, isSelected }) => {
	  return {
		 ...styles,
		 cursor: 'pointer',
		 ...dot(data.color)
	  };
	},
	// input: (styles) => ({ ...styles, ...dot() }),
	singleValue: (styles, { data }) => ({
		...styles,
		...dot(data.color),
	}),
 }

export const Option: React.FC<OptionProps<IOption>> = (props) => {
	const { data } = props;
	return (
		<components.Option {...props}>
			{data.labelDisplay}
		</components.Option>
	);
 };
