import * as Types from '@/__gen/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FormActionManagerDeactivateMutationVariables = Types.Exact<{
  recordFormPairs: Array<Types.RecordFormPair>;
}>;


export type FormActionManagerDeactivateMutation = { __typename: 'PharosMutations', formAction_BulkDeactivate: { __typename: 'FormActionBulkDeactivateMutation', success: boolean | null, schedulesCount: number | null, actionsCount: number | null, escalationsCount: number | null } | null };


export const FormActionManagerDeactivateDocument = gql`
    mutation formActionManagerDeactivate($recordFormPairs: [RecordFormPair!]!) {
  formAction_BulkDeactivate(recordFormPairs: $recordFormPairs) {
    success
    schedulesCount
    actionsCount
    escalationsCount
  }
}
    `;
export type FormActionManagerDeactivateMutationFn = Apollo.MutationFunction<FormActionManagerDeactivateMutation, FormActionManagerDeactivateMutationVariables>;

/**
 * __useFormActionManagerDeactivateMutation__
 *
 * To run a mutation, you first call `useFormActionManagerDeactivateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFormActionManagerDeactivateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [formActionManagerDeactivateMutation, { data, loading, error }] = useFormActionManagerDeactivateMutation({
 *   variables: {
 *      recordFormPairs: // value for 'recordFormPairs'
 *   },
 * });
 */
export function useFormActionManagerDeactivateMutation(baseOptions?: Apollo.MutationHookOptions<FormActionManagerDeactivateMutation, FormActionManagerDeactivateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FormActionManagerDeactivateMutation, FormActionManagerDeactivateMutationVariables>(FormActionManagerDeactivateDocument, options);
      }
export type FormActionManagerDeactivateMutationHookResult = ReturnType<typeof useFormActionManagerDeactivateMutation>;
export type FormActionManagerDeactivateMutationResult = Apollo.MutationResult<FormActionManagerDeactivateMutation>;
export type FormActionManagerDeactivateMutationOptions = Apollo.BaseMutationOptions<FormActionManagerDeactivateMutation, FormActionManagerDeactivateMutationVariables>;