import { PayloadAction, createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'
import { selectInvalidAnswers, selectMissingMandatoryFields } from '../answer/selectors'
import { RootState } from '../redux-store'
import { getFormAuth, getFormPublic } from '../main-form/thunks'

type Notification = {
	type?: 'error' | 'neutral' | 'positive'
	content: string
}

type NotificationWithId = Notification & { id: number }

export type NotificationsState = NotificationWithId[]

export const notifications = createSlice({
	name: 'notifications',
	initialState: [] as NotificationsState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(showNotification.pending, (state, action) => [
				...state,
				{ ...action.meta.arg, id: Math.floor(Math.random() * 9999999) },
			])
			.addCase(showNotification.fulfilled, (state, action) => state.filter((_, i) => i != 0))
	},
})

export const showNotification = createAsyncThunk<void, Notification, { state: RootState }>(
	'notification/show',
	async (__, { dispatch, getState, rejectWithValue }) => {
		await new Promise((resolve) => setTimeout(resolve, 3000))
	},
)

///////////////////

export type PrintableDashboardState = boolean

export const printableDashboard = createSlice({
	name: 'printableDashboard',
	initialState: false,
	reducers: {
		togglePrintableDashboard: (state) => !state,
		disablePrintableDashboard: (state) => false,
	},
})

export const { togglePrintableDashboard, disablePrintableDashboard } = printableDashboard.actions

///////////////////

export type FullScreenFieldState = null | string

export const fullScreenField = createSlice({
	name: 'fullScreenField',
	initialState: null as FullScreenFieldState,
	reducers: {
		toggleFullScreenField: (state, action: PayloadAction<FullScreenFieldState>) => action.payload,
	},
})

export const { toggleFullScreenField } = fullScreenField.actions

/////////

export type HelpInfo = {
	title: string
	htmlContent: string
} | null

export const helpInfoField = createSlice({
	name: 'helpInfoField',
	initialState: null as HelpInfo,
	reducers: {
		toggleHelpInfoField: (state, action: PayloadAction<HelpInfo>) => action.payload,
	},
})

export const { toggleHelpInfoField } = helpInfoField.actions

////////////////

export type FormValidationSidebar = {
	show: number
}

export const formValidationSidebar = createSlice({
	name: 'formValidationSidebar',
	initialState: { show: 0 } as FormValidationSidebar,
	reducers: {
		toggleFormValidationSideBar: (state, action: PayloadAction<boolean>) => ({
			show: action.payload ? state.show + 1 : 0,
		}),
	},
	extraReducers: (builder) => {
		builder
		.addMatcher(isAnyOf(getFormAuth.pending, getFormPublic.pending), (state, { payload, meta }) => ({show: 0}))
	}
})

export const { toggleFormValidationSideBar } = formValidationSidebar.actions

////////////////

export default combineReducers({
	fullScreenField: fullScreenField.reducer,
	printableDashboard: printableDashboard.reducer,
	notifications: notifications.reducer,
	helpInfoField: helpInfoField.reducer,
	formValidationSidebar: formValidationSidebar.reducer,
})
