import React, { useState } from 'react'
import dayjs from 'dayjs'
import gql from 'graphql-tag'
import { FormActionFormActionScheduleUnitNameChoices } from '@/__gen/types'
import { RowType, getRecordFormKey } from '../../common/const'
import { useFormActionDashbaordSaveScheduleMutation } from './__gen/use-bulk-save-schedules'

export type RowFields = {
	dueAt: dayjs.Dayjs | null
	unitAmount: number | null
	unitName: FormActionFormActionScheduleUnitNameChoices | null
	requestSignature: boolean
	userSuppliedContext: string
	assignedAnonEmailAnswer: string | null
	escalationEmailAnswer: string | null
	escalationDeltaDays: number | null
}

type Props = {
	rows: RowType[]
	rowStates: {
		[id: string]: RowFields
	}
	setShowSaveCompletedModal: (show: boolean) => void
}

gql`
	mutation formActionDashbaordSaveSchedule(
		$schedule: form_action_scheduleInput!
		$escalations: [form_action_escalation_bulkBulkInput!]!
	) {
		formActionSchedule_Update(input: $schedule) {
			object {
				id
			}
			errors {
				field
				messages
			}
		}
		formActionEscalation_BulkUpdate(input: { objects: $escalations }) {
			objects {
				id
			}
			errors {
				field
				messages
			}
		}
	}
`

export const useBulkSaveSchedules = ({ rows, rowStates, setShowSaveCompletedModal }: Props) => {
	const [saveSchedule, {}] = useFormActionDashbaordSaveScheduleMutation()
	const [savingSet, setSavingSet] = useState(new Set<string>())
	const [errorMap, setErrorMap] = useState<{ [rfKey: string]: string }>({})
	const [showErrors, setShowErrors] = useState(false)

	const isValid = (fields: RowFields) => {
		const escalationsValid =
			(fields.escalationEmailAnswer && fields.escalationDeltaDays) ||
			(!fields.escalationEmailAnswer && !fields.escalationDeltaDays)
		return (
			fields.dueAt &&
			fields.unitAmount &&
			fields.unitName &&
			fields.assignedAnonEmailAnswer &&
			escalationsValid
		)
	}

	const save = () => {
		if (savingSet.size > 0) {
			alert('Already saving')
			return
		}
		const nextSaving = new Set<string>()
		setErrorMap({})
		let nextErrors: { [rfKey: string]: string } = {}
		for (const r of rows) {
			const key = getRecordFormKey(r)
			nextSaving.add(key)
			if (!isValid(rowStates[key])) {
				nextErrors[key] = `${r.record.name} ${r.form.title} is invalid`
			}
		}
		if (Object.keys(nextErrors).length > 0) {
			setErrorMap(nextErrors)
			setShowErrors(true)
			return
		}
		let e = false
		setSavingSet(nextSaving)
		const promises = rows.map(async (r) => {
			const key = getRecordFormKey(r)
			const fields = rowStates[key]
			const promise = saveSchedule({
				variables: {
					schedule: {
						record: r.record.id,
						form: r.form.id,
						overwriteExistingUnlessIdentical: true,
						dueAt: fields.dueAt,
						unitAmount: fields.unitAmount,
						unitName: fields.unitName,
						requestSignature: fields.requestSignature,
						userSuppliedContext: fields.userSuppliedContext,
						assignedAnonEmailAnswer: fields.assignedAnonEmailAnswer,
					},
					escalations:
						fields.escalationEmailAnswer ?
							[
								{
									record: r.record.id,
									form: r.form.id,
									overwriteExisting: true,
									assignedAnonEmailAnswer: fields.escalationEmailAnswer,
									deltaDays: fields.escalationDeltaDays,
								},
							]
						:	[],
				},
			})
			const resp = await promise
			setSavingSet((prev) => {
				prev.delete(getRecordFormKey(r))
				return new Set(prev)
			})
			if (
				resp.errors ||
				resp.data?.formActionSchedule_Update?.errors ||
				resp.data?.formActionEscalation_BulkUpdate?.errors
			) {
				e = true
				setErrorMap((prev) => ({
					...prev,
					[key]: `${r.record.name} ${r.form.title} failed to save`,
				}))
			}
		})
		Promise.all(promises).then(() => {
			if (e) {
				setShowErrors(true)
			} else {
				setShowSaveCompletedModal(true)
			}
		})
	}
	const validRows = rows.filter((r) => isValid(rowStates[getRecordFormKey(r)])).length
	return {
		validRows,
		save,
		savingSet,
		errorMap,
		showErrors,
		setShowErrors,
	}
}
