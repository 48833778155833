import React from 'react'
import { Tabs } from 'antd'
import { Route } from 'react-router-dom'
import { SinglePanel } from '@/components/common/panel'
import { useGlobalContext } from '@/components/context'
import history from '@/utils/history'
import { useTitle } from '@/utils/hooks'
import { recordMergePdfsCreateUrl, recordMergePdfsHistoryUrl } from '@/utils/url'
import { CreateMergedPdf } from './create'
import History from './history'

const TABS = {
	SEND: 'send',
	HISTORY: 'history',
}

type Props = {
	match: {
		params: {
			recordId: string
		}
		url: string
	}
}

export const MergePdf = ({
	match: {
		params: { recordId },
	},
}: Props) => {
	const globalContext = useGlobalContext()
	const createRoute = recordMergePdfsCreateUrl({
		...globalContext,
		recordId: recordId,
	})
	const historyRoute = recordMergePdfsHistoryUrl({
		...globalContext,
		recordId: recordId,
	})
	useTitle('Combine Pdfs')
	return (
		<SinglePanel type="thin">
			<h4>Merge pdfs</h4>
			<div>
				<Tabs
					defaultActiveKey={window.location.pathname.includes('create') ? TABS.SEND : TABS.HISTORY}
					onChange={(key) => history.push(key === TABS.SEND ? createRoute : historyRoute)}
					items={[
						{
							label: 'Create',
							key: TABS.SEND,
							children: (
								<Route path={createRoute} component={() => <CreateMergedPdf recordId={recordId} />} />
							),
						},
						{
							label: 'History',
							key: TABS.HISTORY,
							children: <Route path={historyRoute} component={() => <History recordId={recordId} />} />,
						},
					]}
				/>
			</div>
		</SinglePanel>
	)
}
