import React, { useCallback, useState } from 'react'
import { FormActionDashboardDataQuery } from '../../__gen'
import { monthIdFromString } from '../../../common/const'
import { Popup } from './popup'
import style from './segment.module.sass'

type Record = NonNullable<FormActionDashboardDataQuery['record_List']>['objects'][number]
type FormAction = Record['formActions'][number]

export type MonthData = {
	created: { [id: string]: FormAction }
	completedOn: { [id: string]: FormAction }
	dueInFuture: { [id: string]: Record['formActionSchedules'][number] }
	overdue: { [id: string]: FormAction }
	span: { [id: string]: FormAction }
	escalation: { [id: string]: Record['formActionEscalationEvents'][number] }
}

type Props = {
	year: number
	month: number
	todayKey: string
	monthData: MonthData
}
export const Segment = ({ monthData, month, year, todayKey }: Props) => {
	const [isHover, setIsHover] = useState(false)
	const hasEscalation = Object.keys(monthData.escalation).length > 0
	const overdue = Object.keys(monthData.overdue).length > 0
	const hasCreated = Object.keys(monthData.created).length > 0
	const hasCompleted = Object.keys(monthData.completedOn).length > 0
	const hasDueInFuture = Object.keys(monthData.dueInFuture).length > 0
	const hasSpan = Object.keys(monthData.span).length > 0

	const onMouseEnter = useCallback(() => {
		setIsHover(true)
	}, [setIsHover])

	const onMouseLeave = useCallback(() => {
		setIsHover(false)
	}, [setIsHover])

	return (
		<div className={style.container} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
			{isHover && <Popup monthData={monthData} />}
			{hasEscalation && <div className={style.escalation} />}
			{overdue && <div className={style.overdue} />}
			{hasDueInFuture && <div className={style.dueInFuture} />}
			{hasCompleted && <div className={style.completed} />}
			{hasCreated && (
				<>
					<div className={style.created} />
					{Object.values(monthData.created).find(
						(a) =>
							monthIdFromString(a.created) < (a.completedOn ? monthIdFromString(a.completedOn) : todayKey),
					) && (
						<div className={style.spanContainer}>
							<div className={style.spanEmpty} />
							<div className={style.span} />
						</div>
					)}
				</>
			)}
			{(Object.values(monthData.completedOn).find(
				(a) => monthIdFromString(a.created) < monthIdFromString(a.completedOn!),
			) ||
				Object.values(monthData.overdue).find((a) => monthIdFromString(a.created) < todayKey)) && (
				<div className={style.spanContainer}>
					<div className={style.span} />
					<div className={style.spanEmpty} />
				</div>
			)}
			{hasSpan && (
				<div className={style.spanContainer}>
					<div className={style.span} />
					<div className={style.span} />
				</div>
			)}
			<div className={style.background} />
		</div>
	)
}
