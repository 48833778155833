import React from 'react'
import { MoreOutlined } from '@ant-design/icons'
import { Dropdown } from 'antd'
import { format } from 'date-fns'
import { TEMP_ID_PREFIX } from '@/utils/constants'
import { useAppDispatch } from '@/utils/hooks'
import generalStyle from '@sass/common/general.module.sass'
import iconStyle from '@sass/common/icon.module.sass'
import { getUserDetails } from '../../../common/utils'
import { copyLinkAvailable, CopyLinkText } from '../../common/copy-link'
import { Row } from '../row'
import { Actions, FormAction } from '../state'

const fmtReminder = (reminder: Date | null) => {
	if (!reminder) {
		return null
	}

	return format(new Date(reminder), 'h:mmbbb M/d/yy')
}

type Props = {
	formAction: FormAction
	isCurrent: boolean
	dispatch: React.Dispatch<Actions>
	hasReordered: boolean
}

export const FormActionRow = ({ formAction, isCurrent, dispatch, hasReordered }: Props) => {
	const reduxDispatch = useAppDispatch()
	const reminder =
		formAction.completedOn ? <>Complete</>
		: formAction.id.startsWith(TEMP_ID_PREFIX) ? null
		: isCurrent && hasReordered ? <div className={generalStyle.muted}>Save to see reminders</div>
		: isCurrent ?
			<>
				<div>Last - {fmtReminder(formAction.reminderLast)}</div>
				<div>
					{formAction.reminderNext ?
						<>Next - {fmtReminder(formAction.reminderNext)}</>
					:	<>Reminders stopped</>}
				</div>
			</>
		:	<div className={generalStyle.muted}>Waiting</div>

	const details = getUserDetails(formAction)

	const props = {
		reminder,
		priority: <>{formAction.priority}</>,
		requestSignature: formAction.requestSignature ? 'Yes' : 'No',
		name: `${details.name} - ${details.email}`,
		type: details.type,
		action: (
			<Dropdown
				menu={{
					items: [
						{
							key: 'delete',
							label: (
								<div
									className={iconStyle.bin}
									onClick={() => dispatch({ type: 'DELETE', id: formAction.id })}
								>
									Delete
								</div>
							),
						},
						...(copyLinkAvailable(formAction) ?
							[
								{
									key: 'link',
									disabled: !formAction.link,
									label: <CopyLinkText link={formAction.link} reduxDispatch={reduxDispatch} />,
								},
							]
						:	[]),
					],
				}}
				trigger={['click']}
			>
				<MoreOutlined style={{ transform: 'scale(1.4)' }} />
			</Dropdown>
		),
	}

	return <Row {...props} />
}
