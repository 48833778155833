import React, { useState } from 'react'
import gql from 'graphql-tag'
import * as Yup from 'yup'
import { Formik, Field, FormikProps } from 'formik'

import { formUrl } from '@/utils/url'
import { TextField } from '@/components/common/formik'
import { CenteredSpinner } from '@/components/common/loading'
import history from '@/utils/history'
import { PDF_CONTENT_TYPE } from '@/utils/constants'
import { useAppDispatch } from '@/utils/hooks'
import { useGlobalContext } from '@/components/context'
import { showNotification } from '@/state/user-interface'
import buttonStyle from '@sass/common/button.module.sass'
import iconStyle from '@sass/common/icon.module.sass'
import EmailSelect from './email-select'
import { FormValues } from './types'
import styles from './index.module.sass'
import { useEmailOptionsQuery, useSendEmailsMutation } from './__gen'


const PUBLIC_FORM_EMAIL_TYPE = "PF"
const PDF_EMAIL_TYPE = "PDF"

gql`
	query emailOptions {
		emailOption_List {
			objId
			email
			sourceType
			name
			detail
			recordWord
		}
	}
`

gql`
	mutation sendEmails(
		$typeString: String!,
		$createdBy: ID!,
		$userSuppliedContext: String!,
		$sendToEmail: String!,
		$sendToContentType: String,
		$sendToObjectId: ID,
		$regardingContentType: String!,
		$regardingObjectId: ID!,
	) {
		email_Update(
			input: {
				typeString: $typeString,
				createdBy: $createdBy,
				userSuppliedContext: $userSuppliedContext,
				sendToEmail: $sendToEmail,
				sendToContentType: $sendToContentType,
				sendToObjectId: $sendToObjectId,
				regardingContentType: $regardingContentType,
				regardingObjectId: $regardingObjectId,
			}
		) {
			object {
				id
			}
			errors {
				field
				messages
			}
		}
	}
`

type CustomOption = string


type Props = {
	recordId: string
	formId: string
	regardingObjectId: string
	regardingObjectContentType: string
}


const sendEmailModal = ({recordId, formId, regardingObjectId, regardingObjectContentType}: Props) => {
	const [showAddEmail, setShowAddEmail] = useState(true)
	const globalContext = useGlobalContext()
	const { data, loading } = useEmailOptionsQuery()
	const [mutate, { loading: mutating }] = useSendEmailsMutation({refetchQueries: ['email_Options']})
	const dispatch = useAppDispatch()
	
	const postSend = () => {
		setShowAddEmail(true)
		dispatch(showNotification({
			'content': `Email(s) sent successfully`
		}))
		history.push(formUrl({recordId, formId, ...globalContext}))
	};

	const renderFormInner = ({ handleSubmit, values }: FormikProps<FormValues>) => {
		const emails = [
			...values.emailOptions.filter(o => o.selected).map(o => o.email),
			...values.newCustomEmails.filter((ce: CustomOption) => ce !== ''),
		]
		return (
			<div>
				<div className={styles.sendingToContainer}>
					<div className={styles.sendingToTitle}>Sending to:</div>
					<div className={styles.sendingToList}>
						{emails.map((e, index) => (
							<span key={index} className={styles.emailItem}>
								{e}
							</span>
						))}
						<button
							className={buttonStyle.noOutline}
							onClick={() => setShowAddEmail(true)}
						>
							<span className={iconStyle.pencil} />
						</button>
					</div>
				</div>
				<br/>
				<div className={styles.subtitle}>Email message (optional):</div>
				<Field
					placeholder={""}
					name="context"
					component={TextField}
					textarea={true}
				/>
				Note: You will not receive the replies to this email
				<div className={styles.buttonContainer}>
					<button
						type="button"
						className={buttonStyle.primarySolid}
						//@ts-ignore
						onClick={handleSubmit}
						disabled={mutating}
					>
						Send
					</button>
				</div>
			</div>
		)
	}
	return (!data || mutating)
		? <CenteredSpinner/>
		: (
			<div>
				<Formik
					enableReinitialize
					initialValues={{
						context: "",
						newCustomEmails: [] as string[],
						emailOptions: data?.emailOption_List?.map(
							option => ({...option, selected: false})
						) as FormValues["emailOptions"],
					}}
					validationSchema={Yup.object().shape({
						context: Yup.string(),
						newCustomEmails: Yup.array().of(Yup.string().email()),
						emailOptions: Yup.array(Yup.object()),
					})}
					onSubmit={(values, {resetForm}) => {
						const emailData = {
							typeString: regardingObjectContentType == PDF_CONTENT_TYPE ? PDF_EMAIL_TYPE : PUBLIC_FORM_EMAIL_TYPE,
							createdBy: globalContext.user.id,
							userSuppliedContext: values.context,
							regardingContentType: regardingObjectContentType,
							regardingObjectId: regardingObjectId,
						}

						const answers = values.emailOptions.filter(ae => ae.selected)
						const emailOptions = answers.map(a => ({
							...emailData,
							sendToContentType: a.sourceType,
							sendToObjectId: a.objId,
							sendToEmail: a.email,
						}))
						
						const newCustomEmails = values.newCustomEmails.filter(ce => ce != '').map(ce => ({
							...emailData,
							sendToContentType: null,
							sendToObjectId: null,
							sendToEmail: ce,
						}))
						const toSend = [
							...emailOptions,
							...newCustomEmails,
						]
						const promises = toSend.map(e => mutate({ variables: e }))
						Promise.all(promises).then(() => {
							resetForm()
							postSend()
						})
					}}
				>
					{(formProps) => {
						return showAddEmail
							? <EmailSelect
									toggleState={() => setShowAddEmail(!showAddEmail)}
									formProps={formProps}
								/>
							: renderFormInner(formProps)
					}}
				</Formik>
			</div>
		)
}

export default sendEmailModal