import React from 'react'
import { Link } from 'react-router-dom'
import { useGlobalContext } from '@/components/context'
import { formUrl } from '@/utils/url'
import { ModeType } from '../../../common/const'
import style from './index.module.sass'

type FormTitleProps = {
	record: { id: string; name: string }
	form: { id: string; title: string }
	isSelected: boolean
	onClick: () => void
	mode: ModeType
}

export const FormTitle = ({ record, form, mode, isSelected, onClick }: FormTitleProps) => {
	const globalContext = useGlobalContext()

	if (mode == 'select') {
		return (
			<div onClick={onClick} className={style.selectContainer}>
				<input type="checkbox" checked={isSelected} readOnly />
				<div className={style.text}>{form.title}</div>
			</div>
		)
	}

	return (
		<Link
			className={style.container}
			to={formUrl({ ...globalContext, formId: form.id, recordId: record.id })}
		>
			<div/> {/* for justify-content: space-between */}
			<div className={style.text}>{form.title}</div>
		</Link>
	)
}
