import React, { useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { Error } from '@/components/common/error-boundary'
import { SinglePanel } from '@/components/common/panel'
import Placeholder from '@/components/common/placeholder'
import { getAnswersThunk } from '@/state/answer/thunks'
import { useFormContext } from '@/state/main-form/reducer'
import { getForm } from '@/state/main-form/thunks'
import { RequestFailures } from '@/utils/functions'
import { useAppDispatch, useAppSelector } from '@/utils/hooks'
import Form from './form'
import style from './form.module.sass'

type Props =
	| RouteComponentProps<{ recordId: string; formId: string }>
	| RouteComponentProps<{ publicFormCode: string }>

const FormContainer = ({ match: { params } }: Props) => {
	const dispatch = useAppDispatch()
	// There is a short moment when the form is loaded but the answers are not.
	// Use this extra loading state to handle the flicker.
	const [thunksLoading, setThunksLoading] = useState(true)
	useEffect(
		() => {
			setThunksLoading(true)
			dispatch(getForm(params)).then(() => {
				dispatch(getAnswersThunk(params)).then(() => {
					setThunksLoading(false)
				})
			})
		},
		'publicFormCode' in params ? [params.publicFormCode] : [params.recordId, params.formId],
	)
	const formContext = useFormContext()

	const loading = useAppSelector(
		(state) =>
			thunksLoading ||
			state.answers.loading ||
			state.mainForm.loading ||
			// This last clause isn't essential. It handles the case when we're waiting for
			// answers to save when switching from one form to another.
			(!('publicFormCode' in params) && state.mainForm.data?.form.id !== params.formId),
	)

	const error = useAppSelector((state) => state.mainForm.error)
	if (error) {
		const message = {
			[RequestFailures.PERMISSION_DENIED]: {
				title: 'This form does not exist',
				content:
					'This link has either been deactivated or does not exists. If you believe it should, please contact your administrator.',
			},
			[RequestFailures.UNKNOWN_ERROR]: { title: null, content: null }, // default error message
		}[error]
		return <Error {...message} />
	}

	if (loading || !formContext) {
		return (
			<div className={style.container}>
				<SinglePanel type="full-width">
					<Placeholder style={{ height: 50, maxWidth: 400 }} />
					<Placeholder style={{ height: 300 }} />
					<Placeholder style={{ height: 600 }} />
				</SinglePanel>
				<div className={style.rightPanelContainer}>
					<Placeholder style={{ height: 30, width: 100, marginTop: 0, marginBottom: 0 }} />
					<Placeholder style={{ height: 100 }} />
					<Placeholder style={{ height: 100 }} />
				</div>
			</div>
		)
	}

	return <Form />
}

export default FormContainer
