import React, { useState } from 'react'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd'
import c from 'classnames'
import buttonStyle from '@sass/common/button.module.sass'
import commonStyle from '../../common/common.module.sass'
import { ModeType, RowType, getRecordFormKey } from '../../common/const'
import { DataItem } from '../../common/data-item'
import { Row } from '../../common/row'
import { SelectModeTitle } from '../../page-title/select-mode-title'
import { ActionLine } from './action-line'
import { FormTitle } from './form-title'
import { Headers } from './headers'
import style from './index.module.sass'
import { DeleteButton } from './delete-button'

type TableProps = {
	startYear: number
	startMonth: number
	selectedRows: Set<string>
	setSelectedRows: (rows: Set<string>) => void
	loadMoreRow: number
	loadMoreRef: (node: HTMLDivElement) => void
	rows: RowType[]
	mode: ModeType
	setMode: (mode: ModeType) => void
	hasNextpage: boolean
	resetList: () => void
}

export const SelectTable = ({
	startYear,
	startMonth,
	rows,
	selectedRows,
	setSelectedRows,
	loadMoreRow,
	loadMoreRef,
	mode,
	setMode,
	hasNextpage,
	resetList,
}: TableProps) => {
	const [showHasNextPageWarning, setShowHasNextPageWarning] = useState(false)
	const toggleSelectAll = () => {
		if (selectedRows.size === rows.length) {
			setSelectedRows(new Set())
			setShowHasNextPageWarning(false)
		} else {
			setSelectedRows(new Set(rows.map((r) => `${r.record.id}|${r.form.id}`)))
			if (hasNextpage) {
				setShowHasNextPageWarning(true)
			}
		}
	}

	const toggleRowSelection = (id: string) => {
		const newSelectedRows = new Set(selectedRows)
		if (newSelectedRows.has(id)) {
			newSelectedRows.delete(id)
		} else {
			newSelectedRows.add(id)
		}
		setSelectedRows(newSelectedRows)
	}

	const toggleRecordSelection = (recordId: string) => {
		const recordRows = rows.filter((row) => row.record.id === recordId)
		const allSelected = recordRows.every((row) => selectedRows.has(getRecordFormKey(row)))
		const newSelectedRows = new Set(selectedRows)

		recordRows.forEach((row) => {
			const rowId = getRecordFormKey(row)
			if (allSelected) {
				newSelectedRows.delete(rowId)
			} else {
				newSelectedRows.add(rowId)
			}
		})

		setSelectedRows(newSelectedRows)
	}

	return (
		<>
			{mode === 'select' && (
				<>
					<SelectModeTitle />
					<div className={commonStyle.controlsRow}>
						<div className={commonStyle.controlButtons}>
							<DataItem
								left={'Records selected'}
								right={new Set(Array.from(selectedRows).map((row) => row.split('|')[0])).size}
							/>
							<DataItem left={'Total selected'} right={selectedRows.size} />
							{showHasNextPageWarning && (
								<Tooltip title="Only loaded rows have been selected. Scroll to the bottom to load all rows.">
									<ExclamationCircleOutlined className={style.warning} /> Warning
								</Tooltip>
							)}
						</div>
						<div className={commonStyle.controlButtons}>
							<button className={buttonStyle.greySolid} onClick={() => setMode('view')}>
								Back
							</button>
							<DeleteButton
								selectedRows={selectedRows}
								rows={rows}
								resetList={resetList}
							/>
							<button
								className={buttonStyle.greySolid}
								onClick={() => setMode('edit')}
								disabled={selectedRows.size === 0}
							>
								Edit
							</button>
						</div>
					</div>
				</>
			)}

			<Headers
				startYear={startYear}
				startMonth={startMonth}
				mode={mode}
				allSelected={selectedRows.size === rows.length}
				toggleSelectAll={toggleSelectAll}
			/>
			{rows.map((row, i) => {
				const recordSelected = rows
					.filter((r) => r.record.id === row.record.id)
					.every((r) => selectedRows.has(getRecordFormKey(r)))
				return (
					<div className={style.row} key={getRecordFormKey(row)} ref={i === loadMoreRow ? loadMoreRef : null}>
						{row.showName && (
							<Row
								left={
									<div
										className={c(style.recordName, { [style.recordNameSelectMode]: mode == 'select' })}
										onClick={() => toggleRecordSelection(row.record.id)}
									>
										{mode == 'select' && (
											<input
												className={style.recordCheckbox}
												type="checkbox"
												checked={recordSelected}
												readOnly
											/>
										)}
										<b>&nbsp;&nbsp;{row.record.name || <i>No Name</i>}</b>
									</div>
								}
								right={null}
							/>
						)}
						<Row
							left={
								<FormTitle
									record={row.record}
									form={row.form}
									mode={mode}
									isSelected={selectedRows.has(getRecordFormKey(row))}
									onClick={() => toggleRowSelection(getRecordFormKey(row))}
								/>
							}
							right={
								<ActionLine
									record={row.record}
									form={row.form}
									data={row.data}
									startYear={startYear}
									startMonth={startMonth}
								/>
							}
						/>
					</div>
				)
			})}
		</>
	)
}
