import { ActionLineProps } from "../fetch/select-table/action-line"

export const monthKey = ({ year, month }: { year: number, month: number }) => {
	return `${year}-${month / 10 < 1 ? '0' : ''}${month}`
}

export const monthIdFromString = (dateString: string) => {
	const date = new Date(dateString)
	return monthKey({ year: new Date(date).getFullYear(), month: new Date(date).getMonth() + 1 })
}

export const getMonths = ({ startYear, startMonth }: { startYear: number; startMonth: number }) => {
	return MONTHS.map((_, i) => {
		const month = MONTHS[(i + startMonth - 1) % 12]
		return {
			month: month.index,
			year: startYear + Math.floor((startMonth + i - 1) / 12),
		}
	})
}

export type ModeType = 'view' | 'select' | 'edit'

export const getRecordFormKey = ({ record, form }: { record: { id: string }; form: { id: string } }) =>
	`${record.id}|${form.id}`

export type RowType = Pick<ActionLineProps, 'record' | 'form' | 'data'> & { showName: boolean }

export const MONTHS = [
	{title: 'Jan', index: 1},
	{title: 'Feb', index: 2},
	{title: 'Mar', index: 3},
	{title: 'Apr', index: 4},
	{title: 'May', index: 5},
	{title: 'Jun', index: 6},
	{title: 'Jul', index: 7},
	{title: 'Aug', index: 8},
	{title: 'Sep', index: 9},
	{title: 'Oct', index: 10},
	{title: 'Nov', index: 11},
	{title: 'Dec', index: 12},
]