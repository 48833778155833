import * as Types from '@/__gen/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FormActionDashbaordSaveScheduleMutationVariables = Types.Exact<{
  schedule: Types.Form_Action_ScheduleInput;
  escalations: Array<Types.Form_Action_Escalation_BulkBulkInput>;
}>;


export type FormActionDashbaordSaveScheduleMutation = { __typename: 'PharosMutations', formActionSchedule_Update: { __typename: 'form_action_schedulePayload', object: { __typename: 'FormActionScheduleType', id: string } | null, errors: Array<{ __typename: 'ErrorType', field: string, messages: Array<string> } | null> | null } | null, formActionEscalation_BulkUpdate: { __typename: 'form_action_escalation_bulkPayload', objects: Array<{ __typename: 'FormActionEscalationType', id: string }> | null, errors: Array<{ __typename: 'ErrorType', field: string, messages: Array<string> } | null> | null } | null };


export const FormActionDashbaordSaveScheduleDocument = gql`
    mutation formActionDashbaordSaveSchedule($schedule: form_action_scheduleInput!, $escalations: [form_action_escalation_bulkBulkInput!]!) {
  formActionSchedule_Update(input: $schedule) {
    object {
      id
    }
    errors {
      field
      messages
    }
  }
  formActionEscalation_BulkUpdate(input: {objects: $escalations}) {
    objects {
      id
    }
    errors {
      field
      messages
    }
  }
}
    `;
export type FormActionDashbaordSaveScheduleMutationFn = Apollo.MutationFunction<FormActionDashbaordSaveScheduleMutation, FormActionDashbaordSaveScheduleMutationVariables>;

/**
 * __useFormActionDashbaordSaveScheduleMutation__
 *
 * To run a mutation, you first call `useFormActionDashbaordSaveScheduleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFormActionDashbaordSaveScheduleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [formActionDashbaordSaveScheduleMutation, { data, loading, error }] = useFormActionDashbaordSaveScheduleMutation({
 *   variables: {
 *      schedule: // value for 'schedule'
 *      escalations: // value for 'escalations'
 *   },
 * });
 */
export function useFormActionDashbaordSaveScheduleMutation(baseOptions?: Apollo.MutationHookOptions<FormActionDashbaordSaveScheduleMutation, FormActionDashbaordSaveScheduleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FormActionDashbaordSaveScheduleMutation, FormActionDashbaordSaveScheduleMutationVariables>(FormActionDashbaordSaveScheduleDocument, options);
      }
export type FormActionDashbaordSaveScheduleMutationHookResult = ReturnType<typeof useFormActionDashbaordSaveScheduleMutation>;
export type FormActionDashbaordSaveScheduleMutationResult = Apollo.MutationResult<FormActionDashbaordSaveScheduleMutation>;
export type FormActionDashbaordSaveScheduleMutationOptions = Apollo.BaseMutationOptions<FormActionDashbaordSaveScheduleMutation, FormActionDashbaordSaveScheduleMutationVariables>;