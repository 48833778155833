import React from 'react'
import c from 'classnames'
import { useGlobalContext } from '@/components/context'
import commonStyle from '../../../common/common.module.sass'
import { MONTHS, ModeType, getMonths } from '../../../common/const'
import { Row } from '../../../common/row'
import style from './index.module.sass'

type Props = {
	startYear: number
	startMonth: number
	allSelected: boolean
	toggleSelectAll: () => void
	mode: ModeType
}

export const Headers = ({ startYear, startMonth, mode, allSelected, toggleSelectAll }: Props) => {
	const globalContext = useGlobalContext()
	const months = getMonths({ startYear, startMonth })
	const isSelectMode = mode === 'select'
	return (
		<Row
			left={
				<div
					onClick={isSelectMode ? toggleSelectAll : undefined}
					className={c(style.leftHeader, style.headerContainer, {
						[style.selectModeContainer]: isSelectMode,
					})}
				>
					{isSelectMode && (
						<>
							<input type="checkbox" checked={allSelected} readOnly />
							&nbsp; Select All :{' '}
						</>
					)}
					{globalContext.currentModule.recordWord}
				</div>
			}
			right={
				<div
					className={c(commonStyle.monthsContainer, style.headerContainer)}
					style={{ borderTopRightRadius: 8 }}
				>
					{months.map((month) => (
						<div
							key={month.month}
							className={c(commonStyle.monthCell, style.monthTitle, {
								[style.current]:
									new Date().getFullYear() == month.year && new Date().getMonth() + 1 == month.month,
								[style.monthJan]: month.month === 1,
							})}
						>
							{MONTHS[month.month - 1].title}
						</div>
					))}
				</div>
			}
		/>
	)
}
