import { useMemo } from 'react'
import { useAppSelector } from '@/utils/hooks'
import { FieldType } from '@/state/main-form/types'

type ConditionalItem = {
	id: string
	conditionalOption: null | {
		label: string
		field: {
			id: string
		}
	}
}

type Params = {
	items: ConditionalItem[]
	fields: FieldType[]
}

export const useConditionalItemsToHide = ({items, fields}: Params) => {
	const conditionalPairs = useMemo(() => {
		const conditionalPairs: [parentId: string, ConditionalItem][]  = []

		items.forEach((item) => {
			const parent = fields.find((f) => f.id === item.conditionalOption?.field.id)
			if (parent) {
				conditionalPairs.push([parent.id, item])
			} else if (item.conditionalOption) {
				console.error('Unable to locate conditional parent for item:', item)
			}
		})
		return conditionalPairs
	}, [])

	const hideItems = useAppSelector((state) => {
		const l = conditionalPairs.map(([parentId, conditionalItem]) => {
			const match = Object.values(state.answers.objects).find(
				(a) =>
					!a.deactivated &&
					parentId === a.fieldId &&
					a.content === conditionalItem.conditionalOption?.label,
			)
			return { fieldId: conditionalItem.id, satisfied: !!match }
		})
		return new Set(l.filter((obj) => !obj.satisfied).map((obj) => obj.fieldId))
	})

	return hideItems
}
