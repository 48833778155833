import React, { useEffect, useRef } from 'react'
import c from 'classnames'
import _sortBy from 'lodash/sortBy'
import querystring from 'query-string'
import { useRouter } from '@/components/context'
import { useFormContext } from '@/state/main-form/reducer'
import { useAppSelector } from '@/utils/hooks'
import iconStyle from '@sass/common/icon.module.sass'
import { Field } from './field'
import { HelpInfoModal } from './field/help-info/modal'
import style from './index.module.sass'
import { InfoBlock } from './info-block'
import ShrinkButton from './shrink-button'
import { useConditionalItemsToHide } from './use-conditional-items-to-hide'

type Props = {
	scrollContainerRef: React.RefObject<HTMLDivElement>
}

export const FormInputPanel = ({ scrollContainerRef }: Props) => {
	const formContext = useFormContext()
	const router = useRouter()
	const fullScreenField = useAppSelector((state) => state.userInterface.fullScreenField)

	const fields = Object.values(formContext.fields)
	fields.sort((a, b) => a.rank - b.rank)

	let fieldRefs: { [key: string]: React.RefObject<HTMLDivElement> } = {}
	fields.map((field) => {
		fieldRefs[field.id] = useRef<HTMLDivElement>(null)
	})
	const scrollToField = querystring.parse(router.location.search).highlightFieldId || fullScreenField

	const scrollToFieldRef = fieldRefs[scrollToField]

	useEffect(() => {
		// Need to add a short delay for the container to fill before scrolling
		// (it works some of the time without this so be careful!)
		const scrollFunc = setTimeout(() => {
			if (scrollToField && scrollContainerRef?.current && scrollToFieldRef?.current) {
				scrollContainerRef.current.scrollTo(0, scrollToFieldRef.current.offsetTop - 100)
			}
		}, 500)
		return () => clearTimeout(scrollFunc)
	}, [])

	const hideInfoBlocks = useConditionalItemsToHide({items: formContext.form.infoBlocks, fields})
	const hideFields = useConditionalItemsToHide({items: fields, fields})

	const showFields = fields.filter((field) => !hideFields.has(field.id))
	const showInfoBlocks = formContext.form.infoBlocks.filter((infoBlock) => !hideInfoBlocks.has(infoBlock.id))

	const items = _sortBy([...showInfoBlocks, ...showFields], 'rank').map((item, i) => {
		if (item.__typename === 'InfoBlockType') {
			return (
				<InfoBlock
					isFirst={i == 0}
					key={`${item.id}-info-block`}
					{...item}
					recordId={formContext.record.id}
				/>
			)
		} else {
			return (
				<Field
					key={`${item.id}-field`}
					forwardRef={fieldRefs[item.id]}
					highlight={item.id == scrollToField}
					fieldId={item.id}
				/>
			)
		}
	})

	return (
		<div className={c(style.container, { [style.fullScreenForm]: fullScreenField })}>
			{formContext.record.archived ||
				(formContext.form.lockedFromFormAction && (
					<div className={style.archived}>
						<div className={`${iconStyle.pencil} ${style.iconLock}`} />
						{formContext.record.archived ? 'Archived' : 'Signing in progress'}
					</div>
				))}
			<div className={style.titleRow}>
				<ShrinkButton />
				<div className={style.title}>{formContext.form.title}</div>
			</div>

			{items}
			<HelpInfoModal />
		</div>
	)
}
