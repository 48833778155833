import { PayloadAction, createSlice, isAnyOf } from '@reduxjs/toolkit'
import { differenceInSeconds } from 'date-fns'
import _omit from 'lodash/omit'
import { v7 as uuid } from 'uuid'
import { AnswerContentTypeEnum } from '@/__gen/types'
import { GenericAnswer, SaveType } from '@/state/answer/types'
import { MainFormStateDataType } from '@/state/main-form/reducer'
import { FieldType } from '@/state/main-form/types'
import {
	UpdateAnswerParams,
	doPublicFormSubmit,
	getAnswersAuthThunk,
	getAnswersPublicThunk,
	saveAnswersThunk,
} from './thunks'
import { createNewAnswer, createNewAnswerRow } from './utils'

const FORM_SESSION_REPLACEMENT_SECONDS = 60 * 60 // one hour

type Session = {
	id: string
	started: Date
	saved: boolean
}

export type AnswerInState = GenericAnswer & {
	new?: true
	newInFlight?: boolean
	purgeable?: boolean
	replacingId?: string
}

export type AnswerState = {
	session: Session
	saving: boolean
	loading: boolean
	queuedSave: SaveType | null
	touched: boolean
	submitting: boolean
	queuedSubmit: {
		submitterEmail: string
	} | null
	objects: { [id: string]: AnswerInState }
	status: {
		dirtyQueued: { [id: string]: string }
		dirtyInFlight: { [id: string]: string }
		purgeablePostSave: string[]
		initialAnswerMap: { [id: string]: string }
	}
}

export const answerSlice = createSlice({
	name: 'answers',
	initialState: {
		session: {
			id: 'will-be-replaced-first-answer-update',
			started: new Date(2000, 1, 1),
			saved: true,
		},
		saving: false,
		loading: false,
		queuedSave: null,
		touched: false,
		submitting: false,
		queuedSubmit: null,
		objects: {},
		status: {
			dirtyQueued: {},
			dirtyInFlight: {},
			purgeablePostSave: [],
			initialAnswerMap: {},
		},
	} as AnswerState,
	reducers: {
		gotAnswers: (state, action: PayloadAction<GenericAnswer[]>) => {
			action.payload.forEach((answer) => {
				state.objects[answer.id] = answer
			})
			return state
		},
		setQueuedSave: (state, action: PayloadAction<SaveType>) => {
			if (action.payload == 'SaveDebounce' && state.queuedSave == 'SaveImmediate') {
				// Don't overwrite immedate with debounce
			} else {
				state.queuedSave = action.payload
			}
			return state
		},
		setQueuedSubmit: (state, action: PayloadAction<AnswerState['queuedSubmit']>) => {
			state.queuedSubmit = action.payload
			return state
		},
		addMissingAnswers: (
			state,
			action: PayloadAction<{
				answers: GenericAnswer[]
			}>,
		) => {
			for (const answer of action.payload.answers) {
				state.objects[answer.id] = answer
				state.status.dirtyQueued[answer.id] = answer.id
			}
			return state
		},
		addAnswer: (state, action: PayloadAction<Parameters<typeof createNewAnswer>[0]>) => {
			const { payload: createParams } = action
			const answer = createNewAnswer(createParams)
			state.objects[answer.id] = answer
			state.status.dirtyQueued[answer.id] = answer.id
			return state
		},
		addAnswerRow: (
			state,
			action: PayloadAction<{
				objectIdMap: { [k in AnswerContentTypeEnum]: string }
				mainField: FieldType
			}>,
		) => {
			const {
				payload: { mainField, objectIdMap },
			} = action
			const rank =
				1 +
				Math.max(
					0,
					...Object.values(state.objects)
						.filter((a) => a.fieldId == action.payload.mainField.id)
						.map((a) => a.rank),
				)

			if (!state.touched) {
				state.touched = true
			}

			const answers = createNewAnswerRow({ mainField, rank, objectIdMap })
			for (const answer of answers) {
				state.objects[answer.id] = answer
				state.status.dirtyQueued[answer.id] = answer.id
			}
			return state
		},
		addInitialAnswers: (
			state,
			action: PayloadAction<{
				objectIdMap: { [k in AnswerContentTypeEnum]: string }
				fields: { [fieldId: string]: FieldType }
				initialAnswers: MainFormStateDataType['initialAnswers']
			}>,
		) => {
			const {
				payload: { initialAnswers, fields, objectIdMap },
			} = action

			for (const field of Object.values(fields)) {
				const hasAnswer = !!Object.values(state.objects).find((a) => a.fieldId == field.id)
				if (hasAnswer) {
					continue
				}

				const ranks = Array.from(new Set(Object.keys(initialAnswers[field.id] || {}).map(Number)))
				if (ranks.length === 0) {
					ranks.push(1)
				}

				for (const rank of ranks) {
					const answers = createNewAnswerRow({
						mainField: field,
						rank,
						objectIdMap,
						initialAnswers: initialAnswers[field.id],
					})
					for (const answer of answers) {
						state.objects[answer.id] = answer
						state.status.dirtyQueued[answer.id] = answer.id

						const initialAnswerForField = (initialAnswers[answer.fieldId] || {})[answer.rank] || []
						const initialAnswer = initialAnswerForField.find((i) => i.tableFieldId === answer.tableFieldId)
						if (initialAnswer) {
							state.status.initialAnswerMap[answer.id] = initialAnswer.id
						}
					}
				}
			}
			state.loading = false
			return state
		},
		updateAnswers: (state, action: PayloadAction<UpdateAnswerParams>) => {
			action.payload.answers.forEach((answer) => {
				const isDeleteToggle = !!answer.deactivated != !!state.objects[answer.id].deactivated
				const isRankChange = answer.rank != state.objects[answer.id].rank
				const isNew = state.objects[answer.id].new
				const isFile = ['AnswerFileType', 'AnswerFilePubType'].includes(answer.__typename)

				const destructiveUpdate = isRankChange || (isNew && !isFile && !isDeleteToggle)

				if (destructiveUpdate) {
					// destructive update, replace existing
					state.objects[answer.id] = {
						...state.objects[answer.id],
						..._omit(answer, '__typename'),
					}
					state.status.dirtyQueued[answer.id] = answer.id
				} else {
					// immutable update, deactivate old and create new
					if (!answer.deactivated) {
						const newId = uuid()
						state.objects[newId] = {
							...state.objects[answer.id],
							..._omit(answer, '__typename'),
							replacingId: answer.id, // used to update references to assigned_anon_email_answer
							new: true,
							id: newId,
						}
						delete state.objects[newId].newInFlight
						state.status.dirtyQueued[newId] = newId
					}
					if (!state.objects[answer.id].deactivated) {
						state.objects[answer.id] = {
							...state.objects[answer.id],
							purgeable: !answer.deactivated, // we already have a new answer, so can purge local state
							deactivated: new Date().toISOString(),
						}
						state.status.dirtyQueued[answer.id] = answer.id
					}
				}
			})
			return state
		},
	},

	extraReducers: (builder) => {
		builder
			.addCase(saveAnswersThunk.pending, (state) => {
				if (!state.status.dirtyQueued) {
					console.error('Save called with empty dirtyQueued')
					alert('Error: Multiple saves occurred on form')
				}
				state.saving = true
				state.queuedSave = null

				state.status.purgeablePostSave = Object.keys(state.status.dirtyQueued).filter(
					(id) => state.objects[id]?.purgeable,
				)

				for (const id of Object.keys(state.status.dirtyQueued)) {
					if (state.objects[id].new) {
						delete state.objects[id].new
						state.objects[id].newInFlight = true
					}
				}

				state.status.dirtyInFlight = {
					...state.status.dirtyInFlight,
					...state.status.dirtyQueued,
				}
				state.status.dirtyQueued = {}

				return state
			})
			.addCase(saveAnswersThunk.fulfilled, (state, action) => {
				state.saving = false
				state.status.dirtyInFlight = {}

				for (const a of action.payload) {
					if (state.objects[a.id].newInFlight) {
						delete state.objects[a.id].newInFlight
					}
					if (state.objects[a.id].replacingId) {
						delete state.objects[a.id].replacingId
					}
				}

				for (const id of state.status.purgeablePostSave) {
					delete state.objects[id]
				}

				if (!state.session.saved) {
					state.session.saved = true
				}
				return state
			})
			.addCase(saveAnswersThunk.rejected, (state, action) => {
				state.saving = false
				state.status.dirtyQueued = {
					...state.status.dirtyInFlight,
					...state.status.dirtyQueued,
				}
				return state
			})
			.addCase(doPublicFormSubmit.pending, (state, action) => {
				state.submitting = true
				return state
			})
			.addMatcher(
				isAnyOf(answerSlice.actions.updateAnswers, answerSlice.actions.addAnswerRow),
				(state, action) => {
					state.touched = true

					if (
						state.session.saved &&
						differenceInSeconds(new Date(), state.session.started) > FORM_SESSION_REPLACEMENT_SECONDS
					) {
						state.session = {
							id: uuid(),
							started: new Date(),
							saved: false,
						}
					}
					return state
				},
			)
			.addMatcher(isAnyOf(doPublicFormSubmit.fulfilled, doPublicFormSubmit.rejected), (state) => {
				state.submitting = false
				state.queuedSubmit = null
				return state
			})
			.addMatcher(isAnyOf(getAnswersPublicThunk.pending, getAnswersAuthThunk.pending), (state) => {
				state = { ...answerSlice.getInitialState() }
				state.loading = true
				return state
			})
			.addMatcher(
				isAnyOf(getAnswersPublicThunk.fulfilled, getAnswersAuthThunk.fulfilled),
				(state, action) => {
					state.touched = false
					state.objects = { ...answerSlice.getInitialState() }.objects
					action.payload.answers.forEach((a) => {
						state.objects[a.id] = a
					})
					return state
				},
			)
	},
})

export default answerSlice.reducer
