import React from 'react'
import { Dropdown, Typography } from 'antd'
import { MoreOutlined } from '@ant-design/icons'
import { format } from 'date-fns'
import { getUserDetails } from '../../../common/utils'
import { copyLinkAvailable, CopyLinkText } from '../../common/copy-link'
import { AddFormState } from '../add'
import { CYCLE_MAP, getStringFromCycle } from '../common'
import { useAppDispatch } from '@/utils/hooks'

const Title = Typography.Title

type Props = {
	state: AddFormState | null
	formAction: {
		reminderLast: Date | null
		reminderNext: Date | null
		completedOn: string | null
		assignedUser: { id: string } | null
		link: string | null
	} | null
}

export const Details = ({ state, formAction }: Props) => {
	const hasSchedule = state && !state.deleted
	const dispatch = useAppDispatch()
	const details = getUserDetails(state)
	return (
		<>
			{hasSchedule ?
				<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', maxWidth: 650 }}>
					<div>
						<Title level={5}>Assigned to</Title>
						{details.type} - {details.name}
						<br />
						{details.email}
					</div>

					<div>
						<Title level={5}>Due</Title>
						{format(new Date(new Date(state.dueAt)), 'dd/MM/yyyy')}
						<br />
						Repeating {CYCLE_MAP[getStringFromCycle(state)].label.toLocaleLowerCase()}
					</div>

					{formAction && (
						<div>
							{(formAction.reminderLast || formAction.reminderNext) && <Title level={5}>Reminders</Title>}
							{formAction.reminderLast && (
								<>
									Last: &nbsp;
									{format(new Date(new Date(formAction.reminderLast)), 'dd/MM/yyyy')}
									<br />
								</>
							)}
							{formAction.reminderNext && (
								<>
									Next: &nbsp;
									{format(new Date(new Date(formAction.reminderNext)), 'dd/MM/yyyy')}
								</>
							)}
						</div>
					)}
					{formAction && copyLinkAvailable(formAction) && (
						<div>
						<Dropdown
							menu={{
								items: [
									...(copyLinkAvailable(formAction) ?
										[
											{
												key: 'link',
												disabled: !formAction.link,
												label: <CopyLinkText link={formAction.link} reduxDispatch={dispatch} />,
											},
										]
									:	[]),
								],
							}}
							trigger={['click']}
						>
							<MoreOutlined style={{ transform: 'scale(1.4)' }} />
						</Dropdown>
						</div>
					)}
				</div>
			:	<p>
					Assign this form to a user or email. Users will be notified and reminded in order to complete this
					action. Reminders will stop after 1 year.
				</p>
			}

			<br />
			<br />
		</>
	)
}
