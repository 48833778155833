import buttonStyle from '@sass/common/button.module.sass'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SmallSpinner } from '@/components/common/loading'
import { hideModal } from '@/state/modal/actions'
import { ReduxState } from '@/types/redux'
import style from './index.module.sass'

const Modal = () => {
	const modal = useSelector((state: ReduxState) => state.modal)
	const dispatch = useDispatch()
	const [confirming, setConfirming] = useState(false)

	if (!modal) {
		return null
	}
	const cancel = () => {
		setConfirming(false)
		dispatch(hideModal())
		if (modal.cancelAction) {
			modal.cancelAction()
		}
	}
	const confirm = async () => {
		setConfirming(true)
		if (modal.confirmAction) {
			await modal.confirmAction()
		}
		setConfirming(false)
		dispatch(hideModal())
	}

	const { title, content, cancelText, confirmText, confirmClass } = modal

	return (
		<div className={style.modalBlocker}>
			<div className={style.modal}>
				<h4 className={style.title}>{title}</h4>
				<div>{content}</div>
				<div className={style.bottomButtons}>
					<button className={buttonStyle.greySolid} onClick={cancel}>
						{cancelText || 'Cancel'}
					</button>
					{confirming && <SmallSpinner />}
					{confirmText && (
						<button className={confirmClass} onClick={confirm}>
							{confirmText}
						</button>
					)}
				</div>
			</div>
		</div>
	)
}

export default Modal
