import React from 'react'
import { CheckCircleFilled, ExclamationCircleFilled } from '@ant-design/icons'
import { DatePicker, Input, Select, Spin } from 'antd'
import dayjs from 'dayjs'
import _pick from 'lodash/pick'
import {
	CYCLE_MAP,
	getStringFromCycle,
} from '@/components/form/form-control-panel/form-actions/edit-modal/recurring/common'
import { RowType } from '../../../common/const'
import { getRecordFormKey } from '../../../common/const'
import { Row } from '../../../common/row'
import { FormActionDashboardToUpdateQuery } from '../__gen'
import { RightEditRow } from '../right-edit-row'
import { RowFields } from '../use-bulk-save-schedules'
import style from './index.module.sass'

export type ScheduleRowProps = {
	row: RowType
	fields: RowFields
	saving: boolean
	error: string | null
	saved: boolean
	updateRowField: (t: { rowId: string; data: Partial<RowFields> }) => void
	recordAnswerOptions: NonNullable<FormActionDashboardToUpdateQuery['formActionContact_List']> | null
}

const NO_ESCALATION_VALUE = 'no-escalation'

export const ScheduleRow = ({
	row,
	fields,
	saving,
	error,
	saved,
	updateRowField,
	recordAnswerOptions,
}: ScheduleRowProps) => {
	const rowId = getRecordFormKey(row)
	const onCycleChange = (cycleString: string) => {
		const cycleObj = CYCLE_MAP[cycleString]
		if (cycleObj) {
			updateRowField({ rowId, data: _pick(cycleObj, 'unitName', 'unitAmount') })
		}
	}

	return (
		<Row
			left={
				<div className={style.formTitleContainer}>
					<div className={style.savingIndicator}>
						{saving && <Spin />}
						{error ?
							<ExclamationCircleFilled className={style.error} />
						: saved ?
							<CheckCircleFilled className={style.check} />
						:	null}
					</div>
					<div className={style.formTitle}>{row.form.title}</div>
				</div>
			}
			right={
				<RightEditRow
					dueAt={
						<DatePicker
							value={fields.dueAt ? dayjs(fields.dueAt) : null}
							onChange={(val) => updateRowField({ rowId, data: { dueAt: val } })}
						/>
					}
					duration={
						<Select
							style={{ width: 100 }}
							onChange={onCycleChange}
							value={
								fields.unitName && fields.unitAmount ?
									getStringFromCycle({ unitName: fields.unitName, unitAmount: fields.unitAmount })
								:	null
							}
							options={Object.entries(CYCLE_MAP).map(([key, value], i) => ({
								value: getStringFromCycle(value),
								label: value.label,
							}))}
						/>
					}
					requestSignature={
						<Input
							type="checkbox"
							checked={fields.requestSignature}
							onChange={(e) => updateRowField({ rowId, data: { requestSignature: e.target.checked } })}
						/>
					}
					userSuppliedContext={
						<Input
							value={fields.userSuppliedContext}
							onChange={(e) => updateRowField({ rowId, data: { userSuppliedContext: e.target.value } })}
						/>
					}
					assignedAnonEmailAnswer={
						<Select
							style={{ width: '100%', minWidth: 0, overflow: 'hidden' }}
							value={fields.assignedAnonEmailAnswer}
							onChange={(val) => {
								updateRowField({ rowId, data: { assignedAnonEmailAnswer: val } })
							}}
							options={
								recordAnswerOptions ?
									Object.values(recordAnswerOptions).map((option) => ({
										value: option.id,
										label: `${option.name} - ${option.email}`,
									}))
								:	[]
							}
							optionLabelProp="label"
							filterOption={(input, option) =>
								option ? option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false
							}
							showSearch
						>
							{recordAnswerOptions ?
								Object.values(recordAnswerOptions).map((option) => (
									<Select.Option key={option.id} value={option.id}>
										{`${option.name} (${option.email})`}
									</Select.Option>
								))
							:	[]}
						</Select>
					}
					escalationEmail={
						<Select
							style={{ width: '100%', minWidth: 0, overflow: 'hidden' }}
							value={fields.escalationEmailAnswer}
							onChange={(val) => {
								if (val === NO_ESCALATION_VALUE) {
									updateRowField({ rowId, data: { escalationEmailAnswer: null, escalationDeltaDays: null } })
								} else {
									updateRowField({ rowId, data: { escalationEmailAnswer: val } })
								}
							}}
							options={
								recordAnswerOptions ?
									[
										...Object.values(recordAnswerOptions).map((option) => ({
											value: option.id,
											label: `${option.name} - ${option.email}`,
										})),
										{ value: NO_ESCALATION_VALUE, label: 'No escalation' },
									]
								:	[]
							}
							optionLabelProp="label"
							filterOption={(input, option) =>
								option ? option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false
							}
							showSearch
						>
							{recordAnswerOptions ?
								[
									...Object.values(recordAnswerOptions).map((option) => (
										<Select.Option key={option.id} value={option.id}>
											{`${option.name} (${option.email})`}
										</Select.Option>
									)),
									<Select.Option key={'no-escalation'} value={NO_ESCALATION_VALUE}>
										No escalation
									</Select.Option>,
								]
							:	[]}
						</Select>
					}
					escalationDeltaDays={
						<Input
							type="number"
							value={fields.escalationDeltaDays === null ? '' : fields.escalationDeltaDays}
							onChange={(e) =>
								updateRowField({ rowId, data: { escalationDeltaDays: e.target.valueAsNumber } })
							}
						/>
					}
				/>
			}
		/>
	)
}
