import React from 'react'
import { SearchOutlined } from '@ant-design/icons'
import { Input, Switch } from 'antd'
import style from './index.module.sass'

type Props = {
	filterRowsWithoutData: boolean
	setFilterRowsWithoutData: (filterRowsWithoutData: boolean) => void
	search: string
	setSearch: (search: string) => void
}

export const Filters = ({ filterRowsWithoutData, setFilterRowsWithoutData, search, setSearch }: Props) => {
	return (
		<div className={style.container}>
			<div className={style.searchContainer}>
				<Input
					prefix={<SearchOutlined />}
					className={style.searchInput}
					placeholder="Search..."
					onChange={(e) => {
						const search = e.target.value
						setSearch(search)
					}}
				/>
			</div>
			<div className={style.filterNoDataContainer}>
				<Switch
					checked={filterRowsWithoutData}
					onChange={() => setFilterRowsWithoutData(!filterRowsWithoutData)}
				/>
				<div className={style.filterNoDataLabel}>Hide rows without data</div>
			</div>
		</div>
	)
}
