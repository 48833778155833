import React, { useEffect, useState } from 'react'
import { RocketOutlined } from '@ant-design/icons'
import { Tooltip } from 'antd'
import gql from 'graphql-tag'
import { RouteComponentProps } from 'react-router-dom'
import { SinglePanel } from '@/components/common/panel'
import Placeholder from '@/components/common/placeholder'
import { useGlobalContext } from '@/components/context'
import { useDebounce } from '@/utils/hooks'
import { FormActionMetricsUrlProps } from '@/utils/url'
import buttonStyle from '@sass/common/button.module.sass'
import { useFormActionDashboardTemplatesQuery } from './__gen'
import commonStyle from './common/common.module.sass'
import { ModeType } from './common/const'
import { FiltersAndFetch } from './fetch'
import { Filters } from './filters'
import style from './index.module.sass'
import { ViewModeTitle } from './page-title/view-mode-title'

gql`
	query formActionDashboardTemplates($moduleId: ID!) {
		template_List(module: $moduleId) {
			objects {
				id
				formSet {
					id
					forms(
						actionsFormat_In: [CUSTOMISABLE, PDF_CREATION]
						orderBy: [{ field: category_Rank, modifiers: [NULLS_FIRST] }, { field: rank }]
					) {
						id
						title
					}
				}
			}
		}
	}
`

type Props = RouteComponentProps<FormActionMetricsUrlProps>
export const FormActionManagement = ({ match }: Props) => {
	const startYear = parseInt(match.params.startYear)
	const startMonth = parseInt(match.params.startMonth)

	const [saveCount, setSaveCount] = useState(0)

	const globalContext = useGlobalContext()
	const { data, loading } = useFormActionDashboardTemplatesQuery({
		variables: {
			moduleId: globalContext.currentModule.id,
		},
	})

	const [filterRowsWithoutData, setFilterRowsWithoutData] = useState(false)
	const [search, setSearch] = useState('')
	const [debouncedSearch, setDebouncedSearch] = useState(search)
	useDebounce(() => setDebouncedSearch(search), 200, [search])

	const [mode, setMode] = useState<ModeType>('view')

	const [selectedRows, setSelectedRows] = useState<Set<string>>(new Set([]))

	const resetList = () => {
		setMode('view')
		setSaveCount((prev) => prev + 1)
		setSelectedRows(new Set())
	}

	const rerenderKey = `y:${startYear}|m:${startMonth}|filterRows:${filterRowsWithoutData}|search:${debouncedSearch}|saveCount:${saveCount}`

	useEffect(() => {
		setSelectedRows(new Set())
	}, [rerenderKey, setSelectedRows])

	return (
		<SinglePanel noPadding type="full-width" noBoxShadow>
			<div className={style.container}>
				{mode == 'view' && (
					<>
						<ViewModeTitle startYear={startYear} startMonth={startMonth} />
						<div className={commonStyle.controlsRow}>
							<Filters
								filterRowsWithoutData={filterRowsWithoutData}
								setFilterRowsWithoutData={setFilterRowsWithoutData}
								search={search}
								setSearch={setSearch}
							/>
							<div className={commonStyle.controlButtons}>
								{globalContext.user.isAdmin && (
									<>
										<Tooltip title="This feature is admin only.">
											<RocketOutlined style={{color: 'purple'}} />
										</Tooltip>
										<button className={buttonStyle.greySolid} onClick={() => setMode('select')}>
											Select Rows
										</button>
									</>
								)}
							</div>
						</div>
					</>
				)}

				{loading &&
					[...Array(5).keys()].map((i) => (
						<div key={i}>
							<Placeholder style={{ margin: '10px 0' }} />
						</div>
					))}
				{!loading && (
					<div className={style.table}>
						{!data?.template_List ?
							'Error loading template data'
						:	<FiltersAndFetch
								key={rerenderKey}
								startYear={startYear}
								startMonth={startMonth}
								search={debouncedSearch}
								filterRowsWithoutData={filterRowsWithoutData}
								templates={Object.assign({}, ...data.template_List.objects.map((t) => ({ [t.id]: t })))}
								setMode={setMode}
								mode={mode}
								selectedRows={selectedRows}
								setSelectedRows={setSelectedRows}
								resetList={resetList}
							/>
						}
					</div>
				)}
			</div>
		</SinglePanel>
	)
}
