import React, { useRef, useState } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { format } from 'date-fns'
import subMinutes from 'date-fns/subMinutes'
import { IconTextInput } from '@/components/common/inputs'

import { AnswerDatetimeBaseProps } from '../types'
import commonStyle from '../common.module.sass'
import style from './index.module.sass'
import { useAppDispatch } from '@/utils/hooks'
import { updateAnswersThunk } from '@/state/answer/thunks'
import iconStyle from '@sass/common/icon.module.sass'
import ReactDatePicker from 'react-datepicker'

const MAX_DATE = new Date(2100, 0, 1)
const MIN_DATE = new Date(1850, 0, 1)

interface IState {
	date: Date | null
}

// NOTE: BE CAREFUL WITH TIMEZONES!!!!!
const subTimezoneToLocal = (content: string) => {
	// Replace the timezone WITHOUT chaging time
	// e.g. ie 10am UTC => 10am PST

	// ensure the timezone is from the date, NOT the current time!
	// (very sneaky)
	const tzOffset = new Date(content).getTimezoneOffset()
	return subMinutes(new Date(content), -tzOffset)
}

const localToIsoString = (date: Date) => {
	const tzOffset = date.getTimezoneOffset()
	return subMinutes(date, tzOffset).toISOString()
}

const AnswerDate = (props: AnswerDatetimeBaseProps) => {
	const [date, setDate] = useState(props.answer.content ? subTimezoneToLocal(props.answer.content) : null)
	const dispatch = useAppDispatch()
	
	const onChange = (date: Date | null) => {
		setDate(date)
		dispatch(updateAnswersThunk({answers: [{ ...props.answer, content: date ? localToIsoString(date) : null }]}))
	}

	const ref = useRef<ReactDatePicker>(null)

	const openDatePicker = () => {
		if (ref.current) {
			ref.current.setFocus()
		}
	}

	return <div className={style.dateInput}>

			{ props.disabled ?
				<div>
					<span className={`${style.iconCalendar} ${iconStyle.calendar}`} onClick={openDatePicker}></span>
					{ date ?
						format(date, "dd-MM-yyyy")
					:
						<i className={commonStyle.noContent}>No content</i>
					}
				</div>
			:
				<DatePicker
					ref={ref}
					placeholderText={props.field.placeholder || undefined}
					disabledKeyboardNavigation
					selected={date}
					onChange={onChange} 
					maxDate={MAX_DATE}
					minDate={MIN_DATE}
					fixedHeight
					dateFormat="dd/MM/yyyy"
					popperPlacement="top-end"
					customInput={<IconTextInput iconClass={iconStyle.calendar} />}
				/>
			}
	</div>
}

export default AnswerDate