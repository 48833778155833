import React, { useState } from 'react'
import c from 'classnames'

import { updateAnswersThunk } from '@/state/answer/thunks'
import { useAppDispatch } from '@/utils/hooks'
import { Input } from '@/components/common/inputs'
import { TableFieldEnumType } from '@/state/main-form/types'
import { isAnswerValid } from '@/state/answer/utils'
import { isValidUrl } from '@/utils/functions'
import generalStyle from '@sass/common/general.module.sass'
import iconStyle from '@sass/common/icon.module.sass'
import { AnswerTextBaseProps } from '../types'
import commonStyle from '../common.module.sass'
import style from './index.module.sass'


// const useOnSave = (reset: () => void) => {
// 	const isDirty = useAppSelector(state => !!(
// 		Object.keys(state.answers.status.dirtyInFlight).length
// 		+ Object.keys(state.answers.status.dirtyQueued).length)
// 	)
// 	const [prevUpdated, setPrevUpdated] = useState(false)

// 	useEffect(() => {
// 		if (prevUpdated != isDirty && !isDirty) {
// 			reset()
// 		}
// 		setPrevUpdated(isDirty)
// 	}, [isDirty])
// }

const AnswerLink = (props: AnswerTextBaseProps) => {
	const dispatch = useAppDispatch()
	const [editMode, setEditMode] = useState(!props.answer.content)
	const isValid = isAnswerValid(props.field.type as TableFieldEnumType, props.answer)
	// useOnSave(() => {setEditMode(false)})
	return (
		<div className={style.container}>
			{(props.disabled || !editMode)
				? (
					<>
						{props.answer.content && isValidUrl(props.answer.content)
							? (
								<a href={props.answer.content} className={c(style.clickable, iconStyle.link)} target="_blank">
									{props.answer.content}
								</a>
							) : (
								<i className={c(commonStyle.noContent, style.clickable)}>
									{props.answer.content ? "Invalid URL" : "No content"}
								</i>
							)
						}
						{!props.disabled &&
							<div onClick={() => setEditMode(true)} className={c(iconStyle.pencil, style.editButton)} />
						}
					</>
				) : (
					<div className={style.inputContainer}>
						<Input
							className={commonStyle.formInput}
							onChange={(e) => dispatch(updateAnswersThunk({ answers: [{ ...props.answer, content: e.target.value }] }))}
							placeholder={props.field.placeholder || undefined}
							value={props.answer.content || ""}
						/>
						{props.answer.content && !isValid &&
							<div className={generalStyle.error}>Invalid link</div>
						}
					</div>
				)
			}
		</div>
	)
}

export default AnswerLink