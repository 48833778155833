import React from 'react'

import style from './index.module.sass'


type Props = {
	left: React.ReactNode
	right: React.ReactNode
}
export const DataItem = ({left, right}: Props) => {
	return (
		<div className={style.container}>
			<div className={style.left}>{left}</div>
			<div className={style.right}>{right}</div>
		</div>
	)
}