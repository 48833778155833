import * as Types from '@/__gen/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FormActionDashboardToUpdateQueryVariables = Types.Exact<{
  recordIdFormIdJsonList: Types.Scalars['String']['input'];
  recordIds: Array<Types.Scalars['ID']['input']>;
}>;


export type FormActionDashboardToUpdateQuery = { __typename: 'PharosQuery', formActionSchedule_List: { __typename: 'FormActionScheduleTypeListBase', objects: Array<{ __typename: 'FormActionScheduleType', id: string, firstDueAt: string, assignedAnonEmail: string, assignedAnonName: string, dueAt: string, unitAmount: number, unitName: Types.FormActionFormActionScheduleUnitNameChoices, requestSignature: boolean, userSuppliedContext: string, assignedAnonEmailAnswer: { __typename: 'AnswerTextPubType', id: string, content: string | null, contactName: string | null } | null, record: { __typename: 'RecordType', id: string }, form: { __typename: 'FormType', id: string } }>, pageInfo: { __typename: 'PageInfo', hasNextPage: boolean | null, total: number | null } } | null, formActionEscalation_List: { __typename: 'FormActionEscalationTypeListBase', objects: Array<{ __typename: 'FormActionEscalationType', id: string, deltaDays: number, assignedAnonEmailAnswer: { __typename: 'AnswerTextPubType', id: string, content: string | null, contactName: string | null } | null, record: { __typename: 'RecordType', id: string }, form: { __typename: 'FormType', id: string } }>, pageInfo: { __typename: 'PageInfo', hasNextPage: boolean | null, total: number | null } } | null, formActionContact_List: Array<{ __typename: 'FormActionContact', id: string, objId: string, email: string, name: string, fieldTitle: string }> | null };


export const FormActionDashboardToUpdateDocument = gql`
    query formActionDashboardToUpdate($recordIdFormIdJsonList: String!, $recordIds: [ID!]!) {
  formActionSchedule_List(
    limit: 5000
    offset: 0
    recordIdFormIdJsonList: $recordIdFormIdJsonList
  ) {
    objects {
      id
      firstDueAt
      assignedAnonEmail
      assignedAnonName
      dueAt
      unitAmount
      unitName
      requestSignature
      userSuppliedContext
      assignedAnonEmailAnswer {
        id
        content
        contactName
      }
      record {
        id
      }
      form {
        id
      }
    }
    pageInfo {
      hasNextPage
      total
    }
  }
  formActionEscalation_List(
    limit: 5000
    offset: 0
    recordIdFormIdJsonList: $recordIdFormIdJsonList
  ) {
    objects {
      id
      deltaDays
      assignedAnonEmailAnswer {
        id
        content
        contactName
      }
      record {
        id
      }
      form {
        id
      }
    }
    pageInfo {
      hasNextPage
      total
    }
  }
  formActionContact_List(recordIds: $recordIds) {
    id
    objId
    email
    name
    fieldTitle
  }
}
    `;

/**
 * __useFormActionDashboardToUpdateQuery__
 *
 * To run a query within a React component, call `useFormActionDashboardToUpdateQuery` and pass it any options that fit your needs.
 * When your component renders, `useFormActionDashboardToUpdateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFormActionDashboardToUpdateQuery({
 *   variables: {
 *      recordIdFormIdJsonList: // value for 'recordIdFormIdJsonList'
 *      recordIds: // value for 'recordIds'
 *   },
 * });
 */
export function useFormActionDashboardToUpdateQuery(baseOptions: Apollo.QueryHookOptions<FormActionDashboardToUpdateQuery, FormActionDashboardToUpdateQueryVariables> & ({ variables: FormActionDashboardToUpdateQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FormActionDashboardToUpdateQuery, FormActionDashboardToUpdateQueryVariables>(FormActionDashboardToUpdateDocument, options);
      }
export function useFormActionDashboardToUpdateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FormActionDashboardToUpdateQuery, FormActionDashboardToUpdateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FormActionDashboardToUpdateQuery, FormActionDashboardToUpdateQueryVariables>(FormActionDashboardToUpdateDocument, options);
        }
export function useFormActionDashboardToUpdateSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FormActionDashboardToUpdateQuery, FormActionDashboardToUpdateQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FormActionDashboardToUpdateQuery, FormActionDashboardToUpdateQueryVariables>(FormActionDashboardToUpdateDocument, options);
        }
export type FormActionDashboardToUpdateQueryHookResult = ReturnType<typeof useFormActionDashboardToUpdateQuery>;
export type FormActionDashboardToUpdateLazyQueryHookResult = ReturnType<typeof useFormActionDashboardToUpdateLazyQuery>;
export type FormActionDashboardToUpdateSuspenseQueryHookResult = ReturnType<typeof useFormActionDashboardToUpdateSuspenseQuery>;
export type FormActionDashboardToUpdateQueryResult = Apollo.QueryResult<FormActionDashboardToUpdateQuery, FormActionDashboardToUpdateQueryVariables>;