import React, { useEffect, useRef } from 'react'
import c from 'classnames'

import { ErrorBoundary } from '@/components/common/error-boundary'
import { SinglePanel } from '@/components/common/panel'
import { FormInputPanel } from './form-input-panel'
import { FormControlPanel } from './form-control-panel'
import { useFormContext } from '@/state/main-form/reducer'
import style from './form.module.sass'
import { useAppDispatch, useAppSelector, useTitle } from '@/utils/hooks'
import { toggleFullScreenField } from '@/state/user-interface'


const Form = () => {
	const formContext = useFormContext()
	const scrollContainerRef = useRef<HTMLDivElement>(null)

	const fullScreenField = useAppSelector(state => state.userInterface.fullScreenField)
	const dispatch = useAppDispatch()

	useEffect(() => {
		return () => {
			if (fullScreenField) {
				dispatch(toggleFullScreenField(null))
			}
		}
	}, [fullScreenField])

	useTitle(formContext.form.title + ': ' + formContext.record.name)

	return (
		<div className={c(style.container, {[style.externalForm]: !!formContext.publicForm?.code})}>
			<SinglePanel
				ref={scrollContainerRef}
				type="full-width"
				removeScrollForMobile={!!formContext.publicForm?.code}
			>
				<ErrorBoundary>
					<FormInputPanel scrollContainerRef={scrollContainerRef} />
				</ErrorBoundary>
			</SinglePanel>
			{!formContext.form.hideControlPanel &&
				<div className={style.rightPanelContainer}>
					<ErrorBoundary>
						<FormControlPanel />
					</ErrorBoundary>
				</div>
			}
		</div>
	)
}

export default Form