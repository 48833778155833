import React, { useState } from 'react'
import { MoreOutlined } from '@ant-design/icons'
import { DatePicker, Input, Popover, Select } from 'antd'
import c from 'classnames'
import _pick from 'lodash/pick'
import { useGlobalContext } from '@/components/context'
import { getStringFromCycle } from '@/components/form/form-control-panel/form-actions/edit-modal/recurring/common'
import { CYCLE_MAP } from '@/components/form/form-control-panel/form-actions/edit-modal/recurring/common'
import buttonStyle from '@sass/common/button.module.sass'
import { RowType, getRecordFormKey } from '../../../common/const'
import { Row } from '../../../common/row'
import { FormActionDashboardToUpdateQuery } from '../__gen'
import { RightEditRow } from '../right-edit-row'
import { RowFields } from '../use-bulk-save-schedules'
import style from './index.module.sass'

type Props = {
	fields: Record<string, RowFields>
	setFields: (fields: Record<string, RowFields>) => void
	rows: RowType[]
	answerOptions: Record<string, NonNullable<FormActionDashboardToUpdateQuery['formActionContact_List']>>
}

export const EditHeaders = ({ fields, rows, setFields, answerOptions }: Props) => {
	const globalContext = useGlobalContext()
	const [nthAnswer, setNthAnswer] = useState(1)

	const applyToAll = (v: Partial<RowFields>) => {
		const next = { ...fields }
		rows.forEach((r) => {
			const key = getRecordFormKey(r)
			next[key] = { ...next[key], ...v }
		})
		setFields(next)
	}

	const applyNthAnswerToAll = (fieldName: keyof RowFields) => {
		const next = { ...fields }
		rows.forEach((r) => {
			const key = getRecordFormKey(r)
			const recordOptions = answerOptions[r.record.id]
			if (!recordOptions) {
				return
			}
			const id = recordOptions[nthAnswer - 1]?.id
			if (id) {
				next[key] = { ...next[key], [fieldName]: id }
			}
		})
		setFields(next)
	}

	return (
		<div className={style.container}>
			<Row
				left={<div className={c(style.header, style.left)}>{globalContext.currentModule.recordWord}</div>}
				right={
					<div className={style.header}>
						<RightEditRow
							dueAt={
								<Popover content={<DatePicker onChange={(val) => applyToAll({ dueAt: val })} />}>
									<div className={style.rightHeader}>
										<div>DueAt</div>
										<MoreOutlined />
									</div>
								</Popover>
							}
							duration={
								<Popover
									content={
										<Select
											style={{ width: 100 }}
											onChange={(a) => applyToAll(_pick(CYCLE_MAP[a], 'unitAmount', 'unitName'))}
											options={Object.entries(CYCLE_MAP).map(([key, value], i) => ({
												value: getStringFromCycle(value),
												label: value.label,
											}))}
										/>
									}
								>
									<div className={style.rightHeader}>
										<div>Duration</div>
										<MoreOutlined />
									</div>
								</Popover>
							}
							requestSignature={
								<Popover
									content={
										<Input
											type="checkbox"
											checked={rows.every((r) => fields[getRecordFormKey(r)].requestSignature)}
											onChange={(e) => applyToAll({ requestSignature: e.target.checked })}
										/>
									}
								>
									<div className={style.rightHeader}>
										<div>Signature</div>
										<MoreOutlined />
									</div>
								</Popover>
							}
							userSuppliedContext={
								<Popover
									content={
										<div>
											<div>
												<b>Email context</b>
											</div>

											<Input onChange={(e) => applyToAll({ userSuppliedContext: e.target.value })} />
										</div>
									}
								>
									<div className={style.rightHeader}>
										<div>Context</div>
										<MoreOutlined />
									</div>
								</Popover>
							}
							assignedAnonEmailAnswer={
								<Popover
									content={
										<div>
											Set to item # &nbsp;
											<Input
												value={nthAnswer}
												className={style.numberInput}
												type="number"
												onChange={(e) => setNthAnswer(e.target.valueAsNumber)}
											/>
											&nbsp; in the list <br />
											<button
												className={c(buttonStyle.small, buttonStyle.primarySolid)}
												onClick={() => applyNthAnswerToAll('assignedAnonEmailAnswer')}
											>
												Set
											</button>
										</div>
									}
								>
									<div className={style.rightHeader}>
										<div>Email</div>
										<MoreOutlined />
									</div>
								</Popover>
							}
							escalationEmail={
								<Popover
									content={
										<div>
											Set to item # &nbsp;
											<Input
												value={nthAnswer}
												className={style.numberInput}
												type="number"
												onChange={(e) => setNthAnswer(e.target.valueAsNumber)}
											/>
											&nbsp; in the list <br />
											<button
												className={c(buttonStyle.small, buttonStyle.primarySolid)}
												onClick={() => applyNthAnswerToAll('escalationEmailAnswer')}
											>
												Set
											</button>
										</div>
									}
								>
									<div className={style.rightHeader}>
										<div>Escalation</div>
										<MoreOutlined />
									</div>
								</Popover>
							}
							escalationDeltaDays={
								<Popover
									content={
										<div>
											<div>
												<b>Days until escalation</b>
											</div>
											<Input
												type="number"
												className={style.numberInput}
												onChange={(e) => applyToAll({ escalationDeltaDays: e.target.valueAsNumber })}
											/>
										</div>
									}
								>
									<div className={style.rightHeader}>
										<div>Days</div>
										<MoreOutlined />
									</div>
								</Popover>
							}
						/>
					</div>
				}
			/>
		</div>
	)
}
