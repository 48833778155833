import { Select } from 'antd'
import gql from 'graphql-tag'
import React from 'react'
import Placeholder from '@/components/common/placeholder'
import { useFormContext } from '@/state/main-form/reducer'
import generalStyle from '@sass/common/general.module.sass'
import { FormActionContactQuery, useFormActionContactQuery } from './__gen'

const { Option } = Select

type ContactList = NonNullable<FormActionContactQuery['formActionContact_List']>[number]

type Props = {
	onSelect: (c: ContactList) => void
	disabledAnswers: string[]
}

gql`
	query formActionContact($recordIds: [ID!]!) {
		formActionContact_List(recordIds: $recordIds) {
			id
			email
			name
			fieldTitle
		}
	}
`

export const SelectContact = ({ onSelect, disabledAnswers }: Props) => {
	const { record } = useFormContext()

	const variables = { variables: { recordIds: [record.id] } }
	const { data, loading } = useFormActionContactQuery(variables)

	const contacts = data?.formActionContact_List

	return (
		<div>
			{contacts ?
				<Select
					style={{ width: 380 }}
					placeholder="Select Email"
					onChange={(e) => onSelect(contacts.find((c) => c.id === e)!)}
				>
					{contacts.map((c) => (
						<Option key={c.id} disabled={!!disabledAnswers.find((id) => id == c.id)} value={c.id}>
							{c.name} - {c.email}
						</Option>
					))}
				</Select>
			: loading ?
				[...Array(6)].map((_, i) => (
					<Placeholder key={i} style={{ width: 120, height: 28, margin: '3px 0px' }} />
				))
			:	<div className={generalStyle.error}>error</div>}
		</div>
	)
}
