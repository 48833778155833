

import React from 'react'
import gql from "graphql-tag"
import c from 'classnames'

import { SmallSpinner } from '@/components/common/loading'
import { useGlobalContext } from '@/components/context'
import { showNotification } from '@/state/user-interface'
import { apolloClient } from '@/state/apollo-client'
import { useAppDispatch } from '@/utils/hooks'
import iconStyle from '@sass/common/icon.module.sass'
import style from './team-item.module.sass'
import { useCreateUserTeamMutation, useRemoveUserTeamMutation } from './__gen/team-item'
import { UserToManageQuery } from '../__gen/edit'
import { TeamsToAssignQuery } from './__gen'

type UserType = NonNullable<UserToManageQuery["user"]>
type TeamType = NonNullable<TeamsToAssignQuery["team_List"]>["objects"][number]


gql`
	mutation createUserTeam(
		$team: ID!,
		$user: ID!,
	) {
		userTeam_Update(input: {
			team: $team,
			user: $user
		}) {
			object {
				id
				active
				team {
					id
				}
				user {
					id
				}
			}
		}
	}
`

gql`
	mutation removeUserTeam(
		$id: ID!
	) {
		userTeam_Update(input: {
			id: $id,
			active: false
		}) {
			object {
				id
				active
			}
		}
	}
`

type Props = {
	editable: boolean
	team: TeamType
	user: UserType
}


export default (props: Props) => {
	const [remove, removeDetails] = useRemoveUserTeamMutation()
	const [add, addDetails] = useCreateUserTeamMutation()
	const loading = removeDetails.loading || addDetails.loading
	const dispatch = useAppDispatch()
	const globalContext = useGlobalContext();

	const updateCache = (memberId: string | null) => {
		apolloClient.writeFragment({
			id: 'TeamType:' + props.team.id,
			fragment: gql`
				fragment _ on TeamType {
					memberId(userId: "${props.user.id}")
				}
			`,
			data: {
				memberId,
				__typename: 'TeamType'
			},
		})
	}

	if(!props.editable && !props.team.memberId) return null

	return (
		<div className={style.teamItem}>
			{loading
				? <div  className={style.spinnerContainer} ><SmallSpinner/>&nbsp;</div>
				: props.team.memberId
					? <div
						className={c(
							iconStyle.tick,
							style.icon,
							{
								[style.notEditable]: !props.editable,
								[style.matched]: props.editable,
							},
						)}
						onClick={ async () => {
							await remove({variables: {id: props.team.memberId as string}})
							dispatch(showNotification({
								content: `Removed ${props.user.firstName} from ${props.team.name}`,
							}))
							updateCache(null)
						}}
					/>
					: <div
						className={c(iconStyle.tick, style.icon)}
						onClick={ async () => {
							const r = await add({variables: {user: props.user.id, team: props.team.id}})
							dispatch(showNotification({
								content: `Added ${props.user.firstName} to ${props.team.name}`,
							}))
							updateCache(r.data!.userTeam_Update!.object!.id)
						}}
					/>
			}
			{globalContext.organisation.suite.moduleSetItems.length >= 2 &&
				`${props.team.module.name}: `
			}
			<b>&nbsp;{props.team.name}</b>
		</div>
			
	)
}