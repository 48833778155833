import React from 'react'
import { selectAnswerMissingForField } from '@/state/answer/selectors'
import { FieldType } from '@/state/main-form/types'
import { useAppSelector } from '@/utils/hooks'
import commonStyle from './commonStyle.module.sass'
import { Alert } from 'antd'


type Props = {
	field: FieldType
}

export const MissingAnswer = ({ field }: Props) => {
	const isMissing = useAppSelector((s) => selectAnswerMissingForField(s, field.id))

	return (
		<div className={commonStyle.errorRow}>
			<Alert 
				message={field.title}
				type={isMissing ? "error" : "success"}
			/>
		</div>
	)
}
