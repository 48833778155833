import React, { useState } from 'react'
import c from 'classnames'
import gql from 'graphql-tag'
import { Route } from 'react-router-dom'
import { FormFormActionsFormatChoices } from '@/__gen/types'
import { useGlobalContext, useRouter } from '@/components/context'
import { changeFormPdfStatus } from '@/components/dashboard/pages/module/left-side-panel/record'
import { showErrorsAndFlush } from '@/state/answer/thunks'
import { useFormContext } from '@/state/main-form/reducer'
import { useAppDispatch, useAppSelector } from '@/utils/hooks'
import { formSignOffUrl, previewPdfFileApiUrl } from '@/utils/url'
import buttonStyle from '@sass/common/button.module.sass'
import generalStyle from '@sass/common/general.module.sass'
import iconStyle from '@sass/common/icon.module.sass'
import commonStyle from '../common.module.sass'
import { PdfGeneratorsForFormDocument, PdfGeneratorsForFormQuery } from './__gen'
import { useCreatePdfMutation } from './__gen/pdf-generator'
import { PDF_FRAGMENT } from './pdf'
import style from './pdf-generator.module.sass'
import { PdfList } from './pdf-list'
import { SignOff } from './sign-off'

type Props = {
	pdfGenerator: NonNullable<PdfGeneratorsForFormQuery['pdfGenerator_List']>['objects'][number]
	recordId: string
	formId: string
	readOnly: boolean
}

gql`
	mutation createPdf($pdfGenerator: ID!, $form: ID!, $record: ID!, $name: String!, $createdBy: ID!) {
		pdf_Update(
			input: { pdfGenerator: $pdfGenerator, record: $record, form: $form, name: $name, createdBy: $createdBy }
		) {
			object {
				...Pdf
			}
		}
	}
	${PDF_FRAGMENT}
`

const PdfGenerator = (props: Props) => {
	const dispatch = useAppDispatch()
	const [showPdfs, setShowPdfs] = useState(1)
	const globalContext = useGlobalContext()
	const formContext = useFormContext()
	const router = useRouter()

	const [seeMore, setSeeMore] = useState(false)
	const [creating, setCreating] = useState(false)
	const [createPdfMutation] = useCreatePdfMutation()

	const onPdfCreate = () => {
		setShowPdfs((n) => n + 1)
		changeFormPdfStatus(props.formId, props.recordId, true)
	}

	const createPdf = async () => {
		if (creating) {
			return
		}

		setCreating(true)
		const success = await dispatch(showErrorsAndFlush({}))
		if (!success) {
			setCreating(false)
			return
		}
		const response = await createPdfMutation({
			variables: {
				pdfGenerator: props.pdfGenerator.id,
				record: props.recordId,
				form: props.formId,
				name: props.pdfGenerator.name,
				createdBy: globalContext.user.id,
			},
			// Wait for dependant queries to finish loading with new pdf
			// before we take away loading card
			awaitRefetchQueries: true,
			refetchQueries: [
				{
					query: PdfGeneratorsForFormDocument,
					variables: { formId: props.formId, recordId: props.recordId },
				},
			],
		})
		const pdf = response.data?.pdf_Update?.object

		setCreating(false)
		if (!pdf) {
			alert('An unknown error occurred creating your PDF.')
			return
		}

		onPdfCreate()
	}
	const formIsDirty = useAppSelector((state) => {
		return (
			Object.keys(state.answers.status.dirtyInFlight).length +
				Object.keys(state.answers.status.dirtyQueued).length >
			0
		)
	})

	const checkIfFormIsDirtyForPreview = async (e: React.SyntheticEvent) => {
		if (formIsDirty) {
			// When we have prefilled answers, we only save them when a user touches the form.
			// This is to avoid mutations / saves if a user is clicking though forms.
			// Due to browser security restrictions, we pop open a new tab after a promise, so
			// block and make the user try again.
			e.preventDefault()

			// Skip missing answers in this case, as these only matter if we are submitting
			await dispatch(showErrorsAndFlush({ skipMissingAnswerCheck: true }))
			alert('A save was in progress when the preview was requested.\n\nPlease try again.')
		}
	}
	const shownPdfs = seeMore ? props.pdfGenerator.pdfs : props.pdfGenerator.pdfs.slice(0, showPdfs)
	const hiddenPdfs = props.pdfGenerator.pdfs.length - showPdfs

	const signOffUrl = formSignOffUrl({ ...props, ...globalContext, pdfGeneratorId: props.pdfGenerator.id })
	const goToSignoff = async () => {
		const valid = await dispatch(showErrorsAndFlush({}))
		if (valid) {
			router.history.push(signOffUrl)
		}
	}

	return (
		<div>
			<div className={commonStyle.sectionTitle}>{props.pdfGenerator.name}</div>

			<div className={style.createPdfButtonContainer}>
				<a
					onClick={checkIfFormIsDirtyForPreview}
					target="_blank"
					href={previewPdfFileApiUrl({ ...props, pdfGeneratorId: props.pdfGenerator.id })}
				>
					<button className={c(iconStyle.eye, buttonStyle.greySolid)}>Preview</button>
				</a>
				{formContext.form.actionsFormat == FormFormActionsFormatChoices.PdfCreation ?
					<button className={c(iconStyle.doc, buttonStyle.greySolid)} onClick={goToSignoff}>
						Sign Off
					</button>
				:	<button className={c(iconStyle.doc, buttonStyle.greySolid)} onClick={createPdf}>
						Create
					</button>
				}
			</div>

			{props.readOnly && props.pdfGenerator.pdfs.length == 0 && (
				<div className={generalStyle.muted}>No pdfs</div>
			)}

			<PdfList
				creating={creating}
				newPdfName={props.pdfGenerator.name}
				pdfs={shownPdfs.filter((pdf) => pdf.isActive)}
			/>

			{hiddenPdfs > 0 && (
				<div className={style.loadMoreButtonContainer}>
					<button
						className={c(buttonStyle.noOutline, buttonStyle.small, style.loadMoreButton)}
						onClick={() => setSeeMore(!seeMore)}
					>
						{seeMore ? 'Hide history' : 'View history'}
					</button>
				</div>
			)}

			<Route
				path={signOffUrl}
				component={() => <SignOff pdfGenerator={props.pdfGenerator} onPdfCreate={onPdfCreate} />}
			/>
		</div>
	)
}

export default PdfGenerator
