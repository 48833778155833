import gql from 'graphql-tag'


// This is duplicated from form-actions/common, not sure why apollo complains...
export const FORM_ACTION_FRAGMENT = gql`
	fragment FormAction on FormActionType {
		id
		assignedUser {
			id
			firstName
			lastName
			email
		}
		assignedAnonEmail
		assignedAnonName
		assignedAnonEmailAnswer {
			id
			content
			contactName
		}
		completedOn
		deleted
		form {
			pdfGenerators {
				id
				name
			}
		}
		isScheduled
		link
		priority
		reminderNext
		reminderLast
		requestSignature
		userSuppliedContext
	}
`

// This is duplicated from form-actions/common, not sure why apollo complains...
export const FORM_ACTION_SCHEDULE_FRAGMENT = gql`
	fragment FormActionSchedule on FormActionScheduleType {
		id
		assignedAnonEmail
		assignedAnonName
		assignedAnonEmailAnswer {
			id
			content
			contactName
		}
		assignedUser {
			id
			firstName
			lastName
			email
		}
		deleted
		dueAt
		requestSignature
		unitAmount
		unitName
		userSuppliedContext
	}
`

// Warning: refetchQueries references the name of this query
gql`
	query formActionsForRecord($record: ID!, $form: ID!) {
		formAction_List(record: $record, form: $form, deleted: false, orderBy: {field: priority}) {
			objects {
				...FormAction
			}
		}
		formActionSchedule_List(record: $record, form: $form) {
			objects {
				...FormActionSchedule
			}
		}
	}
	${FORM_ACTION_FRAGMENT}
	${FORM_ACTION_SCHEDULE_FRAGMENT}
`
