import { CheckOutlined, MinusOutlined, MoreOutlined } from '@ant-design/icons'
import { Dropdown } from 'antd'
import c from 'classnames'
import { format } from 'date-fns'
import gql from 'graphql-tag'
import React from 'react'
import { useGlobalContext } from '@/components/context'
import { useFormContext } from '@/state/main-form/reducer'
import { getFormAuth } from '@/state/main-form/thunks'
import { useAppDispatch } from '@/utils/hooks'
import generalStyle from '@sass/common/general.module.sass'
import { FormActionsForRecordDocument, FormActionsForRecordQuery } from '../../common/__gen/query'
import { FORM_ACTION_FRAGMENT } from '../../common/query'
import { getUserDetails } from '../../common/utils'
import { CompleteFormActionButton } from '../../complete-button'
import { useUndoFormActionMutation } from './__gen'
import style from './index.module.sass'

export type FormAction = NonNullable<FormActionsForRecordQuery['formAction_List']>['objects'][0]
export type FormActionSchedule = NonNullable<
	FormActionsForRecordQuery['formActionSchedule_List']
>['objects'][0]

gql`
	mutation undoFormAction($id: ID!) {
		formAction_Update(input: { id: $id, completedOn: null, signatureImage: null, signatureText: "" }) {
			object {
				...FormAction
			}
		}
	}
	${FORM_ACTION_FRAGMENT}
`

type Props = {
	formAction: FormAction
	formActionSchedule: FormActionSchedule | null
	current: FormAction | null
}

export const SummaryRow = ({ formAction, formActionSchedule, current }: Props) => {
	const globalContext = useGlobalContext()
	const formContext = useFormContext()
	const dispatch = useAppDispatch()

	const [undoFormAction] = useUndoFormActionMutation({
		variables: {
			id: formAction.id,
		},
		// I don't think we support rolling back due dates yet, but just in case we refetch
		refetchQueries: [FormActionsForRecordDocument],
		onCompleted: () => {
			// Refetch form as it may be unlocked now
			dispatch(getFormAuth({ formId: formContext.form.id, recordId: formContext.record.id }))
		},
	})

	const isAuthUser = formAction.assignedUser?.id == globalContext.user.id
	const details = getUserDetails(formAction)

	return (
		<div className={style.container}>
			<div className={c(style.iconContainer, { [style.completeIconContainer]: formAction.completedOn })}>
				{formAction.completedOn ?
					<CheckOutlined className={style.completeIcon} />
				:	<MinusOutlined className={style.incompleteIcon} />}
			</div>

			<div className={style.rightContent}>
				<div className={style.topRow}>
					{formAction.assignedUser?.firstName || formAction.assignedAnonName}
				</div>
				{formAction.completedOn ?
					<div className={generalStyle.muted}>Completed on {format(new Date(formAction.completedOn), 'dd/MM/yyyy')}</div>
				: isAuthUser ?
					<div className={style.buttonContainer}>
						<CompleteFormActionButton formAction={formAction} />
					</div>
				:	<div className={generalStyle.muted}>Waiting for {details.name}</div>}
			</div>

			{isAuthUser && formAction.completedOn && !formAction.isScheduled && (
				<Dropdown
					menu={{
						items: [
							{
								key: '1',
								label: (
									<div className="icon-reset" onClick={() => undoFormAction()}>
										Undo completion
									</div>
								),
							},
						],
					}}
					trigger={['click']}
				>
					<MoreOutlined style={{ fontSize: '1.5rem' }} />
				</Dropdown>
			)}
		</div>
	)
}
