import React, { useCallback, useState } from 'react'
import { Modal } from 'antd'

import { SpinnerButton } from '@/components/common/button'
import { Input } from '@/components/common/inputs'
import { doPublicFormSubmit, showErrorsAndFlush } from '@/state/answer/thunks'
import { useAppDispatch, useAppSelector } from '@/utils/hooks'
import { useFormContext } from '@/state/main-form/reducer'
import buttonStyle from '@sass/common/button.module.sass'


export const SubmitButtonDeprecated = () => {
	const dispatch = useAppDispatch()
	const formContext = useFormContext()
	const loading = useAppSelector(state => state.answers.submitting)
	const [showSubmit, setShowSubmit] = useState(false)

	const onStart = useCallback(async () => {
		const valid = await dispatch(showErrorsAndFlush({}))
		if (valid) {
			setShowSubmit(true)
		}
	}, [dispatch])
	const [email, setEmail] = useState("")
	const onConfirm = () => dispatch(doPublicFormSubmit({submitterEmail: email}))
	
	return (
		<div>
			<button
				className={buttonStyle.primarySolid}
				onClick={onStart}>
				{formContext.form.publicLinkSubmitButtonText || 'Submit'}
			</button>
			
			<Modal title="Submit Form" open={showSubmit} width={650} footer={null} onCancel={() => setShowSubmit(false)}>
				<p> After submitting, you won't be able to make any changes.</p>
				{formContext.publicForm?.requestEmailOnSubmit &&
					<>
						<p> If you would like to receive a copy via email, please enter your email below.</p>
						<Input
							onChange={(e) => setEmail(e.target.value)}
							value={email}
							type="email"
							placeholder="Email"
							autoFocus
						/>
					</>
				}
				<div style={{marginTop: 10, width: '100%', display: 'flex', justifyContent: 'flex-end'}}>
					<SpinnerButton loading={loading} onClick={onConfirm}>Submit</SpinnerButton>
				</div>
			</Modal>
		</div>
	)
}