import React from 'react'
import gql from "graphql-tag"
import { recordEditUrl, recordCopyUrl, recordDashboardUrl, teamMetricsUrl } from '@/utils/url'
import SimpleIconButton from '@/components/common/simple-icon-button'
import { useGlobalContext, useRouter } from '@/components/context'
import { showNotification } from '@/state/user-interface'
import { useAppDispatch } from '@/utils/hooks'
import iconStyle from '@sass/common/icon.module.sass'
import style from './action-buttons.module.sass'
import { useSetArchivedMutation } from './__gen/action-buttons'
import { FormsForRecordQuery } from './__gen'


gql`
    mutation setArchived(
        $id: ID!,
        $archived: Boolean!,
        $archivedBy: ID
    ) {
        record_Update(input: {
            id: $id,
            archived: $archived,
            archivedBy: $archivedBy
        }) {
            object {
                id
                archived
            }
        }
    }
`

type Props = {
    record: NonNullable<FormsForRecordQuery["record"]>
}

export default ({ record }: Props) => {
    const globalContext = useGlobalContext()
    const router = useRouter()
    const dispatch = useAppDispatch()
    const [setArchivedMutation, {loading}] = useSetArchivedMutation()
    const setArchived = () => {
        const archived = !record.archived
        setArchivedMutation({variables: {
            id: record.id,
            archived,
            archivedBy: archived ? globalContext.user.id : null,
        }}).then(() => {
            dispatch(showNotification({
                content: `${globalContext.currentModule.recordWord} ${archived ? 'archived' : 'restored'}`,
            }))
            // need to redirect here as form wont refresh record
            router.history.push(recordDashboardUrl({...globalContext, recordId: record.id}))
        })
        if (archived) {
            router.history.push(teamMetricsUrl(globalContext))
        }
    }
    return (
        <div className={style.container}>
            { !record.archived && 
                <SimpleIconButton
                    to={recordEditUrl({recordId: record.id, ...globalContext})}
                    iconClass={iconStyle.pencil}
                    title="Edit"
                />
            }

            <SimpleIconButton
                to={recordCopyUrl({recordId: record.id, ...globalContext})}
                iconClass={iconStyle.copy}
                title="Copy"
            />

            <SimpleIconButton
                onClick={setArchived}
                iconClass={iconStyle.archive}
                title={record.archived ? 'Reactivate' : 'Archive'}
            />
        </div>
    )
}
