import React, { useState } from 'react'
import {
	selectFieldForAnswer,
	selectInvalidAnswers,
	selectMissingMandatoryFields,
} from '@/state/answer/selectors'
import { useAppSelector } from '@/utils/hooks'
import sideBarStyle from '../../common.module.sass'
import style from './index.module.sass'
import { InvalidAnswer } from './invalid-answer'
import { MissingAnswer } from './missing-answer'

export const AnswerErrorDisplay = () => {
	const invalidAnswers = useAppSelector((s) =>
		selectInvalidAnswers(s).map((a) => ({ answer: a, field: selectFieldForAnswer(s, a) })),
	)
	const missingFields = useAppSelector((s) => selectMissingMandatoryFields(s))

	const [cachedMissingFields, _] = useState(missingFields)
	const [cachedInvalidAnswers, __] = useState(invalidAnswers)

	return (
		<div className={style.container}>
			<div className={sideBarStyle.sectionTitle}>Errors</div>
			{cachedMissingFields.length > 0 && <div className={style.subtitle}>Fill out these fields to continue</div>}
			{cachedMissingFields.map((el) => (
				<MissingAnswer key={el.id} field={el} />
			))}
			{cachedInvalidAnswers.length > 0 && <div className={style.subtitle}>Fix these invalid fields to continue</div>}
			{cachedInvalidAnswers.map((el) => (
				<InvalidAnswer key={el.answer.id} answerId={el.answer.id} field={el.field} />
			))}
		</div>
	)
}
