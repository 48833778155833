import { configureStore, createListenerMiddleware, UnknownAction } from '@reduxjs/toolkit'
import logger, { createLogger } from 'redux-logger'
import { AnyAction, Middleware} from 'redux'

import analytics from './analytics/reducers'
import answers from './answer/reducer'
import file from './file/reducers'
import general from './general/reducers'
import mainForm from './main-form/reducer'
import record from './record/reducers'
import userInterface, { showNotification } from './user-interface'
import modal from './modal/reducers'
import { isRejected } from '@reduxjs/toolkit';
import { saveAnswersThunk, saveFulfilledListener } from './answer/thunks'

const listenerMiddleware = createListenerMiddleware()

const loggerMiddleware = createLogger({
	collapsed: true,
	duration: false,
	timestamp: false,
})

export const store = configureStore({
	middleware: getDefaultMiddleware => (
		getDefaultMiddleware({serializableCheck: false})
		.concat(loggerMiddleware)
		.concat(listenerMiddleware.middleware)
	),
	reducer: {
		analytics,
		answers,
		file,
		general,
		mainForm,
		modal,
		record,
		userInterface,
	},
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

listenerMiddleware.startListening({
	matcher: isRejected,
	effect: async (action, listenerApi) => {
		console.error(`An unknown error occurred: ${action.type}`, action)
		const dispatch = listenerApi.dispatch as AppDispatch
		dispatch(showNotification({content: `Oops, an unknown error occurred`, type: 'error'}))
	},
 })

listenerMiddleware.startListening({
	matcher: (action) => saveAnswersThunk.fulfilled.match(action),
	effect: async (action, listenerApi) => {
		await listenerApi.condition(saveAnswersThunk.fulfilled.match, 100)
		const state = listenerApi.getState() as RootState
		const dispatch = listenerApi.dispatch as AppDispatch
		saveFulfilledListener(state, dispatch)
	}
 })
