import React, { useEffect, useRef } from 'react'
import { Button, notification } from 'antd'
import { ArgsProps } from 'antd/es/notification'
import gql from 'graphql-tag'
import { useApiVersionDetailsQuery } from './__gen'

gql`
	query apiVersionDetails {
		apiVersionDetails {
			id
		}
	}
`

const NOTIFICATION: ArgsProps = {
	message: <span style={{ fontWeight: 'bold' }}>Update Available</span>,
	closable: false,
	description: (
		<div>
			<p>A new version of this page is available. Please reload to avoid any issues.</p>
			<Button type="primary" onClick={() => window.location.reload()}>
				Refresh Page
			</Button>
		</div>
	),
	placement: 'bottomRight',
	duration: 0, // Persistent
}

export const useVersionChecker = () => {
	const { data, loading, error } = useApiVersionDetailsQuery({ pollInterval: 60000 })
	const prevId = useRef<string | null>(null)

	useEffect(() => {
		const id = data?.apiVersionDetails?.id
		if (!loading && id) {
			if (prevId.current && prevId.current !== id) {
				notification.open(NOTIFICATION)
			}
			prevId.current = id
		}
	}, [data, loading])

	if (error) {
		console.error('Error fetching version info', error)
		return null
	}
}
