import React from 'react'
import { IconTextInput } from '@/components/common/inputs'
import { useAppDispatch } from '@/utils/hooks'
import { updateAnswersThunk } from '@/state/answer/thunks'
import iconStyle from '@sass/common/icon.module.sass'
import { AnswerNumberBaseProps } from '../types'
import commonStyle from '../common.module.sass'
import style from './index.module.sass'

const REGEX = /\d{0,12}(\.\d{0,2})?/

const AnswerDecimal = (props: AnswerNumberBaseProps) => {
	const dispatch = useAppDispatch()
	const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		let value = event.target.value

		const regexResult = REGEX.exec(value)
		
		if(regexResult) {
			const regexValue = regexResult[0]
			const validValue = regexValue === '' ? null : regexValue
			
			dispatch(updateAnswersThunk({answers: [{ ...props.answer, content: validValue }]}))
		}
	}

	return (
		<div className={style.answerDecimal}>
			
			{props.disabled ?
					<div>
						<span className={`${style.iconDollar} ${iconStyle.dollar}`} />
						{props.answer.content || <i className={commonStyle.noContent}>No content</i>}
					</div>
				:
					<IconTextInput 
						iconClass={iconStyle.dollar}
						placeholder={props.field.placeholder || undefined}
						onChange={onChange}
						value={props.answer.content || ""}
					/>
			}
		</div>
	)
}

export default AnswerDecimal