import React from 'react'
import { LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import { useGlobalContext } from '@/components/context'
import { formActionMetricsUrl } from '@/utils/url'
import { MONTHS } from '../common/const'
import style from './index.module.sass'

const STEP = 3

type Props = { startYear: number; startMonth: number }
export const ViewModeTitle = ({ startYear, startMonth }: Props) => {
	const globalContext = useGlobalContext()

	return (
		<div className={style.titleRow}>
			<Link
				to={formActionMetricsUrl({
					...globalContext,
					startYear: (startMonth <= STEP ? startYear - 1 : startYear).toString(),
					startMonth: (startMonth <= STEP ? 12 - STEP + 1 : startMonth - STEP).toString(),
				})}
			>
				<LeftCircleOutlined className={style.switchYear} />
			</Link>
			<div className={style.title}>
				{MONTHS[startMonth - 1].title} {startYear} - {MONTHS[(startMonth - 2 + 12) % 12].title}{' '}
				{startMonth == 1 ? startYear : startYear + 1}
			</div>
			<Link
				to={formActionMetricsUrl({
					...globalContext,
					startYear: (startMonth >= 12 - STEP ? startYear + 1 : startYear).toString(),
					startMonth: (startMonth >= 12 - STEP ? 1 : startMonth + STEP).toString(),
				})}
			>
				<RightCircleOutlined className={style.switchYear} />
			</Link>
		</div>
	)
}
