import React, { Component } from 'react'
import cx from 'classnames'
import { hasExtension } from '@/utils/functions'
import * as Yup from 'yup'
import { FieldProps } from 'formik'
import Dropzone from 'react-dropzone'
import Select, { Props as ReactSelectProps } from 'react-select'
import generalStyle from '@sass/common/general.module.sass'
import iconStyle from '@sass/common/icon.module.sass'
import formStyle from '@sass/common/form.module.sass'
import { Input, TextArea } from './inputs'
import style from './formik.module.sass'

type TextFieldProps = {
	title: string
	textarea: boolean
	extraProps: React.HTMLProps<HTMLTextAreaElement>
	value: string | null
	className?: string
}

export const TextField: React.FC<FieldProps<any> & TextFieldProps> = (props) => {
	const { field, form, title, textarea, ...extraProps  } = props
	return (
		<div className={cx(formStyle.commonFormInput, props.className)}>
			<label>{title}</label>
			<div className={generalStyle.error}>
				{form.touched[field.name] &&  form.errors[field.name]?.toString()}
			</div>
			{textarea
				? <TextArea
					{...extraProps}
					{...field}
					value={field.value || ""}
				/>
				: <Input
					{...extraProps}
					{...field}
					value={field.value || ""}
				/>
			}
		</div>
	)
}


type SelectFieldProps = {
	title: string
	onChange: Function
	props: ReactSelectProps
	className?: string
}

// React select is so dumb...
// https://github.com/JedWatson/react-select/issues/2674#issuecomment-466046110
export const SelectField: React.FC<FieldProps<any> & SelectFieldProps> = ({ field, form, title, onChange, className, ...props}) => (
	<div className={cx(style.commonSelect, className)}>
		<label>{title}</label>
		{form.touched[field.name] &&  form.errors[field.name] &&
			<div className={generalStyle.error}>{form.errors[field.name]?.toString()}</div>
		}
		<Select
			{...props}
			isClearable={false}
			value={field.value}
			styles={{
				control: (styles) => ({ ...styles, minHeight: 44, fontSize: '1.0rem', cursor: 'pointer' }),
				option: (styles) => ({...styles, cursor: 'pointer' }),
			}}
			onChange={(e) => {
				if (onChange) {
					onChange(e)
				}
				return form.setFieldValue(field.name, e)}
			}
		/>
	</div>
)

type ImageFieldProps = {
	title: string
	cancelIcon?: string
}

export class ImageField extends Component<FieldProps<any> & ImageFieldProps> {
	state = {
		cancelled: false
	}

	handleChange = (files: FileList) => {
		const file = files[0]
		this.props.form.setFieldTouched(this.props.field.name, true)
		this.props.form.setFieldValue(this.props.field.name, file)
		this.setState({
			...this.state,
			cancelled: false
		})
	}

	static validator = Yup.mixed().test(
		'is under 4 mb',
		'Please upload a file under 4mb',
		value => {
			const isFile = value instanceof File
			return(!isFile) || value.size/1024/1024 < 4
		}
	).test(
		'is an image',
		'Please upload a valid image (png, jpg, jpeg)',
		value => {
			const isFile = value instanceof File
			return (!isFile) || hasExtension(value.name, ['png', 'jpg', 'jpeg'])
		}
	)

	reset = () => {
		this.props.form.setFieldTouched(this.props.field.name, true)
		this.props.form.setFieldValue(
			this.props.field.name,
			'__clear'
		)
		this.setState({
			...this.state,
			cancelled: true
		})
	}

	renderDropzone = () => (
		<div className={style.dropzoneContainer}>
			<Dropzone
				//@ts-ignore
				onDrop={this.handleChange}
				multiple={false}
				className={style.dropzone}
			>
				<div className={style.dropzoneInner}>
					<div className={style.dropzoneText}>Add {this.props.title}</div>
					<div className={`${style.icon} ${iconStyle.picture}`}/>
				</div>
			</Dropzone>
		</div>
	)

	renderImageContainer = (image: string) => (
		<div className={style.imageContainer}>
			<div
				className={style.image}
				style={{backgroundImage: `url(${image})`}}
			/>
			<div
				className={`${style.cancelIcon} ${this.props.cancelIcon || iconStyle.cross}`}
				onClick={this.reset}
			/>
		</div>
	)

	render() {
		const { field, form, title } = this.props
		const initialValue = form.initialValues[field.name]
		const hasValue = (initialValue || field.value instanceof File) && !this.state.cancelled
		return (
			<div className="common-radio">
				{form.touched[field.name] &&  form.errors[field.name] &&
					<div className={generalStyle.error}>{form.errors[field.name]?.toString()}</div>
				}
				<div className={style.dropzoneContainer}>
					{ hasValue
						? this.renderImageContainer(field.value.preview || initialValue)
						: this.renderDropzone()
					}
				</div>
			</div>
		)
	}
}