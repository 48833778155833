import React from 'react'
import style from './index.module.sass'

export const SelectModeTitle = () => {
	return (
		<div className={style.titleRow}>
			<div className={style.title}>Select Actions to Edit</div>
		</div>
	)
}
