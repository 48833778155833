import React from 'react'
import gql from "graphql-tag"
import c from 'classnames'

import { showNotification } from '@/state/user-interface'
import { showModal } from '@/state/modal/actions'
import { useAppDispatch } from '@/utils/hooks'
import buttonStyle from '@sass/common/button.module.sass'
import iconStyle from '@sass/common/icon.module.sass'
import { useAdminResetMfaMutation } from './__gen/reset-mfa-button'
import { UserToManageQuery } from './__gen/edit'

type UserType = NonNullable<UserToManageQuery["user"]>

gql`
	mutation adminResetMfa(
		$userId: ID!,
	) {
		webauthnCredentials_AdminResetForUser(resetUserId: $userId) {
			success
		}
	}
`

type Props = {
	user: UserType
	refetchUser: () => void
}

export const ResetMfaButton = ({ user, refetchUser }: Props) => {
	const [adminResetMfaMutation, { }] = useAdminResetMfaMutation({variables: {userId: user.id}})
	const dispatch = useAppDispatch()

	const adminResetMfa = async () => {
		const r = await adminResetMfaMutation()
		if (r.errors) {
			dispatch(showNotification({ content: `Error`, type: 'error' }))
		} else {
			refetchUser()
			dispatch(showNotification({ content: `Mfa devices removed for ${user.firstName}` }))
		}
	}

	const enabled = user.webauthnCredentials.length > 0
	if (!enabled) {
		return null
	}

	return (
		<button
			type="button"
			className={c(buttonStyle.small, buttonStyle.grey, iconStyle.bin)}
			style={{marginRight: 15}}
			onClick={async () => {
				dispatch(showModal({
					title: `Confirm reset MFA for ${user.firstName}`,
					content: <div>
						The use will no longer have an MFA requirement when they log in. After resetting, {user.firstName} will be able to set up MFA again.
					</div>,
					confirmClass: buttonStyle.red,
					confirmText: 'Reset MFA',
					confirmAction: async () => await adminResetMfa()
				}))
			}}
		>
			Reset MFA
		</button>
	)
}