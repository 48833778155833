export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: any; output: any; }
  Decimal: { input: any; output: any; }
  DjangoFileType: { input: any; output: any; }
  PharosDatetime: { input: any; output: any; }
  Upload: { input: any; output: any; }
};

export type ActivityEventType = Node & {
  __typename?: 'ActivityEventType';
  created: Scalars['String']['output'];
  form?: Maybe<FormType>;
  /** The ID of the object */
  id: Scalars['ID']['output'];
  record: RecordType;
  type: ActivityEventTypeType;
  user?: Maybe<UserType>;
};

export type ActivityEventTypeListBase = {
  __typename?: 'ActivityEventTypeListBase';
  objects: Array<ActivityEventType>;
  pageInfo: PageInfo;
};

/** An enumeration. */
export enum ActivityEventTypeOrderingFilter {
  Created = 'created',
  Id = 'id'
}

export type ActivityEventTypeOrderingFilterObject = {
  direction?: InputMaybe<OrderingDirectionEnum>;
  field?: InputMaybe<ActivityEventTypeOrderingFilter>;
  modifiers?: InputMaybe<Array<InputMaybe<OrderingModifierEnum>>>;
};

/** An enumeration. */
export enum ActivityEventTypeType {
  /** add_record */
  A = 'A',
  /** record_deactivated */
  D = 'D',
  /** public_form_submit */
  F = 'F',
  /** form_reset */
  Fr = 'FR',
  /** create_pdf */
  P = 'P',
  /** record_reactivated */
  R = 'R',
  /** form_save */
  S = 'S',
  /** record_update */
  U = 'U'
}

export type AddressGeolocationType = {
  __typename?: 'AddressGeolocationType';
  id: Scalars['ID']['output'];
  lat: Scalars['Decimal']['output'];
  lng: Scalars['Decimal']['output'];
};

export type AdminOrgSwitchHistoryType = Node & {
  __typename?: 'AdminOrgSwitchHistoryType';
  /** The ID of the object */
  id: Scalars['ID']['output'];
  memberId?: Maybe<Scalars['ID']['output']>;
  organisation: AdminOrganisationType;
};


export type AdminOrgSwitchHistoryTypeMemberIdArgs = {
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type AdminOrgSwitchHistoryTypeListBase = {
  __typename?: 'AdminOrgSwitchHistoryTypeListBase';
  objects: Array<AdminOrgSwitchHistoryType>;
  pageInfo: PageInfo;
};

/** An enumeration. */
export enum AdminOrgSwitchHistoryTypeOrderingFilter {
  Id = 'id',
  LastSwitch = 'lastSwitch'
}

export type AdminOrgSwitchHistoryTypeOrderingFilterObject = {
  direction?: InputMaybe<OrderingDirectionEnum>;
  field?: InputMaybe<AdminOrgSwitchHistoryTypeOrderingFilter>;
  modifiers?: InputMaybe<Array<InputMaybe<OrderingModifierEnum>>>;
};

export type AdminOrganisationType = Node & {
  __typename?: 'AdminOrganisationType';
  /** The ID of the object */
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  recordCount: Scalars['Int']['output'];
  userCount: Scalars['Int']['output'];
};

export type AdminOrganisationTypeListBase = {
  __typename?: 'AdminOrganisationTypeListBase';
  objects: Array<AdminOrganisationType>;
  pageInfo: PageInfo;
};

/** An enumeration. */
export enum AdminOrganisationTypeOrderingFilter {
  Id = 'id'
}

export type AdminOrganisationTypeOrderingFilterObject = {
  direction?: InputMaybe<OrderingDirectionEnum>;
  field?: InputMaybe<AdminOrganisationTypeOrderingFilter>;
  modifiers?: InputMaybe<Array<InputMaybe<OrderingModifierEnum>>>;
};

export type AdminTeamType = Node & {
  __typename?: 'AdminTeamType';
  /** The ID of the object */
  id: Scalars['ID']['output'];
  memberId?: Maybe<Scalars['ID']['output']>;
  name: Scalars['String']['output'];
  organisation: AdminOrganisationType;
};


export type AdminTeamTypeMemberIdArgs = {
  userId?: InputMaybe<Scalars['ID']['input']>;
};

export type AdminTeamTypeListBase = {
  __typename?: 'AdminTeamTypeListBase';
  objects: Array<AdminTeamType>;
  pageInfo: PageInfo;
};

/** An enumeration. */
export enum AdminTeamTypeOrderingFilter {
  Id = 'id'
}

export type AdminTeamTypeOrderingFilterObject = {
  direction?: InputMaybe<OrderingDirectionEnum>;
  field?: InputMaybe<AdminTeamTypeOrderingFilter>;
  modifiers?: InputMaybe<Array<InputMaybe<OrderingModifierEnum>>>;
};

export type AdviceCategoryType = Node & {
  __typename?: 'AdviceCategoryType';
  /** The ID of the object */
  id: Scalars['ID']['output'];
  rank: Scalars['Int']['output'];
  subcategories: Array<AdviceSubcategoryType>;
  title: Scalars['String']['output'];
};


export type AdviceCategoryTypeSubcategoriesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<AdviceSubcategoryType_AdviceCategory_Subcategories_OrderingFilterObject>>>;
  rank?: InputMaybe<Scalars['Int']['input']>;
};

/** An enumeration. */
export enum AdviceCategoryType_AdviceSection_Categories_OrderingFilter {
  Id = 'id',
  Rank = 'rank'
}

export type AdviceCategoryType_AdviceSection_Categories_OrderingFilterObject = {
  direction?: InputMaybe<OrderingDirectionEnum>;
  field?: InputMaybe<AdviceCategoryType_AdviceSection_Categories_OrderingFilter>;
  modifiers?: InputMaybe<Array<InputMaybe<OrderingModifierEnum>>>;
};

export type AdviceSectionType = Node & {
  __typename?: 'AdviceSectionType';
  categories: Array<AdviceCategoryType>;
  /** The ID of the object */
  id: Scalars['ID']['output'];
  title: Scalars['String']['output'];
};


export type AdviceSectionTypeCategoriesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<AdviceCategoryType_AdviceSection_Categories_OrderingFilterObject>>>;
  rank?: InputMaybe<Scalars['Int']['input']>;
};

export type AdviceSectionTypeListBase = {
  __typename?: 'AdviceSectionTypeListBase';
  objects: Array<AdviceSectionType>;
  pageInfo: PageInfo;
};

/** An enumeration. */
export enum AdviceSectionTypeOrderingFilter {
  Id = 'id'
}

export type AdviceSectionTypeOrderingFilterObject = {
  direction?: InputMaybe<OrderingDirectionEnum>;
  field?: InputMaybe<AdviceSectionTypeOrderingFilter>;
  modifiers?: InputMaybe<Array<InputMaybe<OrderingModifierEnum>>>;
};

export type AdviceSubcategoryType = Node & {
  __typename?: 'AdviceSubcategoryType';
  content: Scalars['String']['output'];
  /** The ID of the object */
  id: Scalars['ID']['output'];
  rank: Scalars['Int']['output'];
  title: Scalars['String']['output'];
};

/** An enumeration. */
export enum AdviceSubcategoryType_AdviceCategory_Subcategories_OrderingFilter {
  Id = 'id',
  Rank = 'rank'
}

export type AdviceSubcategoryType_AdviceCategory_Subcategories_OrderingFilterObject = {
  direction?: InputMaybe<OrderingDirectionEnum>;
  field?: InputMaybe<AdviceSubcategoryType_AdviceCategory_Subcategories_OrderingFilter>;
  modifiers?: InputMaybe<Array<InputMaybe<OrderingModifierEnum>>>;
};

/** An enumeration. */
export enum AnalyticsUpcomingNotificationsMetricTypeChoices {
  /** Past */
  Past = 'PAST',
  /** Upcoming */
  Upcoming = 'UPCOMING'
}

export type AnswerBooleanBulkObjectInput = {
  content?: InputMaybe<Scalars['Boolean']['input']>;
  contentType?: InputMaybe<Scalars['String']['input']>;
  deactivated?: InputMaybe<Scalars['DateTime']['input']>;
  fieldId?: InputMaybe<Scalars['ID']['input']>;
  formSave?: InputMaybe<Scalars['ID']['input']>;
  formSaveId?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  objectId?: InputMaybe<Scalars['ID']['input']>;
  rank?: InputMaybe<Scalars['Int']['input']>;
  replacingId?: InputMaybe<Scalars['ID']['input']>;
  tableFieldId?: InputMaybe<Scalars['ID']['input']>;
};

export type AnswerBooleanPubBulkObjectInput = {
  content?: InputMaybe<Scalars['Boolean']['input']>;
  contentType?: InputMaybe<Scalars['String']['input']>;
  deactivated?: InputMaybe<Scalars['DateTime']['input']>;
  fieldId?: InputMaybe<Scalars['ID']['input']>;
  formSave?: InputMaybe<Scalars['ID']['input']>;
  formSaveId?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  objectId?: InputMaybe<Scalars['ID']['input']>;
  rank?: InputMaybe<Scalars['Int']['input']>;
  replacingId?: InputMaybe<Scalars['ID']['input']>;
  tableFieldId?: InputMaybe<Scalars['ID']['input']>;
};

export type AnswerBooleanPubType = Node & {
  __typename?: 'AnswerBooleanPubType';
  content: Scalars['Boolean']['output'];
  contentObjectName?: Maybe<Scalars['String']['output']>;
  contentType: AnswerContentTypeEnum;
  deactivated?: Maybe<Scalars['String']['output']>;
  field: FieldType;
  fieldId: Scalars['String']['output'];
  formSave?: Maybe<FormSaveType>;
  /** The ID of the object */
  id: Scalars['ID']['output'];
  link?: Maybe<MetricFormLink>;
  model: AnswerModel;
  modified: Scalars['String']['output'];
  objectId: Scalars['ID']['output'];
  rank: Scalars['Int']['output'];
  tableField?: Maybe<TableFieldType>;
  tableFieldId?: Maybe<Scalars['String']['output']>;
};

export type AnswerBooleanPubTypeListBase = {
  __typename?: 'AnswerBooleanPubTypeListBase';
  objects: Array<AnswerBooleanPubType>;
  pageInfo: PageInfo;
};

/** An enumeration. */
export enum AnswerBooleanPubTypeOrderingFilter {
  Content = 'content',
  Id = 'id'
}

export type AnswerBooleanPubTypeOrderingFilterObject = {
  direction?: InputMaybe<OrderingDirectionEnum>;
  field?: InputMaybe<AnswerBooleanPubTypeOrderingFilter>;
  modifiers?: InputMaybe<Array<InputMaybe<OrderingModifierEnum>>>;
};

export type AnswerBooleanType = Node & {
  __typename?: 'AnswerBooleanType';
  content: Scalars['Boolean']['output'];
  contentObjectName?: Maybe<Scalars['String']['output']>;
  contentType: AnswerContentTypeEnum;
  deactivated?: Maybe<Scalars['String']['output']>;
  field: FieldType;
  fieldId: Scalars['String']['output'];
  formSave?: Maybe<FormSaveType>;
  /** The ID of the object */
  id: Scalars['ID']['output'];
  link?: Maybe<MetricFormLink>;
  model: AnswerModel;
  modified: Scalars['String']['output'];
  objectId: Scalars['ID']['output'];
  rank: Scalars['Int']['output'];
  tableField?: Maybe<TableFieldType>;
  tableFieldId?: Maybe<Scalars['String']['output']>;
};

export type AnswerBooleanTypeListBase = {
  __typename?: 'AnswerBooleanTypeListBase';
  objects: Array<AnswerBooleanType>;
  pageInfo: PageInfo;
};

/** An enumeration. */
export enum AnswerBooleanTypeOrderingFilter {
  Content = 'content',
  Id = 'id'
}

export type AnswerBooleanTypeOrderingFilterObject = {
  direction?: InputMaybe<OrderingDirectionEnum>;
  field?: InputMaybe<AnswerBooleanTypeOrderingFilter>;
  modifiers?: InputMaybe<Array<InputMaybe<OrderingModifierEnum>>>;
};

/** An enumeration. */
export enum AnswerContentTypeEnum {
  Organisation = 'organisation',
  Record = 'record',
  Team = 'team'
}

export type AnswerDateBulkObjectInput = {
  content?: InputMaybe<Scalars['DateTime']['input']>;
  contentType?: InputMaybe<Scalars['String']['input']>;
  deactivated?: InputMaybe<Scalars['DateTime']['input']>;
  fieldId?: InputMaybe<Scalars['ID']['input']>;
  formSave?: InputMaybe<Scalars['ID']['input']>;
  formSaveId?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  objectId?: InputMaybe<Scalars['ID']['input']>;
  rank?: InputMaybe<Scalars['Int']['input']>;
  replacingId?: InputMaybe<Scalars['ID']['input']>;
  tableFieldId?: InputMaybe<Scalars['ID']['input']>;
};

export type AnswerDatePubBulkObjectInput = {
  content?: InputMaybe<Scalars['DateTime']['input']>;
  contentType?: InputMaybe<Scalars['String']['input']>;
  deactivated?: InputMaybe<Scalars['DateTime']['input']>;
  fieldId?: InputMaybe<Scalars['ID']['input']>;
  formSave?: InputMaybe<Scalars['ID']['input']>;
  formSaveId?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  objectId?: InputMaybe<Scalars['ID']['input']>;
  rank?: InputMaybe<Scalars['Int']['input']>;
  replacingId?: InputMaybe<Scalars['ID']['input']>;
  tableFieldId?: InputMaybe<Scalars['ID']['input']>;
};

export type AnswerDatePubType = Node & {
  __typename?: 'AnswerDatePubType';
  content?: Maybe<Scalars['PharosDatetime']['output']>;
  contentObjectName?: Maybe<Scalars['String']['output']>;
  contentType: AnswerContentTypeEnum;
  deactivated?: Maybe<Scalars['String']['output']>;
  field: FieldType;
  fieldId: Scalars['String']['output'];
  formSave?: Maybe<FormSaveType>;
  /** The ID of the object */
  id: Scalars['ID']['output'];
  link?: Maybe<MetricFormLink>;
  model: AnswerModel;
  modified: Scalars['String']['output'];
  objectId: Scalars['ID']['output'];
  rank: Scalars['Int']['output'];
  tableField?: Maybe<TableFieldType>;
  tableFieldId?: Maybe<Scalars['String']['output']>;
};

export type AnswerDatePubTypeListBase = {
  __typename?: 'AnswerDatePubTypeListBase';
  objects: Array<AnswerDatePubType>;
  pageInfo: PageInfo;
};

/** An enumeration. */
export enum AnswerDatePubTypeOrderingFilter {
  Content = 'content',
  FieldTitle = 'field_Title',
  Id = 'id'
}

export type AnswerDatePubTypeOrderingFilterObject = {
  direction?: InputMaybe<OrderingDirectionEnum>;
  field?: InputMaybe<AnswerDatePubTypeOrderingFilter>;
  modifiers?: InputMaybe<Array<InputMaybe<OrderingModifierEnum>>>;
};

export type AnswerDateType = Node & {
  __typename?: 'AnswerDateType';
  content?: Maybe<Scalars['PharosDatetime']['output']>;
  contentObjectName?: Maybe<Scalars['String']['output']>;
  contentType: AnswerContentTypeEnum;
  deactivated?: Maybe<Scalars['String']['output']>;
  field: FieldType;
  fieldId: Scalars['String']['output'];
  formSave?: Maybe<FormSaveType>;
  /** The ID of the object */
  id: Scalars['ID']['output'];
  link?: Maybe<MetricFormLink>;
  model: AnswerModel;
  modified: Scalars['String']['output'];
  objectId: Scalars['ID']['output'];
  rank: Scalars['Int']['output'];
  tableField?: Maybe<TableFieldType>;
  tableFieldId?: Maybe<Scalars['String']['output']>;
};

export type AnswerDateTypeListBase = {
  __typename?: 'AnswerDateTypeListBase';
  objects: Array<AnswerDateType>;
  pageInfo: PageInfo;
};

/** An enumeration. */
export enum AnswerDateTypeOrderingFilter {
  Content = 'content',
  FieldTitle = 'field_Title',
  Id = 'id'
}

export type AnswerDateTypeOrderingFilterObject = {
  direction?: InputMaybe<OrderingDirectionEnum>;
  field?: InputMaybe<AnswerDateTypeOrderingFilter>;
  modifiers?: InputMaybe<Array<InputMaybe<OrderingModifierEnum>>>;
};

export type AnswerDecimalBulkObjectInput = {
  content?: InputMaybe<Scalars['Decimal']['input']>;
  contentType?: InputMaybe<Scalars['String']['input']>;
  deactivated?: InputMaybe<Scalars['DateTime']['input']>;
  fieldId?: InputMaybe<Scalars['ID']['input']>;
  formSave?: InputMaybe<Scalars['ID']['input']>;
  formSaveId?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  objectId?: InputMaybe<Scalars['ID']['input']>;
  rank?: InputMaybe<Scalars['Int']['input']>;
  replacingId?: InputMaybe<Scalars['ID']['input']>;
  tableFieldId?: InputMaybe<Scalars['ID']['input']>;
};

export type AnswerDecimalPubBulkObjectInput = {
  content?: InputMaybe<Scalars['Decimal']['input']>;
  contentType?: InputMaybe<Scalars['String']['input']>;
  deactivated?: InputMaybe<Scalars['DateTime']['input']>;
  fieldId?: InputMaybe<Scalars['ID']['input']>;
  formSave?: InputMaybe<Scalars['ID']['input']>;
  formSaveId?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  objectId?: InputMaybe<Scalars['ID']['input']>;
  rank?: InputMaybe<Scalars['Int']['input']>;
  replacingId?: InputMaybe<Scalars['ID']['input']>;
  tableFieldId?: InputMaybe<Scalars['ID']['input']>;
};

export type AnswerDecimalPubType = Node & {
  __typename?: 'AnswerDecimalPubType';
  content?: Maybe<Scalars['String']['output']>;
  contentObjectName?: Maybe<Scalars['String']['output']>;
  contentType: AnswerContentTypeEnum;
  deactivated?: Maybe<Scalars['String']['output']>;
  field: FieldType;
  fieldId: Scalars['String']['output'];
  formSave?: Maybe<FormSaveType>;
  /** The ID of the object */
  id: Scalars['ID']['output'];
  link?: Maybe<MetricFormLink>;
  model: AnswerModel;
  modified: Scalars['String']['output'];
  objectId: Scalars['ID']['output'];
  rank: Scalars['Int']['output'];
  tableField?: Maybe<TableFieldType>;
  tableFieldId?: Maybe<Scalars['String']['output']>;
};

export type AnswerDecimalPubTypeListBase = {
  __typename?: 'AnswerDecimalPubTypeListBase';
  objects: Array<AnswerDecimalPubType>;
  pageInfo: PageInfo;
};

/** An enumeration. */
export enum AnswerDecimalPubTypeOrderingFilter {
  Content = 'content',
  Id = 'id'
}

export type AnswerDecimalPubTypeOrderingFilterObject = {
  direction?: InputMaybe<OrderingDirectionEnum>;
  field?: InputMaybe<AnswerDecimalPubTypeOrderingFilter>;
  modifiers?: InputMaybe<Array<InputMaybe<OrderingModifierEnum>>>;
};

export type AnswerDecimalType = Node & {
  __typename?: 'AnswerDecimalType';
  content?: Maybe<Scalars['String']['output']>;
  contentObjectName?: Maybe<Scalars['String']['output']>;
  contentType: AnswerContentTypeEnum;
  deactivated?: Maybe<Scalars['String']['output']>;
  field: FieldType;
  fieldId: Scalars['String']['output'];
  formSave?: Maybe<FormSaveType>;
  /** The ID of the object */
  id: Scalars['ID']['output'];
  link?: Maybe<MetricFormLink>;
  model: AnswerModel;
  modified: Scalars['String']['output'];
  objectId: Scalars['ID']['output'];
  rank: Scalars['Int']['output'];
  tableField?: Maybe<TableFieldType>;
  tableFieldId?: Maybe<Scalars['String']['output']>;
};

export type AnswerDecimalTypeListBase = {
  __typename?: 'AnswerDecimalTypeListBase';
  objects: Array<AnswerDecimalType>;
  pageInfo: PageInfo;
};

/** An enumeration. */
export enum AnswerDecimalTypeOrderingFilter {
  Content = 'content',
  Id = 'id'
}

export type AnswerDecimalTypeOrderingFilterObject = {
  direction?: InputMaybe<OrderingDirectionEnum>;
  field?: InputMaybe<AnswerDecimalTypeOrderingFilter>;
  modifiers?: InputMaybe<Array<InputMaybe<OrderingModifierEnum>>>;
};

export type AnswerFileBulkObjectInput = {
  content?: InputMaybe<Scalars['Upload']['input']>;
  contentType?: InputMaybe<Scalars['String']['input']>;
  deactivated?: InputMaybe<Scalars['DateTime']['input']>;
  fieldId?: InputMaybe<Scalars['ID']['input']>;
  formSave?: InputMaybe<Scalars['ID']['input']>;
  formSaveId?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  objectId?: InputMaybe<Scalars['ID']['input']>;
  rank?: InputMaybe<Scalars['Int']['input']>;
  replacingId?: InputMaybe<Scalars['ID']['input']>;
  tableFieldId?: InputMaybe<Scalars['ID']['input']>;
};

export type AnswerFilePubBulkObjectInput = {
  content?: InputMaybe<Scalars['Upload']['input']>;
  contentType?: InputMaybe<Scalars['String']['input']>;
  deactivated?: InputMaybe<Scalars['DateTime']['input']>;
  fieldId?: InputMaybe<Scalars['ID']['input']>;
  formSave?: InputMaybe<Scalars['ID']['input']>;
  formSaveId?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  objectId?: InputMaybe<Scalars['ID']['input']>;
  rank?: InputMaybe<Scalars['Int']['input']>;
  replacingId?: InputMaybe<Scalars['ID']['input']>;
  tableFieldId?: InputMaybe<Scalars['ID']['input']>;
};

export type AnswerFilePubType = Node & {
  __typename?: 'AnswerFilePubType';
  content?: Maybe<Scalars['DjangoFileType']['output']>;
  contentObjectName?: Maybe<Scalars['String']['output']>;
  contentType: AnswerContentTypeEnum;
  deactivated?: Maybe<Scalars['String']['output']>;
  field: FieldType;
  fieldId: Scalars['String']['output'];
  formSave?: Maybe<FormSaveType>;
  /** The ID of the object */
  id: Scalars['ID']['output'];
  link?: Maybe<MetricFormLink>;
  model: AnswerModel;
  modified: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  objectId: Scalars['ID']['output'];
  rank: Scalars['Int']['output'];
  tableField?: Maybe<TableFieldType>;
  tableFieldId?: Maybe<Scalars['String']['output']>;
};

export type AnswerFilePubTypeListBase = {
  __typename?: 'AnswerFilePubTypeListBase';
  objects: Array<AnswerFilePubType>;
  pageInfo: PageInfo;
};

/** An enumeration. */
export enum AnswerFilePubTypeOrderingFilter {
  Content = 'content',
  Id = 'id'
}

export type AnswerFilePubTypeOrderingFilterObject = {
  direction?: InputMaybe<OrderingDirectionEnum>;
  field?: InputMaybe<AnswerFilePubTypeOrderingFilter>;
  modifiers?: InputMaybe<Array<InputMaybe<OrderingModifierEnum>>>;
};

export type AnswerFileType = Node & {
  __typename?: 'AnswerFileType';
  content?: Maybe<Scalars['DjangoFileType']['output']>;
  contentObjectName?: Maybe<Scalars['String']['output']>;
  contentType: AnswerContentTypeEnum;
  deactivated?: Maybe<Scalars['String']['output']>;
  field: FieldType;
  fieldId: Scalars['String']['output'];
  formSave?: Maybe<FormSaveType>;
  /** The ID of the object */
  id: Scalars['ID']['output'];
  link?: Maybe<MetricFormLink>;
  model: AnswerModel;
  modified: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  objectId: Scalars['ID']['output'];
  rank: Scalars['Int']['output'];
  tableField?: Maybe<TableFieldType>;
  tableFieldId?: Maybe<Scalars['String']['output']>;
};

export type AnswerFileTypeListBase = {
  __typename?: 'AnswerFileTypeListBase';
  objects: Array<AnswerFileType>;
  pageInfo: PageInfo;
};

/** An enumeration. */
export enum AnswerFileTypeOrderingFilter {
  Content = 'content',
  Id = 'id'
}

export type AnswerFileTypeOrderingFilterObject = {
  direction?: InputMaybe<OrderingDirectionEnum>;
  field?: InputMaybe<AnswerFileTypeOrderingFilter>;
  modifiers?: InputMaybe<Array<InputMaybe<OrderingModifierEnum>>>;
};

/** An enumeration. */
export enum AnswerModel {
  Boolean = 'boolean',
  Date = 'date',
  Decimal = 'decimal',
  File = 'file',
  Text = 'text'
}

export type AnswerTextBulkObjectInput = {
  content?: InputMaybe<Scalars['String']['input']>;
  contentType?: InputMaybe<Scalars['String']['input']>;
  deactivated?: InputMaybe<Scalars['DateTime']['input']>;
  fieldId?: InputMaybe<Scalars['ID']['input']>;
  formSave?: InputMaybe<Scalars['ID']['input']>;
  formSaveId?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  lat?: InputMaybe<Scalars['Decimal']['input']>;
  lng?: InputMaybe<Scalars['Decimal']['input']>;
  objectId?: InputMaybe<Scalars['ID']['input']>;
  rank?: InputMaybe<Scalars['Int']['input']>;
  replacingId?: InputMaybe<Scalars['ID']['input']>;
  tableFieldId?: InputMaybe<Scalars['ID']['input']>;
};

export type AnswerTextPubBulkObjectInput = {
  content?: InputMaybe<Scalars['String']['input']>;
  contentType?: InputMaybe<Scalars['String']['input']>;
  deactivated?: InputMaybe<Scalars['DateTime']['input']>;
  fieldId?: InputMaybe<Scalars['ID']['input']>;
  formSave?: InputMaybe<Scalars['ID']['input']>;
  formSaveId?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  lat?: InputMaybe<Scalars['Decimal']['input']>;
  lng?: InputMaybe<Scalars['Decimal']['input']>;
  objectId?: InputMaybe<Scalars['ID']['input']>;
  rank?: InputMaybe<Scalars['Int']['input']>;
  replacingId?: InputMaybe<Scalars['ID']['input']>;
  tableFieldId?: InputMaybe<Scalars['ID']['input']>;
};

export type AnswerTextPubType = Node & {
  __typename?: 'AnswerTextPubType';
  contactName?: Maybe<Scalars['String']['output']>;
  content?: Maybe<Scalars['String']['output']>;
  contentObjectName?: Maybe<Scalars['String']['output']>;
  contentType: AnswerContentTypeEnum;
  deactivated?: Maybe<Scalars['String']['output']>;
  field: FieldType;
  fieldId: Scalars['String']['output'];
  formSave?: Maybe<FormSaveType>;
  /** The ID of the object */
  id: Scalars['ID']['output'];
  link?: Maybe<MetricFormLink>;
  model: AnswerModel;
  modified: Scalars['String']['output'];
  objectId: Scalars['ID']['output'];
  rank: Scalars['Int']['output'];
  tableField?: Maybe<TableFieldType>;
  tableFieldId?: Maybe<Scalars['String']['output']>;
};

export type AnswerTextPubTypeListBase = {
  __typename?: 'AnswerTextPubTypeListBase';
  objects: Array<AnswerTextPubType>;
  pageInfo: PageInfo;
};

/** An enumeration. */
export enum AnswerTextPubTypeOrderingFilter {
  Content = 'content',
  Id = 'id'
}

export type AnswerTextPubTypeOrderingFilterObject = {
  direction?: InputMaybe<OrderingDirectionEnum>;
  field?: InputMaybe<AnswerTextPubTypeOrderingFilter>;
  modifiers?: InputMaybe<Array<InputMaybe<OrderingModifierEnum>>>;
};

export type AnswerTextType = Node & {
  __typename?: 'AnswerTextType';
  addressGeolocation?: Maybe<AddressGeolocationType>;
  contactName?: Maybe<Scalars['String']['output']>;
  content?: Maybe<Scalars['String']['output']>;
  contentObjectName?: Maybe<Scalars['String']['output']>;
  contentType: AnswerContentTypeEnum;
  deactivated?: Maybe<Scalars['String']['output']>;
  field: FieldType;
  fieldId: Scalars['String']['output'];
  formSave?: Maybe<FormSaveType>;
  /** The ID of the object */
  id: Scalars['ID']['output'];
  link?: Maybe<MetricFormLink>;
  model: AnswerModel;
  modified: Scalars['String']['output'];
  objectId: Scalars['ID']['output'];
  rank: Scalars['Int']['output'];
  tableField?: Maybe<TableFieldType>;
  tableFieldId?: Maybe<Scalars['String']['output']>;
};

export type AnswerTextTypeListBase = {
  __typename?: 'AnswerTextTypeListBase';
  objects: Array<AnswerTextType>;
  pageInfo: PageInfo;
};

/** An enumeration. */
export enum AnswerTextTypeOrderingFilter {
  Content = 'content',
  Id = 'id'
}

export type AnswerTextTypeOrderingFilterObject = {
  direction?: InputMaybe<OrderingDirectionEnum>;
  field?: InputMaybe<AnswerTextTypeOrderingFilter>;
  modifiers?: InputMaybe<Array<InputMaybe<OrderingModifierEnum>>>;
};

export type ApiVersionDetails = {
  __typename?: 'ApiVersionDetails';
  id: Scalars['String']['output'];
};

export type CategoryType = Node & {
  __typename?: 'CategoryType';
  collapsible: Scalars['Boolean']['output'];
  /** The ID of the object */
  id: Scalars['ID']['output'];
  rank: Scalars['Int']['output'];
  title?: Maybe<Scalars['String']['output']>;
};

export type ConcatenatedTableDataRowLinkType = {
  __typename?: 'ConcatenatedTableDataRowLinkType';
  fieldId: Scalars['ID']['output'];
  formId: Scalars['ID']['output'];
  recordId: Scalars['ID']['output'];
  teamId: Scalars['ID']['output'];
};

export type ConcatenatedTableDataRowType = {
  __typename?: 'ConcatenatedTableDataRowType';
  jsonData: Scalars['String']['output'];
  link: ConcatenatedTableDataRowLinkType;
};

export type ConcatenatedTableDataType = {
  __typename?: 'ConcatenatedTableDataType';
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  rows: Array<ConcatenatedTableDataRowType>;
};

export type ConcatenatedTableOrdering = {
  columnIndex?: InputMaybe<Scalars['Int']['input']>;
  direction?: InputMaybe<Scalars['String']['input']>;
};

export type ConcatenatedTableType = Node & {
  __typename?: 'ConcatenatedTableType';
  /** The ID of the object */
  id: Scalars['ID']['output'];
  includeFields: Array<IncludeFieldType>;
  includeTableFields: Array<IncludeTableFieldType>;
  name: Scalars['String']['output'];
};


export type ConcatenatedTableTypeIncludeFieldsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<IncludeFieldType_ConcatenatedTable_Include_Fields_OrderingFilterObject>>>;
};


export type ConcatenatedTableTypeIncludeTableFieldsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<IncludeTableFieldType_ConcatenatedTable_Include_Table_Fields_OrderingFilterObject>>>;
};

export type ConcatenatedTableTypeListBase = {
  __typename?: 'ConcatenatedTableTypeListBase';
  objects: Array<ConcatenatedTableType>;
  pageInfo: PageInfo;
};

/** An enumeration. */
export enum ConcatenatedTableTypeOrderingFilter {
  Id = 'id'
}

export type ConcatenatedTableTypeOrderingFilterObject = {
  direction?: InputMaybe<OrderingDirectionEnum>;
  field?: InputMaybe<ConcatenatedTableTypeOrderingFilter>;
  modifiers?: InputMaybe<Array<InputMaybe<OrderingModifierEnum>>>;
};

export type EmailOption = {
  __typename?: 'EmailOption';
  detail?: Maybe<Scalars['String']['output']>;
  email: Scalars['String']['output'];
  name: Scalars['String']['output'];
  objId: Scalars['String']['output'];
  recordWord?: Maybe<Scalars['String']['output']>;
  sourceType: Scalars['String']['output'];
};

export type EmailType = Node & {
  __typename?: 'EmailType';
  created: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  excludeFromAddressBook: Scalars['Boolean']['output'];
  /** The ID of the object */
  id: Scalars['ID']['output'];
  regardingObjectId: Scalars['String']['output'];
  sendToEmail: Scalars['String']['output'];
  sendToObjectId?: Maybe<Scalars['String']['output']>;
  /** A message supplied by the creator that will appear in the email */
  userSuppliedContext: Scalars['String']['output'];
};

export type EmailTypeListBase = {
  __typename?: 'EmailTypeListBase';
  objects: Array<EmailType>;
  pageInfo: PageInfo;
};

/** An enumeration. */
export enum EmailTypeOrderingFilter {
  Id = 'id'
}

export type EmailTypeOrderingFilterObject = {
  direction?: InputMaybe<OrderingDirectionEnum>;
  field?: InputMaybe<EmailTypeOrderingFilter>;
  modifiers?: InputMaybe<Array<InputMaybe<OrderingModifierEnum>>>;
};

export type ErrorType = {
  __typename?: 'ErrorType';
  field: Scalars['String']['output'];
  messages: Array<Scalars['String']['output']>;
};

export type FieldAggregationMetricType = Node & {
  __typename?: 'FieldAggregationMetricType';
  /** The ID of the object */
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  order: Scalars['Int']['output'];
  result: ValueResultType;
};


export type FieldAggregationMetricTypeResultArgs = {
  isSuiteAdminDashboard?: InputMaybe<Scalars['Boolean']['input']>;
  teamId?: InputMaybe<Scalars['ID']['input']>;
};

export type FieldAggregationMetricTypeListBase = {
  __typename?: 'FieldAggregationMetricTypeListBase';
  objects: Array<FieldAggregationMetricType>;
  pageInfo: PageInfo;
};

/** An enumeration. */
export enum FieldAggregationMetricTypeOrderingFilter {
  Id = 'id',
  Order = 'order'
}

export type FieldAggregationMetricTypeOrderingFilterObject = {
  direction?: InputMaybe<OrderingDirectionEnum>;
  field?: InputMaybe<FieldAggregationMetricTypeOrderingFilter>;
  modifiers?: InputMaybe<Array<InputMaybe<OrderingModifierEnum>>>;
};

export type FieldDateRangeMetricType = Node & {
  __typename?: 'FieldDateRangeMetricType';
  /** The ID of the object */
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  order: Scalars['Int']['output'];
  values: Array<FieldDateValueType>;
};


export type FieldDateRangeMetricTypeValuesArgs = {
  isSuiteAdminDashboard?: InputMaybe<Scalars['Boolean']['input']>;
  teamId?: InputMaybe<Scalars['ID']['input']>;
};

export type FieldDateRangeMetricTypeListBase = {
  __typename?: 'FieldDateRangeMetricTypeListBase';
  objects: Array<FieldDateRangeMetricType>;
  pageInfo: PageInfo;
};

/** An enumeration. */
export enum FieldDateRangeMetricTypeOrderingFilter {
  Id = 'id',
  Order = 'order'
}

export type FieldDateRangeMetricTypeOrderingFilterObject = {
  direction?: InputMaybe<OrderingDirectionEnum>;
  field?: InputMaybe<FieldDateRangeMetricTypeOrderingFilter>;
  modifiers?: InputMaybe<Array<InputMaybe<OrderingModifierEnum>>>;
};

export type FieldDateValueType = {
  __typename?: 'FieldDateValueType';
  content: Scalars['String']['output'];
  contentObjectName: Scalars['String']['output'];
  formLink?: Maybe<MetricFormLink>;
};

/** An enumeration. */
export enum FieldFieldTypeChoices {
  /** address */
  Address = 'ADDRESS',
  /** boolean */
  Boolean = 'BOOLEAN',
  /** currency */
  Currency = 'CURRENCY',
  /** date */
  Date = 'DATE',
  /** drop_down */
  DropDown = 'DROP_DOWN',
  /** drop_down_other */
  DropDownOther = 'DROP_DOWN_OTHER',
  /** email */
  Email = 'EMAIL',
  /** file */
  File = 'FILE',
  /** image */
  Image = 'IMAGE',
  /** integer */
  Integer = 'INTEGER',
  /** link */
  Link = 'LINK',
  /** radio */
  Radio = 'RADIO',
  /** rating */
  Rating = 'RATING',
  /** table */
  Table = 'TABLE',
  /** text_long */
  TextLong = 'TEXT_LONG',
  /** text_short */
  TextShort = 'TEXT_SHORT',
  /** text_tiny */
  TextTiny = 'TEXT_TINY',
  /** time */
  Time = 'TIME'
}

export type FieldGroupMetricType = Node & {
  __typename?: 'FieldGroupMetricType';
  /** The ID of the object */
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  order: Scalars['Int']['output'];
  values: Array<PieChartSegmentType>;
};


export type FieldGroupMetricTypeValuesArgs = {
  isSuiteAdminDashboard?: InputMaybe<Scalars['Boolean']['input']>;
  teamId?: InputMaybe<Scalars['ID']['input']>;
};

export type FieldGroupMetricTypeListBase = {
  __typename?: 'FieldGroupMetricTypeListBase';
  objects: Array<FieldGroupMetricType>;
  pageInfo: PageInfo;
};

/** An enumeration. */
export enum FieldGroupMetricTypeOrderingFilter {
  Id = 'id',
  Order = 'order'
}

export type FieldGroupMetricTypeOrderingFilterObject = {
  direction?: InputMaybe<OrderingDirectionEnum>;
  field?: InputMaybe<FieldGroupMetricTypeOrderingFilter>;
  modifiers?: InputMaybe<Array<InputMaybe<OrderingModifierEnum>>>;
};

export type FieldNotificationType = Node & {
  __typename?: 'FieldNotificationType';
  createdBy: UserType;
  /** (-3) means notification goes out 3 days BEFORE the entered date */
  delta: Scalars['Int']['output'];
  fieldId: Scalars['Int']['output'];
  fieldNotificationUsers: Array<FieldNotificationUserType>;
  /** The ID of the object */
  id: Scalars['ID']['output'];
  isActive: Scalars['Boolean']['output'];
  /** If false send to specifc notification users. If true, send to all users with access */
  sendToOrganisation: Scalars['Boolean']['output'];
};


export type FieldNotificationTypeFieldNotificationUsersArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<FieldNotificationUserType_FieldNotification_Field_Notification_Users_OrderingFilterObject>>>;
};

export type FieldNotificationTypeListBase = {
  __typename?: 'FieldNotificationTypeListBase';
  objects: Array<FieldNotificationType>;
  pageInfo: PageInfo;
};

/** An enumeration. */
export enum FieldNotificationTypeOrderingFilter {
  Delta = 'delta',
  Id = 'id'
}

export type FieldNotificationTypeOrderingFilterObject = {
  direction?: InputMaybe<OrderingDirectionEnum>;
  field?: InputMaybe<FieldNotificationTypeOrderingFilter>;
  modifiers?: InputMaybe<Array<InputMaybe<OrderingModifierEnum>>>;
};

export type FieldNotificationUserType = Node & {
  __typename?: 'FieldNotificationUserType';
  /** The ID of the object */
  id: Scalars['ID']['output'];
  isActive: Scalars['Boolean']['output'];
  user: UserType;
};

/** An enumeration. */
export enum FieldNotificationUserType_FieldNotification_Field_Notification_Users_OrderingFilter {
  Id = 'id'
}

export type FieldNotificationUserType_FieldNotification_Field_Notification_Users_OrderingFilterObject = {
  direction?: InputMaybe<OrderingDirectionEnum>;
  field?: InputMaybe<FieldNotificationUserType_FieldNotification_Field_Notification_Users_OrderingFilter>;
  modifiers?: InputMaybe<Array<InputMaybe<OrderingModifierEnum>>>;
};

export type FieldPubType = Node & {
  __typename?: 'FieldPubType';
  /**
   * Compatible with both normal fields and tables.
   * Determines if more than 1 row is allowed.
   */
  allowMultipleAnswers: Scalars['Boolean']['output'];
  /** Allows the user to hide the field in the form and remove it from the pdf completely */
  canHide: Scalars['Boolean']['output'];
  conditionalOption?: Maybe<SelectOptionType>;
  contentType: AnswerContentTypeEnum;
  /**
   * Locks the rows of the field, but not answer content, when using an external
   * link. This is useful in combination with table field restrictions.
   */
  disableEditingRowsWhenPublic: Scalars['Boolean']['output'];
  /** Disables editing of field values when using an external link */
  disableWhenPublic: Scalars['Boolean']['output'];
  /** Disables editing of field values form action signing is in progress */
  disableWhenSigning: Scalars['Boolean']['output'];
  disabled?: Maybe<Scalars['Boolean']['output']>;
  /**
   * Disables this field entirely. Useful if you want to freeze
   * all answers in their current state. Preference the use of
   * info blocks + custom PDF element over this field.
   */
  disabledAlways: Scalars['Boolean']['output'];
  disabledForFormActions: Array<FormActionType>;
  helpInfo?: Maybe<HelpInfoType>;
  hiddenFieldId?: Maybe<Scalars['ID']['output']>;
  /** The ID of the object */
  id: Scalars['ID']['output'];
  /** User will not be able to save the form unless this field has a truthy value */
  mandatory: Scalars['Boolean']['output'];
  placeholder?: Maybe<Scalars['String']['output']>;
  /** The order this field appears in the form */
  rank: Scalars['Int']['output'];
  selectOptions: Array<SelectOptionType>;
  /** The heading will be placed ABOVE this field */
  subsectionHeading?: Maybe<Scalars['String']['output']>;
  subtitle: Scalars['String']['output'];
  tableFields: Array<TableFieldPubType>;
  title: Scalars['String']['output'];
  type: FieldFieldTypeChoices;
};


export type FieldPubTypeDisabledForFormActionsArgs = {
  formId?: InputMaybe<Scalars['ID']['input']>;
  publicFormCode?: InputMaybe<Scalars['ID']['input']>;
  recordId?: InputMaybe<Scalars['ID']['input']>;
};


export type FieldPubTypeHiddenFieldIdArgs = {
  recordId?: InputMaybe<Scalars['ID']['input']>;
};


export type FieldPubTypeTableFieldsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<TableFieldPubType_Field_Table_Fields_OrderingFilterObject>>>;
};

/** An enumeration. */
export enum FieldPubType_Form_Fields_OrderingFilter {
  Id = 'id',
  Rank = 'rank',
  Title = 'title'
}

export type FieldPubType_Form_Fields_OrderingFilterObject = {
  direction?: InputMaybe<OrderingDirectionEnum>;
  field?: InputMaybe<FieldPubType_Form_Fields_OrderingFilter>;
  modifiers?: InputMaybe<Array<InputMaybe<OrderingModifierEnum>>>;
};

/** An enumeration. */
export enum FieldTableFieldTypeChoices {
  /** address */
  Address = 'ADDRESS',
  /** boolean */
  Boolean = 'BOOLEAN',
  /** currency */
  Currency = 'CURRENCY',
  /** date */
  Date = 'DATE',
  /** drop_down */
  DropDown = 'DROP_DOWN',
  /** drop_down_other */
  DropDownOther = 'DROP_DOWN_OTHER',
  /** email */
  Email = 'EMAIL',
  /** file */
  File = 'FILE',
  /** image */
  Image = 'IMAGE',
  /** integer */
  Integer = 'INTEGER',
  /** link */
  Link = 'LINK',
  /** radio */
  Radio = 'RADIO',
  /** rating */
  Rating = 'RATING',
  /** text_long */
  TextLong = 'TEXT_LONG',
  /** text_short */
  TextShort = 'TEXT_SHORT',
  /** text_tiny */
  TextTiny = 'TEXT_TINY',
  /** time */
  Time = 'TIME'
}

export type FieldType = Node & {
  __typename?: 'FieldType';
  /**
   * Compatible with both normal fields and tables.
   * Determines if more than 1 row is allowed.
   */
  allowMultipleAnswers: Scalars['Boolean']['output'];
  calendarNames: Array<Scalars['String']['output']>;
  /** Allows the user to hide the field in the form and remove it from the pdf completely */
  canHide: Scalars['Boolean']['output'];
  conditionalOption?: Maybe<SelectOptionType>;
  contentType: AnswerContentTypeEnum;
  /**
   * Locks the rows of the field, but not answer content, when using an external
   * link. This is useful in combination with table field restrictions.
   */
  disableEditingRowsWhenPublic: Scalars['Boolean']['output'];
  /** Disables editing of field values when using an external link */
  disableWhenPublic: Scalars['Boolean']['output'];
  /** Disables editing of field values form action signing is in progress */
  disableWhenSigning: Scalars['Boolean']['output'];
  disabled?: Maybe<Scalars['Boolean']['output']>;
  /**
   * Disables this field entirely. Useful if you want to freeze
   * all answers in their current state. Preference the use of
   * info blocks + custom PDF element over this field.
   */
  disabledAlways: Scalars['Boolean']['output'];
  disabledForFormActions: Array<FormActionType>;
  helpInfo?: Maybe<HelpInfoType>;
  hiddenFieldId?: Maybe<Scalars['ID']['output']>;
  /** The ID of the object */
  id: Scalars['ID']['output'];
  /** User will not be able to save the form unless this field has a truthy value */
  mandatory: Scalars['Boolean']['output'];
  placeholder?: Maybe<Scalars['String']['output']>;
  /** The order this field appears in the form */
  rank: Scalars['Int']['output'];
  selectOptions: Array<SelectOptionType>;
  /** The heading will be placed ABOVE this field */
  subsectionHeading?: Maybe<Scalars['String']['output']>;
  subtitle: Scalars['String']['output'];
  tableFields: Array<TableFieldType>;
  title: Scalars['String']['output'];
  type: FieldFieldTypeChoices;
};


export type FieldTypeCalendarNamesArgs = {
  moduleId?: InputMaybe<Scalars['ID']['input']>;
};


export type FieldTypeDisabledForFormActionsArgs = {
  formId?: InputMaybe<Scalars['ID']['input']>;
  publicFormCode?: InputMaybe<Scalars['ID']['input']>;
  recordId?: InputMaybe<Scalars['ID']['input']>;
};


export type FieldTypeHiddenFieldIdArgs = {
  recordId?: InputMaybe<Scalars['ID']['input']>;
};


export type FieldTypeTableFieldsArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  inTemplate?: InputMaybe<Scalars['ID']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  moveDateOnCopy?: InputMaybe<Scalars['Boolean']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<TableFieldType_Field_Table_Fields_OrderingFilterObject>>>;
};

export type FieldTypeListBase = {
  __typename?: 'FieldTypeListBase';
  objects: Array<FieldType>;
  pageInfo: PageInfo;
};

/** An enumeration. */
export enum FieldTypeOrderingFilter {
  Id = 'id',
  Rank = 'rank',
  Title = 'title'
}

export type FieldTypeOrderingFilterObject = {
  direction?: InputMaybe<OrderingDirectionEnum>;
  field?: InputMaybe<FieldTypeOrderingFilter>;
  modifiers?: InputMaybe<Array<InputMaybe<OrderingModifierEnum>>>;
};

/** An enumeration. */
export enum FieldType_FormSetDashboard_Fields_OrderingFilter {
  Id = 'id',
  Rank = 'rank',
  Title = 'title'
}

export type FieldType_FormSetDashboard_Fields_OrderingFilterObject = {
  direction?: InputMaybe<OrderingDirectionEnum>;
  field?: InputMaybe<FieldType_FormSetDashboard_Fields_OrderingFilter>;
  modifiers?: InputMaybe<Array<InputMaybe<OrderingModifierEnum>>>;
};

/** An enumeration. */
export enum FieldType_Form_Fields_OrderingFilter {
  Id = 'id',
  Rank = 'rank',
  Title = 'title'
}

export type FieldType_Form_Fields_OrderingFilterObject = {
  direction?: InputMaybe<OrderingDirectionEnum>;
  field?: InputMaybe<FieldType_Form_Fields_OrderingFilter>;
  modifiers?: InputMaybe<Array<InputMaybe<OrderingModifierEnum>>>;
};

export type FormActionBulkDeactivateMutation = {
  __typename?: 'FormActionBulkDeactivateMutation';
  actionsCount?: Maybe<Scalars['Int']['output']>;
  escalationsCount?: Maybe<Scalars['Int']['output']>;
  schedulesCount?: Maybe<Scalars['Int']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type FormActionContact = {
  __typename?: 'FormActionContact';
  email: Scalars['String']['output'];
  fieldTitle: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  objId: Scalars['String']['output'];
};

export type FormActionEscalationEventType = Node & {
  __typename?: 'FormActionEscalationEventType';
  /** If a custom email is specified. Assigned anon email answers will not use this field. */
  assignedAnonEmail: Scalars['String']['output'];
  assignedAnonEmailAnswer?: Maybe<AnswerTextPubType>;
  /** If a custom name is specified. Assigned anon email answers will not use this field. */
  assignedAnonName: Scalars['String']['output'];
  assignedUser?: Maybe<UserType>;
  created: Scalars['String']['output'];
  form: FormType;
  /** The ID of the object */
  id: Scalars['ID']['output'];
  record: RecordType;
};

export type FormActionEscalationEventTypeListBase = {
  __typename?: 'FormActionEscalationEventTypeListBase';
  objects: Array<FormActionEscalationEventType>;
  pageInfo: PageInfo;
};

/** An enumeration. */
export enum FormActionEscalationEventTypeOrderingFilter {
  Id = 'id'
}

export type FormActionEscalationEventTypeOrderingFilterObject = {
  direction?: InputMaybe<OrderingDirectionEnum>;
  field?: InputMaybe<FormActionEscalationEventTypeOrderingFilter>;
  modifiers?: InputMaybe<Array<InputMaybe<OrderingModifierEnum>>>;
};

/** An enumeration. */
export enum FormActionEscalationEventType_Record_Form_Action_Escalation_Events_OrderingFilter {
  Id = 'id'
}

export type FormActionEscalationEventType_Record_Form_Action_Escalation_Events_OrderingFilterObject = {
  direction?: InputMaybe<OrderingDirectionEnum>;
  field?: InputMaybe<FormActionEscalationEventType_Record_Form_Action_Escalation_Events_OrderingFilter>;
  modifiers?: InputMaybe<Array<InputMaybe<OrderingModifierEnum>>>;
};

export type FormActionEscalationType = Node & {
  __typename?: 'FormActionEscalationType';
  /** If a custom email is specified. Assigned anon email answers will not use this field. */
  assignedAnonEmail: Scalars['String']['output'];
  assignedAnonEmailAnswer?: Maybe<AnswerTextPubType>;
  /** If a custom name is specified. Assigned anon email answers will not use this field. */
  assignedAnonName: Scalars['String']['output'];
  assignedUser?: Maybe<UserType>;
  deleted: Scalars['Boolean']['output'];
  deltaDays: Scalars['Int']['output'];
  form: FormType;
  /** The ID of the object */
  id: Scalars['ID']['output'];
  record: RecordType;
};

export type FormActionEscalationTypeListBase = {
  __typename?: 'FormActionEscalationTypeListBase';
  objects: Array<FormActionEscalationType>;
  pageInfo: PageInfo;
};

/** An enumeration. */
export enum FormActionEscalationTypeOrderingFilter {
  Id = 'id'
}

export type FormActionEscalationTypeOrderingFilterObject = {
  direction?: InputMaybe<OrderingDirectionEnum>;
  field?: InputMaybe<FormActionEscalationTypeOrderingFilter>;
  modifiers?: InputMaybe<Array<InputMaybe<OrderingModifierEnum>>>;
};

/** An enumeration. */
export enum FormActionFormActionScheduleUnitNameChoices {
  /** Days */
  Days = 'DAYS',
  /** Months */
  Months = 'MONTHS'
}

export type FormActionPubType = Node & {
  __typename?: 'FormActionPubType';
  completedOn?: Maybe<Scalars['String']['output']>;
  form: FormPubType;
  /** The ID of the object */
  id: Scalars['ID']['output'];
  isScheduled: Scalars['Boolean']['output'];
  requestSignature: Scalars['Boolean']['output'];
};

export type FormActionScheduleType = Node & {
  __typename?: 'FormActionScheduleType';
  /** If a custom email is specified. Assigned anon email answers will not use this field. */
  assignedAnonEmail: Scalars['String']['output'];
  assignedAnonEmailAnswer?: Maybe<AnswerTextPubType>;
  /** If a custom name is specified. Assigned anon email answers will not use this field. */
  assignedAnonName: Scalars['String']['output'];
  assignedUser?: Maybe<UserType>;
  deleted: Scalars['Boolean']['output'];
  dueAt: Scalars['String']['output'];
  firstDueAt: Scalars['String']['output'];
  form: FormType;
  /** The ID of the object */
  id: Scalars['ID']['output'];
  record: RecordType;
  requestSignature: Scalars['Boolean']['output'];
  unitAmount: Scalars['Int']['output'];
  unitName: FormActionFormActionScheduleUnitNameChoices;
  /** A message supplied by the creator that will appear in the email */
  userSuppliedContext: Scalars['String']['output'];
};

export type FormActionScheduleTypeListBase = {
  __typename?: 'FormActionScheduleTypeListBase';
  objects: Array<FormActionScheduleType>;
  pageInfo: PageInfo;
};

/** An enumeration. */
export enum FormActionScheduleTypeOrderingFilter {
  Id = 'id'
}

export type FormActionScheduleTypeOrderingFilterObject = {
  direction?: InputMaybe<OrderingDirectionEnum>;
  field?: InputMaybe<FormActionScheduleTypeOrderingFilter>;
  modifiers?: InputMaybe<Array<InputMaybe<OrderingModifierEnum>>>;
};

/** An enumeration. */
export enum FormActionScheduleType_Record_Form_Action_Schedules_OrderingFilter {
  Id = 'id'
}

export type FormActionScheduleType_Record_Form_Action_Schedules_OrderingFilterObject = {
  direction?: InputMaybe<OrderingDirectionEnum>;
  field?: InputMaybe<FormActionScheduleType_Record_Form_Action_Schedules_OrderingFilter>;
  modifiers?: InputMaybe<Array<InputMaybe<OrderingModifierEnum>>>;
};

export type FormActionType = Node & {
  __typename?: 'FormActionType';
  /** If a custom email is specified. Assigned anon email answers will not use this field. */
  assignedAnonEmail: Scalars['String']['output'];
  assignedAnonEmailAnswer?: Maybe<AnswerTextPubType>;
  /** If a custom name is specified. Assigned anon email answers will not use this field. */
  assignedAnonName: Scalars['String']['output'];
  assignedUser?: Maybe<UserType>;
  completedOn?: Maybe<Scalars['String']['output']>;
  created: Scalars['String']['output'];
  createdBySchedule?: Maybe<FormActionScheduleType>;
  deleted: Scalars['Boolean']['output'];
  form: FormType;
  /** The ID of the object */
  id: Scalars['ID']['output'];
  isScheduled: Scalars['Boolean']['output'];
  link?: Maybe<Scalars['String']['output']>;
  priority: Scalars['Int']['output'];
  record: RecordType;
  reminderLast?: Maybe<Scalars['DateTime']['output']>;
  reminderNext?: Maybe<Scalars['DateTime']['output']>;
  requestSignature: Scalars['Boolean']['output'];
  /** Note to display next to signature in pdf, e.g Director */
  signatureNote: Scalars['String']['output'];
  /** A message supplied by the creator that will appear in the email */
  userSuppliedContext: Scalars['String']['output'];
};

export type FormActionTypeListBase = {
  __typename?: 'FormActionTypeListBase';
  objects: Array<FormActionType>;
  pageInfo: PageInfo;
};

/** An enumeration. */
export enum FormActionTypeOrderingFilter {
  FormTitle = 'form_Title',
  Id = 'id',
  Priority = 'priority',
  RecordName = 'record_Name'
}

export type FormActionTypeOrderingFilterObject = {
  direction?: InputMaybe<OrderingDirectionEnum>;
  field?: InputMaybe<FormActionTypeOrderingFilter>;
  modifiers?: InputMaybe<Array<InputMaybe<OrderingModifierEnum>>>;
};

/** An enumeration. */
export enum FormActionType_Record_Form_Actions_OrderingFilter {
  FormTitle = 'form_Title',
  Id = 'id',
  Priority = 'priority',
  RecordName = 'record_Name'
}

export type FormActionType_Record_Form_Actions_OrderingFilterObject = {
  direction?: InputMaybe<OrderingDirectionEnum>;
  field?: InputMaybe<FormActionType_Record_Form_Actions_OrderingFilter>;
  modifiers?: InputMaybe<Array<InputMaybe<OrderingModifierEnum>>>;
};

/** An enumeration. */
export enum FormFormActionsFormatChoices {
  /** Customisable */
  Customisable = 'CUSTOMISABLE',
  /** None */
  None = 'NONE',
  /** Pdf Creation */
  PdfCreation = 'PDF_CREATION'
}

export type FormPubType = Node & {
  __typename?: 'FormPubType';
  /** Specify how actions are configured. PDF Creation: Replaces the "Create PDF" button. Customisable: Dedicated actions section where actions can be created for others, scheduled and escalated. */
  actionsFormat: FormFormActionsFormatChoices;
  allowPublicLinks: Scalars['Boolean']['output'];
  /** Shows button on right hand panel to reset answers for record specific fields ONLY. */
  allowReset: Scalars['Boolean']['output'];
  description?: Maybe<Scalars['String']['output']>;
  fields: Array<FieldPubType>;
  /** Hides right panel. Makes more space for the form. Can make the form feel more read only. */
  hideControlPanel: Scalars['Boolean']['output'];
  /** The ID of the object */
  id: Scalars['ID']['output'];
  infoBlocks: Array<InfoBlockType>;
  lockedFromFormAction?: Maybe<Scalars['Boolean']['output']>;
  pdfGenerators: Array<PdfGeneratorPubType>;
  publicLinkIsReadOnly: Scalars['Boolean']['output'];
  /** Will default to "Submit" */
  publicLinkSubmitButtonText?: Maybe<Scalars['String']['output']>;
  readOnly?: Maybe<Scalars['Boolean']['output']>;
  title: Scalars['String']['output'];
};


export type FormPubTypeFieldsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<FieldPubType_Form_Fields_OrderingFilterObject>>>;
};


export type FormPubTypeLockedFromFormActionArgs = {
  publicFormCode?: InputMaybe<Scalars['ID']['input']>;
  recordId?: InputMaybe<Scalars['ID']['input']>;
};


export type FormPubTypePdfGeneratorsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<PdfGeneratorPubType_Form_Pdf_Generators_OrderingFilterObject>>>;
};

export type FormResetAnswersMutation = {
  __typename?: 'FormResetAnswersMutation';
  message?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type FormSavePubType = Node & {
  __typename?: 'FormSavePubType';
  /** The ID of the object */
  id: Scalars['ID']['output'];
};

export type FormSaveType = Node & {
  __typename?: 'FormSaveType';
  /** The ID of the object */
  id: Scalars['ID']['output'];
};

export type FormSetDashboardSectionType = Node & {
  __typename?: 'FormSetDashboardSectionType';
  /** The ID of the object */
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  rank: Scalars['Int']['output'];
};

/** An enumeration. */
export enum FormSetDashboardSectionType_FormSetDashboard_Sections_OrderingFilter {
  Id = 'id'
}

export type FormSetDashboardSectionType_FormSetDashboard_Sections_OrderingFilterObject = {
  direction?: InputMaybe<OrderingDirectionEnum>;
  field?: InputMaybe<FormSetDashboardSectionType_FormSetDashboard_Sections_OrderingFilter>;
  modifiers?: InputMaybe<Array<InputMaybe<OrderingModifierEnum>>>;
};

export type FormSetDashboardType = Node & {
  __typename?: 'FormSetDashboardType';
  dashboardFields: Array<FormSetDashboard_FieldType>;
  fields: Array<FieldType>;
  /** The ID of the object */
  id: Scalars['ID']['output'];
  sections: Array<FormSetDashboardSectionType>;
};


export type FormSetDashboardTypeDashboardFieldsArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<FormSetDashboard_FieldType_FormSetDashboard_Dashboard_Fields_OrderingFilterObject>>>;
};


export type FormSetDashboardTypeFieldsArgs = {
  hasDate?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  inModule?: InputMaybe<Scalars['ID']['input']>;
  inTemplate?: InputMaybe<Scalars['ID']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  moveDateOnCopy?: InputMaybe<Scalars['Boolean']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<FieldType_FormSetDashboard_Fields_OrderingFilterObject>>>;
  title?: InputMaybe<Scalars['String']['input']>;
};


export type FormSetDashboardTypeSectionsArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<FormSetDashboardSectionType_FormSetDashboard_Sections_OrderingFilterObject>>>;
};

export type FormSetDashboard_FieldType = Node & {
  __typename?: 'FormSetDashboard_FieldType';
  answers: Array<Scalars['String']['output']>;
  field: FieldType;
  form: Scalars['ID']['output'];
  hideIfBlank: Scalars['Boolean']['output'];
  /** The ID of the object */
  id: Scalars['ID']['output'];
  rank: Scalars['Int']['output'];
};


export type FormSetDashboard_FieldTypeAnswersArgs = {
  recordId?: InputMaybe<Scalars['ID']['input']>;
};


export type FormSetDashboard_FieldTypeFormArgs = {
  recordId?: InputMaybe<Scalars['ID']['input']>;
};

/** An enumeration. */
export enum FormSetDashboard_FieldType_FormSetDashboard_Dashboard_Fields_OrderingFilter {
  Id = 'id'
}

export type FormSetDashboard_FieldType_FormSetDashboard_Dashboard_Fields_OrderingFilterObject = {
  direction?: InputMaybe<OrderingDirectionEnum>;
  field?: InputMaybe<FormSetDashboard_FieldType_FormSetDashboard_Dashboard_Fields_OrderingFilter>;
  modifiers?: InputMaybe<Array<InputMaybe<OrderingModifierEnum>>>;
};

export type FormSetType = Node & {
  __typename?: 'FormSetType';
  dashboard?: Maybe<FormSetDashboardType>;
  forms: Array<FormType>;
  /** The ID of the object */
  id: Scalars['ID']['output'];
  namePlaceholder?: Maybe<Scalars['String']['output']>;
};


export type FormSetTypeFormsArgs = {
  actionsFormat_In?: InputMaybe<Array<InputMaybe<FormFormActionsFormatChoices>>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<FormType_FormSet_Forms_OrderingFilterObject>>>;
};

export type FormStatusMetricType = Node & {
  __typename?: 'FormStatusMetricType';
  /** The ID of the object */
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  order: Scalars['Int']['output'];
  values: Array<PieChartSegmentType>;
};


export type FormStatusMetricTypeValuesArgs = {
  isSuiteAdminDashboard?: InputMaybe<Scalars['Boolean']['input']>;
  teamId?: InputMaybe<Scalars['ID']['input']>;
};

export type FormStatusMetricTypeListBase = {
  __typename?: 'FormStatusMetricTypeListBase';
  objects: Array<FormStatusMetricType>;
  pageInfo: PageInfo;
};

/** An enumeration. */
export enum FormStatusMetricTypeOrderingFilter {
  Id = 'id',
  Order = 'order'
}

export type FormStatusMetricTypeOrderingFilterObject = {
  direction?: InputMaybe<OrderingDirectionEnum>;
  field?: InputMaybe<FormStatusMetricTypeOrderingFilter>;
  modifiers?: InputMaybe<Array<InputMaybe<OrderingModifierEnum>>>;
};

export type FormType = Node & {
  __typename?: 'FormType';
  /** Specify how actions are configured. PDF Creation: Replaces the "Create PDF" button. Customisable: Dedicated actions section where actions can be created for others, scheduled and escalated. */
  actionsFormat: FormFormActionsFormatChoices;
  allowPublicLinks: Scalars['Boolean']['output'];
  /** Shows button on right hand panel to reset answers for record specific fields ONLY. */
  allowReset: Scalars['Boolean']['output'];
  /** The subcategory that the form sits in on the left panel */
  category?: Maybe<CategoryType>;
  /** If set, the dependent form will need to be completed before completing this one. */
  dependency?: Maybe<FormType>;
  description?: Maybe<Scalars['String']['output']>;
  fields: Array<FieldType>;
  hasCreatedPdf?: Maybe<Scalars['Boolean']['output']>;
  hasSaved?: Maybe<Scalars['Boolean']['output']>;
  /** Hides right panel. Makes more space for the form. Can make the form feel more read only. */
  hideControlPanel: Scalars['Boolean']['output'];
  /** The ID of the object */
  id: Scalars['ID']['output'];
  infoBlocks: Array<InfoBlockType>;
  lockedFromFormAction?: Maybe<Scalars['Boolean']['output']>;
  pdfGenerators: Array<PdfGeneratorType>;
  publicLinkIsReadOnly: Scalars['Boolean']['output'];
  /** Will default to "Submit" */
  publicLinkSubmitButtonText?: Maybe<Scalars['String']['output']>;
  /** The order this form appears in the list */
  rank: Scalars['Int']['output'];
  readOnly?: Maybe<Scalars['Boolean']['output']>;
  title: Scalars['String']['output'];
};


export type FormTypeFieldsArgs = {
  hasDate?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  inModule?: InputMaybe<Scalars['ID']['input']>;
  inTemplate?: InputMaybe<Scalars['ID']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  moveDateOnCopy?: InputMaybe<Scalars['Boolean']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<FieldType_Form_Fields_OrderingFilterObject>>>;
  title?: InputMaybe<Scalars['String']['input']>;
};


export type FormTypeHasCreatedPdfArgs = {
  recordId?: InputMaybe<Scalars['ID']['input']>;
};


export type FormTypeHasSavedArgs = {
  recordId?: InputMaybe<Scalars['ID']['input']>;
};


export type FormTypeLockedFromFormActionArgs = {
  publicFormCode?: InputMaybe<Scalars['ID']['input']>;
  recordId?: InputMaybe<Scalars['ID']['input']>;
};


export type FormTypePdfGeneratorsArgs = {
  formId?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<PdfGeneratorType_Form_Pdf_Generators_OrderingFilterObject>>>;
};

export type FormTypeListBase = {
  __typename?: 'FormTypeListBase';
  objects: Array<FormType>;
  pageInfo: PageInfo;
};

/** An enumeration. */
export enum FormTypeOrderingFilter {
  CategoryRank = 'category_Rank',
  Id = 'id',
  Rank = 'rank',
  Title = 'title'
}

export type FormTypeOrderingFilterObject = {
  direction?: InputMaybe<OrderingDirectionEnum>;
  field?: InputMaybe<FormTypeOrderingFilter>;
  modifiers?: InputMaybe<Array<InputMaybe<OrderingModifierEnum>>>;
};

/** An enumeration. */
export enum FormType_FormSet_Forms_OrderingFilter {
  CategoryRank = 'category_Rank',
  Id = 'id',
  Rank = 'rank',
  Title = 'title'
}

export type FormType_FormSet_Forms_OrderingFilterObject = {
  direction?: InputMaybe<OrderingDirectionEnum>;
  field?: InputMaybe<FormType_FormSet_Forms_OrderingFilter>;
  modifiers?: InputMaybe<Array<InputMaybe<OrderingModifierEnum>>>;
};

export type HelpInfoType = {
  __typename?: 'HelpInfoType';
  htmlContent: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export type HiddenFieldType = Node & {
  __typename?: 'HiddenFieldType';
  deleted: Scalars['Boolean']['output'];
  field: FieldType;
  /** The ID of the object */
  id: Scalars['ID']['output'];
};

export type IncludeFieldType = Node & {
  __typename?: 'IncludeFieldType';
  field: FieldType;
  /** The ID of the object */
  id: Scalars['ID']['output'];
  rank: Scalars['Int']['output'];
};

/** An enumeration. */
export enum IncludeFieldType_ConcatenatedTable_Include_Fields_OrderingFilter {
  Id = 'id',
  Rank = 'rank'
}

export type IncludeFieldType_ConcatenatedTable_Include_Fields_OrderingFilterObject = {
  direction?: InputMaybe<OrderingDirectionEnum>;
  field?: InputMaybe<IncludeFieldType_ConcatenatedTable_Include_Fields_OrderingFilter>;
  modifiers?: InputMaybe<Array<InputMaybe<OrderingModifierEnum>>>;
};

export type IncludeTableFieldType = Node & {
  __typename?: 'IncludeTableFieldType';
  /** The ID of the object */
  id: Scalars['ID']['output'];
  rank: Scalars['Int']['output'];
  tableField: TableFieldType;
};

/** An enumeration. */
export enum IncludeTableFieldType_ConcatenatedTable_Include_Table_Fields_OrderingFilter {
  Id = 'id',
  Rank = 'rank'
}

export type IncludeTableFieldType_ConcatenatedTable_Include_Table_Fields_OrderingFilterObject = {
  direction?: InputMaybe<OrderingDirectionEnum>;
  field?: InputMaybe<IncludeTableFieldType_ConcatenatedTable_Include_Table_Fields_OrderingFilter>;
  modifiers?: InputMaybe<Array<InputMaybe<OrderingModifierEnum>>>;
};

export type InfoBlockType = {
  __typename?: 'InfoBlockType';
  conditionalOption?: Maybe<SelectOptionType>;
  contentHtml: Scalars['String']['output'];
  hasPaddingAfter: Scalars['Boolean']['output'];
  hasPaddingBefore: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  rank: Scalars['Int']['output'];
  title: Scalars['String']['output'];
  titleSize: TitleSizeEnum;
};

export type InitialAnswerPubType = Node & {
  __typename?: 'InitialAnswerPubType';
  contentBoolean: Scalars['Boolean']['output'];
  contentDate?: Maybe<Scalars['String']['output']>;
  contentDecimal?: Maybe<Scalars['Decimal']['output']>;
  contentFile?: Maybe<Scalars['DjangoFileType']['output']>;
  contentFileName?: Maybe<Scalars['String']['output']>;
  contentText?: Maybe<Scalars['String']['output']>;
  field: FieldType;
  fieldId: Scalars['String']['output'];
  /** The ID of the object */
  id: Scalars['ID']['output'];
  rank: Scalars['Int']['output'];
  tableField?: Maybe<TableFieldType>;
  tableFieldId?: Maybe<Scalars['String']['output']>;
};

export type InitialAnswerPubTypeListBase = {
  __typename?: 'InitialAnswerPubTypeListBase';
  objects: Array<InitialAnswerPubType>;
  pageInfo: PageInfo;
};

/** An enumeration. */
export enum InitialAnswerPubTypeOrderingFilter {
  Id = 'id',
  Rank = 'rank'
}

export type InitialAnswerPubTypeOrderingFilterObject = {
  direction?: InputMaybe<OrderingDirectionEnum>;
  field?: InputMaybe<InitialAnswerPubTypeOrderingFilter>;
  modifiers?: InputMaybe<Array<InputMaybe<OrderingModifierEnum>>>;
};

export type InitialAnswerType = Node & {
  __typename?: 'InitialAnswerType';
  contentBoolean: Scalars['Boolean']['output'];
  contentDate?: Maybe<Scalars['String']['output']>;
  contentDecimal?: Maybe<Scalars['Decimal']['output']>;
  contentFile?: Maybe<Scalars['DjangoFileType']['output']>;
  contentFileName?: Maybe<Scalars['String']['output']>;
  contentText?: Maybe<Scalars['String']['output']>;
  field: FieldType;
  fieldId: Scalars['String']['output'];
  /** The ID of the object */
  id: Scalars['ID']['output'];
  rank: Scalars['Int']['output'];
  tableField?: Maybe<TableFieldType>;
  tableFieldId?: Maybe<Scalars['String']['output']>;
};

export type InitialAnswerTypeListBase = {
  __typename?: 'InitialAnswerTypeListBase';
  objects: Array<InitialAnswerType>;
  pageInfo: PageInfo;
};

/** An enumeration. */
export enum InitialAnswerTypeOrderingFilter {
  Id = 'id',
  Rank = 'rank'
}

export type InitialAnswerTypeOrderingFilterObject = {
  direction?: InputMaybe<OrderingDirectionEnum>;
  field?: InputMaybe<InitialAnswerTypeOrderingFilter>;
  modifiers?: InputMaybe<Array<InputMaybe<OrderingModifierEnum>>>;
};

export type LoginMutation = {
  __typename?: 'LoginMutation';
  failed?: Maybe<Scalars['Boolean']['output']>;
  user?: Maybe<UserType>;
  webauthnOptions?: Maybe<Scalars['String']['output']>;
};

export type LogoutMutation = {
  __typename?: 'LogoutMutation';
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type MergedPdfItemType = Node & {
  __typename?: 'MergedPdfItemType';
  /** The ID of the object */
  id: Scalars['ID']['output'];
  order: Scalars['Int']['output'];
  pdf: PdfType;
};

export type MergedPdfType = Node & {
  __typename?: 'MergedPdfType';
  created: Scalars['String']['output'];
  /** The ID of the object */
  id: Scalars['ID']['output'];
  mergedPdfItems: Array<MergedPdfItemType>;
  name: Scalars['String']['output'];
};

export type MergedPdfTypeListBase = {
  __typename?: 'MergedPdfTypeListBase';
  objects: Array<MergedPdfType>;
  pageInfo: PageInfo;
};

/** An enumeration. */
export enum MergedPdfTypeOrderingFilter {
  Created = 'created',
  Id = 'id'
}

export type MergedPdfTypeOrderingFilterObject = {
  direction?: InputMaybe<OrderingDirectionEnum>;
  field?: InputMaybe<MergedPdfTypeOrderingFilter>;
  modifiers?: InputMaybe<Array<InputMaybe<OrderingModifierEnum>>>;
};

export type MetricFormLink = {
  __typename?: 'MetricFormLink';
  fieldId?: Maybe<Scalars['ID']['output']>;
  fieldTitle?: Maybe<Scalars['String']['output']>;
  formId: Scalars['ID']['output'];
  formTitle: Scalars['String']['output'];
  organisationName: Scalars['String']['output'];
  recordId: Scalars['ID']['output'];
  recordName: Scalars['String']['output'];
  teamName: Scalars['String']['output'];
};

export type ModuleType = Node & {
  __typename?: 'ModuleType';
  /** If set, will be used as the title of the add team modal */
  addTeamPhrase?: Maybe<Scalars['String']['output']>;
  allowPositionTextChange: Scalars['Boolean']['output'];
  allowRecordTemplateChange: Scalars['Boolean']['output'];
  descriptionHtml: Scalars['String']['output'];
  icon?: Maybe<Scalars['String']['output']>;
  /** The ID of the object */
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  positionWord: Scalars['String']['output'];
  publicRecordCreateLinks: Array<PublicRecordCreateLinkType>;
  /** DEPRECATED */
  recordFilesEnabled: Scalars['Boolean']['output'];
  recordImages: Scalars['Boolean']['output'];
  recordWord: Scalars['String']['output'];
  /** Shows a tab on the module homepage that plots action activity on a timeline. */
  showActionDashboard: Scalars['Boolean']['output'];
  /** List all active external links on dashboard */
  showActiveLinksOnDashboard: Scalars['Boolean']['output'];
  /** Show date answers on calendar as tab on the dashboard  */
  showCalendar: Scalars['Boolean']['output'];
  /** Plot address answers on a map as a tab on the dashboard  */
  showMap: Scalars['Boolean']['output'];
  /** Limits the module to only ONE team per organisation */
  singleton: Scalars['Boolean']['output'];
  teamWord: Scalars['String']['output'];
  teams: Array<TeamType>;
  templates: Array<TemplateType>;
};


export type ModuleTypeTeamsArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  id_Gt?: InputMaybe<Scalars['ID']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<TeamType_Module_Teams_OrderingFilterObject>>>;
};


export type ModuleTypeTemplatesArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  module?: InputMaybe<Scalars['ID']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<TemplateType_Module_Templates_OrderingFilterObject>>>;
  position?: InputMaybe<Scalars['String']['input']>;
  position_Icontains?: InputMaybe<Scalars['String']['input']>;
};

export type ModuleTypeListBase = {
  __typename?: 'ModuleTypeListBase';
  objects: Array<ModuleType>;
  pageInfo: PageInfo;
};

/** An enumeration. */
export enum ModuleTypeOrderingFilter {
  Id = 'id'
}

export type ModuleTypeOrderingFilterObject = {
  direction?: InputMaybe<OrderingDirectionEnum>;
  field?: InputMaybe<ModuleTypeOrderingFilter>;
  modifiers?: InputMaybe<Array<InputMaybe<OrderingModifierEnum>>>;
};

/** An enumeration. */
export enum ModuleType_Template_Modules_OrderingFilter {
  Id = 'id'
}

export type ModuleType_Template_Modules_OrderingFilterObject = {
  direction?: InputMaybe<OrderingDirectionEnum>;
  field?: InputMaybe<ModuleType_Template_Modules_OrderingFilter>;
  modifiers?: InputMaybe<Array<InputMaybe<OrderingModifierEnum>>>;
};

/** An enumeration. */
export enum MoveDatesType {
  /** MoveDatesType.YEAR_DELTA */
  YearDelta = 'YEAR_DELTA',
  /** MoveDatesType.YEAR_SPECIFIC */
  YearSpecific = 'YEAR_SPECIFIC'
}

export type Node = {
  /** The ID of the object */
  id: Scalars['ID']['output'];
};

export type NotificationsMetricType = Node & {
  __typename?: 'NotificationsMetricType';
  /** The ID of the object */
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  order: Scalars['Int']['output'];
  type: AnalyticsUpcomingNotificationsMetricTypeChoices;
  values: Array<FieldDateValueType>;
};


export type NotificationsMetricTypeValuesArgs = {
  isSuiteAdminDashboard?: InputMaybe<Scalars['Boolean']['input']>;
  teamId?: InputMaybe<Scalars['ID']['input']>;
};

export type NotificationsMetricTypeListBase = {
  __typename?: 'NotificationsMetricTypeListBase';
  objects: Array<NotificationsMetricType>;
  pageInfo: PageInfo;
};

/** An enumeration. */
export enum NotificationsMetricTypeOrderingFilter {
  Id = 'id',
  Order = 'order'
}

export type NotificationsMetricTypeOrderingFilterObject = {
  direction?: InputMaybe<OrderingDirectionEnum>;
  field?: InputMaybe<NotificationsMetricTypeOrderingFilter>;
  modifiers?: InputMaybe<Array<InputMaybe<OrderingModifierEnum>>>;
};

/** An enumeration. */
export enum OrderingDirectionEnum {
  Asc = 'ASC',
  Desc = 'DESC'
}

/** An enumeration. */
export enum OrderingModifierEnum {
  CaseInsensitive = 'CASE_INSENSITIVE',
  NullsFirst = 'NULLS_FIRST'
}

export type OrganisationPubType = Node & {
  __typename?: 'OrganisationPubType';
  /** The ID of the object */
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type OrganisationType = Node & {
  __typename?: 'OrganisationType';
  csvDownloadEnabled: Scalars['Boolean']['output'];
  /** The ID of the object */
  id: Scalars['ID']['output'];
  logo?: Maybe<Scalars['DjangoFileType']['output']>;
  name: Scalars['String']['output'];
  showTeamFiles: Scalars['Boolean']['output'];
  subdomain?: Maybe<Scalars['String']['output']>;
  suite: SuiteType;
  teams: Array<TeamType>;
  /** DEPRECATED: Was used with form -> show_team_files. Use file field instead */
  usingFormFileLinks: Scalars['Boolean']['output'];
};


export type OrganisationTypeTeamsArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  id_Gt?: InputMaybe<Scalars['ID']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<TeamType_Organisation_Teams_OrderingFilterObject>>>;
};

export type OrganisationTypeListBase = {
  __typename?: 'OrganisationTypeListBase';
  objects: Array<OrganisationType>;
  pageInfo: PageInfo;
};

/** An enumeration. */
export enum OrganisationTypeOrderingFilter {
  Id = 'id'
}

export type OrganisationTypeOrderingFilterObject = {
  direction?: InputMaybe<OrderingDirectionEnum>;
  field?: InputMaybe<OrganisationTypeOrderingFilter>;
  modifiers?: InputMaybe<Array<InputMaybe<OrderingModifierEnum>>>;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  hasNextPage?: Maybe<Scalars['Boolean']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
};

export type PdfGeneratorPubType = Node & {
  __typename?: 'PdfGeneratorPubType';
  /** The ID of the object */
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

/** An enumeration. */
export enum PdfGeneratorPubType_Form_Pdf_Generators_OrderingFilter {
  Id = 'id',
  Name = 'name'
}

export type PdfGeneratorPubType_Form_Pdf_Generators_OrderingFilterObject = {
  direction?: InputMaybe<OrderingDirectionEnum>;
  field?: InputMaybe<PdfGeneratorPubType_Form_Pdf_Generators_OrderingFilter>;
  modifiers?: InputMaybe<Array<InputMaybe<OrderingModifierEnum>>>;
};

export type PdfGeneratorType = Node & {
  __typename?: 'PdfGeneratorType';
  /** The ID of the object */
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  pdfs: Array<PdfType>;
};


export type PdfGeneratorTypePdfsArgs = {
  recordId?: InputMaybe<Scalars['ID']['input']>;
};

export type PdfGeneratorTypeListBase = {
  __typename?: 'PdfGeneratorTypeListBase';
  objects: Array<PdfGeneratorType>;
  pageInfo: PageInfo;
};

/** An enumeration. */
export enum PdfGeneratorTypeOrderingFilter {
  Id = 'id',
  Name = 'name'
}

export type PdfGeneratorTypeOrderingFilterObject = {
  direction?: InputMaybe<OrderingDirectionEnum>;
  field?: InputMaybe<PdfGeneratorTypeOrderingFilter>;
  modifiers?: InputMaybe<Array<InputMaybe<OrderingModifierEnum>>>;
};

/** An enumeration. */
export enum PdfGeneratorType_Form_Pdf_Generators_OrderingFilter {
  Id = 'id',
  Name = 'name'
}

export type PdfGeneratorType_Form_Pdf_Generators_OrderingFilterObject = {
  direction?: InputMaybe<OrderingDirectionEnum>;
  field?: InputMaybe<PdfGeneratorType_Form_Pdf_Generators_OrderingFilter>;
  modifiers?: InputMaybe<Array<InputMaybe<OrderingModifierEnum>>>;
};

/** An enumeration. */
export enum PdfPdfOriginChoices {
  /** FORM_ACTION */
  FormAction = 'FORM_ACTION',
  /** PUBLIC_FORM */
  PublicForm = 'PUBLIC_FORM',
  /** RESET */
  Reset = 'RESET',
  /** USER */
  User = 'USER'
}

export type PdfType = Node & {
  __typename?: 'PdfType';
  created: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  form: FormType;
  fromPublicLink: Scalars['Boolean']['output'];
  /** The ID of the object */
  id: Scalars['ID']['output'];
  isActive: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  origin: PdfPdfOriginChoices;
  pdfGenerator: PdfGeneratorType;
  record: RecordType;
};

export type PdfTypeListBase = {
  __typename?: 'PdfTypeListBase';
  objects: Array<PdfType>;
  pageInfo: PageInfo;
};

/** An enumeration. */
export enum PdfTypeOrderingFilter {
  FormCategoryRank = 'form_Category_Rank',
  FormRank = 'form_Rank',
  Id = 'id'
}

export type PdfTypeOrderingFilterObject = {
  direction?: InputMaybe<OrderingDirectionEnum>;
  field?: InputMaybe<PdfTypeOrderingFilter>;
  modifiers?: InputMaybe<Array<InputMaybe<OrderingModifierEnum>>>;
};

export type PharosMutations = {
  __typename?: 'PharosMutations';
  answerBooleanPub_BulkUpdate?: Maybe<Answer_Boolean_PubPayload>;
  answerBoolean_BulkUpdate?: Maybe<Answer_BooleanPayload>;
  answerDatePub_BulkUpdate?: Maybe<Answer_Date_PubPayload>;
  answerDate_BulkUpdate?: Maybe<Answer_DatePayload>;
  answerDecimalPub_BulkUpdate?: Maybe<Answer_Decimal_PubPayload>;
  answerDecimal_BulkUpdate?: Maybe<Answer_DecimalPayload>;
  answerFilePub_BulkUpdate?: Maybe<Answer_File_PubPayload>;
  answerFile_BulkUpdate?: Maybe<Answer_FilePayload>;
  answerTextPub_BulkUpdate?: Maybe<Answer_Text_PubPayload>;
  answerText_BulkUpdate?: Maybe<Answer_TextPayload>;
  email_Update?: Maybe<EmailPayload>;
  fieldNotificationUser_Update?: Maybe<Field_Notification_UserPayload>;
  fieldNotification_Update?: Maybe<Field_NotificationPayload>;
  formActionEscalation_BulkUpdate?: Maybe<Form_Action_Escalation_BulkPayload>;
  formActionEscalation_Update?: Maybe<Form_Action_EscalationPayload>;
  formActionPub_Update?: Maybe<Form_Action_PubPayload>;
  formActionSchedule_Update?: Maybe<Form_Action_SchedulePayload>;
  formAction_BulkDeactivate?: Maybe<FormActionBulkDeactivateMutation>;
  formAction_BulkUpdate?: Maybe<Form_Action_BulkPayload>;
  formAction_Update?: Maybe<Form_ActionPayload>;
  formSavePub_BulkUpdate?: Maybe<Form_Save_Pub_BulkPayload>;
  formSavePub_Update?: Maybe<Form_Save_PubPayload>;
  formSave_BulkUpdate?: Maybe<Form_Save_BulkPayload>;
  formSave_Update?: Maybe<Form_SavePayload>;
  form_ResetAnswers?: Maybe<FormResetAnswersMutation>;
  hiddenField_Update?: Maybe<Hidden_FieldPayload>;
  mergedPdf_Update?: Maybe<Merged_PdfPayload>;
  organisation_Update?: Maybe<OrganisationPayload>;
  pdf_Update?: Maybe<PdfPayload>;
  publicForm_Submit?: Maybe<SubmitPublicFormMutation>;
  publicForm_Update?: Maybe<Public_FormPayload>;
  publicRecordCreate?: Maybe<PublicRecordCreateMutation>;
  record_Update?: Maybe<RecordPayload>;
  team_Update?: Maybe<TeamPayload>;
  userTeam_Update?: Maybe<User_TeamPayload>;
  user_Login?: Maybe<LoginMutation>;
  user_Logout?: Maybe<LogoutMutation>;
  user_Signup?: Maybe<UserSignupMutation>;
  user_SwitchOrgAdmin?: Maybe<SwitchOrgAdmin>;
  user_Update?: Maybe<UserPayload>;
  webauthnCredentials_AdminResetForUser?: Maybe<WebauthnAdminResetForUser>;
  webauthnCredentials_Authenticate?: Maybe<WebauthnAuthenticate>;
  webauthnCredentials_BeginRegistration?: Maybe<WebauthnBeginRegistration>;
  webauthnCredentials_CompleteRegistration?: Maybe<WebauthnCompleteRegistration>;
  webauthnCredentials_Update?: Maybe<Webauthn_CredentialsPayload>;
};


export type PharosMutationsAnswerBooleanPub_BulkUpdateArgs = {
  input: Answer_Boolean_PubInput;
};


export type PharosMutationsAnswerBoolean_BulkUpdateArgs = {
  input: Answer_BooleanInput;
};


export type PharosMutationsAnswerDatePub_BulkUpdateArgs = {
  input: Answer_Date_PubInput;
};


export type PharosMutationsAnswerDate_BulkUpdateArgs = {
  input: Answer_DateInput;
};


export type PharosMutationsAnswerDecimalPub_BulkUpdateArgs = {
  input: Answer_Decimal_PubInput;
};


export type PharosMutationsAnswerDecimal_BulkUpdateArgs = {
  input: Answer_DecimalInput;
};


export type PharosMutationsAnswerFilePub_BulkUpdateArgs = {
  input: Answer_File_PubInput;
};


export type PharosMutationsAnswerFile_BulkUpdateArgs = {
  input: Answer_FileInput;
};


export type PharosMutationsAnswerTextPub_BulkUpdateArgs = {
  input: Answer_Text_PubInput;
};


export type PharosMutationsAnswerText_BulkUpdateArgs = {
  input: Answer_TextInput;
};


export type PharosMutationsEmail_UpdateArgs = {
  input: EmailInput;
};


export type PharosMutationsFieldNotificationUser_UpdateArgs = {
  input: Field_Notification_UserInput;
};


export type PharosMutationsFieldNotification_UpdateArgs = {
  input: Field_NotificationInput;
};


export type PharosMutationsFormActionEscalation_BulkUpdateArgs = {
  input: Form_Action_Escalation_BulkInput;
};


export type PharosMutationsFormActionEscalation_UpdateArgs = {
  input: Form_Action_EscalationInput;
};


export type PharosMutationsFormActionPub_UpdateArgs = {
  input: Form_Action_PubInput;
};


export type PharosMutationsFormActionSchedule_UpdateArgs = {
  input: Form_Action_ScheduleInput;
};


export type PharosMutationsFormAction_BulkDeactivateArgs = {
  recordFormPairs?: InputMaybe<Array<InputMaybe<RecordFormPair>>>;
};


export type PharosMutationsFormAction_BulkUpdateArgs = {
  input: Form_Action_BulkInput;
};


export type PharosMutationsFormAction_UpdateArgs = {
  input: Form_ActionInput;
};


export type PharosMutationsFormSavePub_BulkUpdateArgs = {
  input: Form_Save_Pub_BulkInput;
};


export type PharosMutationsFormSavePub_UpdateArgs = {
  input: Form_Save_PubInput;
};


export type PharosMutationsFormSave_BulkUpdateArgs = {
  input: Form_Save_BulkInput;
};


export type PharosMutationsFormSave_UpdateArgs = {
  input: Form_SaveInput;
};


export type PharosMutationsForm_ResetAnswersArgs = {
  formId?: InputMaybe<Scalars['ID']['input']>;
  recordId?: InputMaybe<Scalars['ID']['input']>;
};


export type PharosMutationsHiddenField_UpdateArgs = {
  input: Hidden_FieldInput;
};


export type PharosMutationsMergedPdf_UpdateArgs = {
  input: Merged_PdfInput;
};


export type PharosMutationsOrganisation_UpdateArgs = {
  input: OrganisationInput;
};


export type PharosMutationsPdf_UpdateArgs = {
  input: PdfInput;
};


export type PharosMutationsPublicForm_SubmitArgs = {
  publicFormCode?: InputMaybe<Scalars['String']['input']>;
  submitterEmail?: InputMaybe<Scalars['String']['input']>;
};


export type PharosMutationsPublicForm_UpdateArgs = {
  input: Public_FormInput;
};


export type PharosMutationsPublicRecordCreateArgs = {
  choice: Scalars['ID']['input'];
  linkCode: Scalars['String']['input'];
  recordName: Scalars['String']['input'];
  teamCode: Scalars['String']['input'];
};


export type PharosMutationsRecord_UpdateArgs = {
  input: RecordInput;
};


export type PharosMutationsTeam_UpdateArgs = {
  input: TeamInput;
};


export type PharosMutationsUserTeam_UpdateArgs = {
  input: User_TeamInput;
};


export type PharosMutationsUser_LoginArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
};


export type PharosMutationsUser_SignupArgs = {
  orgData: SignupOrgInput;
  userData: SignupUserInput;
};


export type PharosMutationsUser_SwitchOrgAdminArgs = {
  organisationId?: InputMaybe<Scalars['ID']['input']>;
  teamId?: InputMaybe<Scalars['ID']['input']>;
};


export type PharosMutationsUser_UpdateArgs = {
  input: UserInput;
};


export type PharosMutationsWebauthnCredentials_AdminResetForUserArgs = {
  resetUserId?: InputMaybe<Scalars['ID']['input']>;
};


export type PharosMutationsWebauthnCredentials_AuthenticateArgs = {
  credentialString?: InputMaybe<Scalars['String']['input']>;
};


export type PharosMutationsWebauthnCredentials_CompleteRegistrationArgs = {
  displayName?: InputMaybe<Scalars['String']['input']>;
  registrationResponse?: InputMaybe<Scalars['String']['input']>;
};


export type PharosMutationsWebauthnCredentials_UpdateArgs = {
  input: Webauthn_CredentialsInput;
};

export type PharosQuery = {
  __typename?: 'PharosQuery';
  activityEvent?: Maybe<ActivityEventType>;
  activityEvent_List?: Maybe<ActivityEventTypeListBase>;
  adminOrgSwitchHistory?: Maybe<AdminOrgSwitchHistoryType>;
  adminOrgSwitchHistory_List?: Maybe<AdminOrgSwitchHistoryTypeListBase>;
  adviceSection?: Maybe<AdviceSectionType>;
  adviceSection_List?: Maybe<AdviceSectionTypeListBase>;
  answerBoolean?: Maybe<AnswerBooleanType>;
  answerBooleanPub?: Maybe<AnswerBooleanPubType>;
  answerBooleanPub_List?: Maybe<AnswerBooleanPubTypeListBase>;
  answerBoolean_List?: Maybe<AnswerBooleanTypeListBase>;
  answerDate?: Maybe<AnswerDateType>;
  answerDatePub?: Maybe<AnswerDatePubType>;
  answerDatePub_List?: Maybe<AnswerDatePubTypeListBase>;
  answerDate_List?: Maybe<AnswerDateTypeListBase>;
  answerDecimal?: Maybe<AnswerDecimalType>;
  answerDecimalPub?: Maybe<AnswerDecimalPubType>;
  answerDecimalPub_List?: Maybe<AnswerDecimalPubTypeListBase>;
  answerDecimal_List?: Maybe<AnswerDecimalTypeListBase>;
  answerFile?: Maybe<AnswerFileType>;
  answerFilePub?: Maybe<AnswerFilePubType>;
  answerFilePub_List?: Maybe<AnswerFilePubTypeListBase>;
  answerFile_List?: Maybe<AnswerFileTypeListBase>;
  answerText?: Maybe<AnswerTextType>;
  answerTextPub?: Maybe<AnswerTextPubType>;
  answerTextPub_List?: Maybe<AnswerTextPubTypeListBase>;
  answerText_List?: Maybe<AnswerTextTypeListBase>;
  apiVersionDetails: ApiVersionDetails;
  concatenatedTable?: Maybe<ConcatenatedTableType>;
  concatenatedTableData?: Maybe<ConcatenatedTableDataType>;
  concatenatedTable_List?: Maybe<ConcatenatedTableTypeListBase>;
  email?: Maybe<EmailType>;
  emailOption_List?: Maybe<Array<EmailOption>>;
  email_List?: Maybe<EmailTypeListBase>;
  field?: Maybe<FieldType>;
  fieldAggregationMetric?: Maybe<FieldAggregationMetricType>;
  fieldAggregationMetric_List?: Maybe<FieldAggregationMetricTypeListBase>;
  fieldDateRangeMetric?: Maybe<FieldDateRangeMetricType>;
  fieldDateRangeMetric_List?: Maybe<FieldDateRangeMetricTypeListBase>;
  fieldGroupMetric?: Maybe<FieldGroupMetricType>;
  fieldGroupMetric_List?: Maybe<FieldGroupMetricTypeListBase>;
  fieldNotification?: Maybe<FieldNotificationType>;
  fieldNotification_List?: Maybe<FieldNotificationTypeListBase>;
  field_List?: Maybe<FieldTypeListBase>;
  form?: Maybe<FormType>;
  formAction?: Maybe<FormActionType>;
  formActionContact_List?: Maybe<Array<FormActionContact>>;
  formActionEscalation?: Maybe<FormActionEscalationType>;
  formActionEscalationEvent?: Maybe<FormActionEscalationEventType>;
  formActionEscalationEvent_List?: Maybe<FormActionEscalationEventTypeListBase>;
  formActionEscalation_List?: Maybe<FormActionEscalationTypeListBase>;
  formActionSchedule?: Maybe<FormActionScheduleType>;
  formActionSchedule_List?: Maybe<FormActionScheduleTypeListBase>;
  formAction_List?: Maybe<FormActionTypeListBase>;
  formStatusMetric?: Maybe<FormStatusMetricType>;
  formStatusMetric_List?: Maybe<FormStatusMetricTypeListBase>;
  form_List?: Maybe<FormTypeListBase>;
  initialAnswer?: Maybe<InitialAnswerType>;
  initialAnswerPub?: Maybe<InitialAnswerPubType>;
  initialAnswerPub_List?: Maybe<InitialAnswerPubTypeListBase>;
  initialAnswer_List?: Maybe<InitialAnswerTypeListBase>;
  mergedPdf?: Maybe<MergedPdfType>;
  mergedPdf_List?: Maybe<MergedPdfTypeListBase>;
  module?: Maybe<ModuleType>;
  module_List?: Maybe<ModuleTypeListBase>;
  notificationsMetric?: Maybe<NotificationsMetricType>;
  notificationsMetric_List?: Maybe<NotificationsMetricTypeListBase>;
  organisation?: Maybe<OrganisationType>;
  organisationAdmin?: Maybe<AdminOrganisationType>;
  organisationAdmin_List?: Maybe<AdminOrganisationTypeListBase>;
  organisation_List?: Maybe<OrganisationTypeListBase>;
  pdf?: Maybe<PdfType>;
  pdfGenerator?: Maybe<PdfGeneratorType>;
  pdfGenerator_List?: Maybe<PdfGeneratorTypeListBase>;
  pdf_List?: Maybe<PdfTypeListBase>;
  publicForm?: Maybe<PublicFormType>;
  publicFormPub?: Maybe<PublicFormPubType>;
  publicFormPub_List?: Maybe<PublicFormPubTypeListBase>;
  publicForm_List?: Maybe<PublicFormTypeListBase>;
  publicRecordCreateLink?: Maybe<PublicRecordCreateLinkType>;
  quickLink?: Maybe<QuickLinkType>;
  quickLink_List?: Maybe<QuickLinkTypeListBase>;
  record?: Maybe<RecordType>;
  record_List?: Maybe<RecordTypeListBase>;
  schemaPublic?: Maybe<PublicSuiteType>;
  schemaPublic_List?: Maybe<PublicSuiteTypeListBase>;
  specificMetric?: Maybe<SpecificMetricType>;
  specificMetric_List?: Maybe<SpecificMetricTypeListBase>;
  tableField?: Maybe<TableFieldType>;
  tableField_List?: Maybe<TableFieldTypeListBase>;
  team?: Maybe<TeamType>;
  teamAdmin?: Maybe<AdminTeamType>;
  teamAdmin_List?: Maybe<AdminTeamTypeListBase>;
  team_List?: Maybe<TeamTypeListBase>;
  template?: Maybe<TemplateType>;
  template_List?: Maybe<TemplateTypeListBase>;
  user?: Maybe<UserType>;
  userTeam?: Maybe<UserTeamType>;
  userTeam_List?: Maybe<UserTeamTypeListBase>;
  user_List?: Maybe<UserTypeListBase>;
  webauthnCredentials?: Maybe<WebauthnCredentialsType>;
  webauthnCredentials_List?: Maybe<WebauthnCredentialsTypeListBase>;
};


export type PharosQueryActivityEventArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type PharosQueryActivityEvent_ListArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  module?: InputMaybe<Scalars['ID']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<ActivityEventTypeOrderingFilterObject>>>;
  record?: InputMaybe<Scalars['ID']['input']>;
  team?: InputMaybe<Scalars['ID']['input']>;
};


export type PharosQueryAdminOrgSwitchHistoryArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type PharosQueryAdminOrgSwitchHistory_ListArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<AdminOrgSwitchHistoryTypeOrderingFilterObject>>>;
};


export type PharosQueryAdviceSectionArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type PharosQueryAdviceSection_ListArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<AdviceSectionTypeOrderingFilterObject>>>;
};


export type PharosQueryAnswerBooleanArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type PharosQueryAnswerBooleanPubArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type PharosQueryAnswerBooleanPub_ListArgs = {
  content?: InputMaybe<Scalars['PharosDatetime']['input']>;
  content_Gte?: InputMaybe<Scalars['PharosDatetime']['input']>;
  content_Lte?: InputMaybe<Scalars['PharosDatetime']['input']>;
  deactivated_Isnull?: InputMaybe<Scalars['Boolean']['input']>;
  field_Title?: InputMaybe<Scalars['String']['input']>;
  filterFields?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  form?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  isAddressWithContent?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  module?: InputMaybe<Scalars['ID']['input']>;
  objectActive?: InputMaybe<Scalars['Boolean']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<AnswerBooleanPubTypeOrderingFilterObject>>>;
  relatedToRecord?: InputMaybe<Scalars['ID']['input']>;
  team?: InputMaybe<Scalars['ID']['input']>;
};


export type PharosQueryAnswerBoolean_ListArgs = {
  content?: InputMaybe<Scalars['PharosDatetime']['input']>;
  content_Gte?: InputMaybe<Scalars['PharosDatetime']['input']>;
  content_Lte?: InputMaybe<Scalars['PharosDatetime']['input']>;
  deactivated_Isnull?: InputMaybe<Scalars['Boolean']['input']>;
  field_Title?: InputMaybe<Scalars['String']['input']>;
  filterFields?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  form?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  isAddressWithContent?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  module?: InputMaybe<Scalars['ID']['input']>;
  objectActive?: InputMaybe<Scalars['Boolean']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<AnswerBooleanTypeOrderingFilterObject>>>;
  relatedToRecord?: InputMaybe<Scalars['ID']['input']>;
  team?: InputMaybe<Scalars['ID']['input']>;
};


export type PharosQueryAnswerDateArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type PharosQueryAnswerDatePubArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type PharosQueryAnswerDatePub_ListArgs = {
  content?: InputMaybe<Scalars['PharosDatetime']['input']>;
  content_Gte?: InputMaybe<Scalars['PharosDatetime']['input']>;
  content_Lte?: InputMaybe<Scalars['PharosDatetime']['input']>;
  deactivated_Isnull?: InputMaybe<Scalars['Boolean']['input']>;
  field_Title?: InputMaybe<Scalars['String']['input']>;
  filterFields?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  form?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  isAddressWithContent?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  module?: InputMaybe<Scalars['ID']['input']>;
  objectActive?: InputMaybe<Scalars['Boolean']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<AnswerDatePubTypeOrderingFilterObject>>>;
  relatedToRecord?: InputMaybe<Scalars['ID']['input']>;
  team?: InputMaybe<Scalars['ID']['input']>;
};


export type PharosQueryAnswerDate_ListArgs = {
  content?: InputMaybe<Scalars['PharosDatetime']['input']>;
  content_Gte?: InputMaybe<Scalars['PharosDatetime']['input']>;
  content_Lte?: InputMaybe<Scalars['PharosDatetime']['input']>;
  deactivated_Isnull?: InputMaybe<Scalars['Boolean']['input']>;
  field_Title?: InputMaybe<Scalars['String']['input']>;
  filterFields?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  form?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  isAddressWithContent?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  module?: InputMaybe<Scalars['ID']['input']>;
  objectActive?: InputMaybe<Scalars['Boolean']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<AnswerDateTypeOrderingFilterObject>>>;
  relatedToRecord?: InputMaybe<Scalars['ID']['input']>;
  team?: InputMaybe<Scalars['ID']['input']>;
};


export type PharosQueryAnswerDecimalArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type PharosQueryAnswerDecimalPubArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type PharosQueryAnswerDecimalPub_ListArgs = {
  content?: InputMaybe<Scalars['PharosDatetime']['input']>;
  content_Gte?: InputMaybe<Scalars['PharosDatetime']['input']>;
  content_Lte?: InputMaybe<Scalars['PharosDatetime']['input']>;
  deactivated_Isnull?: InputMaybe<Scalars['Boolean']['input']>;
  field_Title?: InputMaybe<Scalars['String']['input']>;
  filterFields?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  form?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  isAddressWithContent?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  module?: InputMaybe<Scalars['ID']['input']>;
  objectActive?: InputMaybe<Scalars['Boolean']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<AnswerDecimalPubTypeOrderingFilterObject>>>;
  relatedToRecord?: InputMaybe<Scalars['ID']['input']>;
  team?: InputMaybe<Scalars['ID']['input']>;
};


export type PharosQueryAnswerDecimal_ListArgs = {
  content?: InputMaybe<Scalars['PharosDatetime']['input']>;
  content_Gte?: InputMaybe<Scalars['PharosDatetime']['input']>;
  content_Lte?: InputMaybe<Scalars['PharosDatetime']['input']>;
  deactivated_Isnull?: InputMaybe<Scalars['Boolean']['input']>;
  field_Title?: InputMaybe<Scalars['String']['input']>;
  filterFields?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  form?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  isAddressWithContent?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  module?: InputMaybe<Scalars['ID']['input']>;
  objectActive?: InputMaybe<Scalars['Boolean']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<AnswerDecimalTypeOrderingFilterObject>>>;
  relatedToRecord?: InputMaybe<Scalars['ID']['input']>;
  team?: InputMaybe<Scalars['ID']['input']>;
};


export type PharosQueryAnswerFileArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type PharosQueryAnswerFilePubArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type PharosQueryAnswerFilePub_ListArgs = {
  content?: InputMaybe<Scalars['PharosDatetime']['input']>;
  content_Gte?: InputMaybe<Scalars['PharosDatetime']['input']>;
  content_Lte?: InputMaybe<Scalars['PharosDatetime']['input']>;
  deactivated_Isnull?: InputMaybe<Scalars['Boolean']['input']>;
  field_Title?: InputMaybe<Scalars['String']['input']>;
  filterFields?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  form?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  isAddressWithContent?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  module?: InputMaybe<Scalars['ID']['input']>;
  objectActive?: InputMaybe<Scalars['Boolean']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<AnswerFilePubTypeOrderingFilterObject>>>;
  relatedToRecord?: InputMaybe<Scalars['ID']['input']>;
  team?: InputMaybe<Scalars['ID']['input']>;
};


export type PharosQueryAnswerFile_ListArgs = {
  content?: InputMaybe<Scalars['PharosDatetime']['input']>;
  content_Gte?: InputMaybe<Scalars['PharosDatetime']['input']>;
  content_Lte?: InputMaybe<Scalars['PharosDatetime']['input']>;
  deactivated_Isnull?: InputMaybe<Scalars['Boolean']['input']>;
  field_Title?: InputMaybe<Scalars['String']['input']>;
  filterFields?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  form?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  isAddressWithContent?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  module?: InputMaybe<Scalars['ID']['input']>;
  objectActive?: InputMaybe<Scalars['Boolean']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<AnswerFileTypeOrderingFilterObject>>>;
  relatedToRecord?: InputMaybe<Scalars['ID']['input']>;
  team?: InputMaybe<Scalars['ID']['input']>;
};


export type PharosQueryAnswerTextArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type PharosQueryAnswerTextPubArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type PharosQueryAnswerTextPub_ListArgs = {
  content?: InputMaybe<Scalars['PharosDatetime']['input']>;
  content_Gte?: InputMaybe<Scalars['PharosDatetime']['input']>;
  content_Lte?: InputMaybe<Scalars['PharosDatetime']['input']>;
  deactivated_Isnull?: InputMaybe<Scalars['Boolean']['input']>;
  field_Title?: InputMaybe<Scalars['String']['input']>;
  filterFields?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  form?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  isAddressWithContent?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  module?: InputMaybe<Scalars['ID']['input']>;
  objectActive?: InputMaybe<Scalars['Boolean']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<AnswerTextPubTypeOrderingFilterObject>>>;
  relatedToRecord?: InputMaybe<Scalars['ID']['input']>;
  team?: InputMaybe<Scalars['ID']['input']>;
};


export type PharosQueryAnswerText_ListArgs = {
  content?: InputMaybe<Scalars['PharosDatetime']['input']>;
  content_Gte?: InputMaybe<Scalars['PharosDatetime']['input']>;
  content_Lte?: InputMaybe<Scalars['PharosDatetime']['input']>;
  deactivated_Isnull?: InputMaybe<Scalars['Boolean']['input']>;
  field_Title?: InputMaybe<Scalars['String']['input']>;
  filterFields?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  form?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  isAddressWithContent?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  module?: InputMaybe<Scalars['ID']['input']>;
  objectActive?: InputMaybe<Scalars['Boolean']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<AnswerTextTypeOrderingFilterObject>>>;
  relatedToRecord?: InputMaybe<Scalars['ID']['input']>;
  team?: InputMaybe<Scalars['ID']['input']>;
};


export type PharosQueryConcatenatedTableArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type PharosQueryConcatenatedTableDataArgs = {
  concatenatedTableId: Scalars['ID']['input'];
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  ordering?: InputMaybe<Array<InputMaybe<ConcatenatedTableOrdering>>>;
  search?: InputMaybe<Scalars['String']['input']>;
  teamId?: InputMaybe<Scalars['ID']['input']>;
};


export type PharosQueryConcatenatedTable_ListArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  module?: InputMaybe<Scalars['ID']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<ConcatenatedTableTypeOrderingFilterObject>>>;
};


export type PharosQueryEmailArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type PharosQueryEmail_ListArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<EmailTypeOrderingFilterObject>>>;
  regardingContentType?: InputMaybe<Scalars['ID']['input']>;
  regardingContentTypeString?: InputMaybe<Scalars['String']['input']>;
  regardingObjectId?: InputMaybe<Scalars['ID']['input']>;
};


export type PharosQueryFieldArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type PharosQueryFieldAggregationMetricArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type PharosQueryFieldAggregationMetric_ListArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  module?: InputMaybe<Scalars['ID']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<FieldAggregationMetricTypeOrderingFilterObject>>>;
  visibleToSuiteAdmin?: InputMaybe<Scalars['Boolean']['input']>;
};


export type PharosQueryFieldDateRangeMetricArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type PharosQueryFieldDateRangeMetric_ListArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  module?: InputMaybe<Scalars['ID']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<FieldDateRangeMetricTypeOrderingFilterObject>>>;
  visibleToSuiteAdmin?: InputMaybe<Scalars['Boolean']['input']>;
};


export type PharosQueryFieldGroupMetricArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type PharosQueryFieldGroupMetric_ListArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  module?: InputMaybe<Scalars['ID']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<FieldGroupMetricTypeOrderingFilterObject>>>;
  visibleToSuiteAdmin?: InputMaybe<Scalars['Boolean']['input']>;
};


export type PharosQueryFieldNotificationArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type PharosQueryFieldNotification_ListArgs = {
  delta?: InputMaybe<Scalars['Int']['input']>;
  fieldContentType?: InputMaybe<Scalars['String']['input']>;
  fieldId?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<FieldNotificationTypeOrderingFilterObject>>>;
  relevantToUser?: InputMaybe<Scalars['ID']['input']>;
};


export type PharosQueryField_ListArgs = {
  hasDate?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  inModule?: InputMaybe<Scalars['ID']['input']>;
  inTemplate?: InputMaybe<Scalars['ID']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  moveDateOnCopy?: InputMaybe<Scalars['Boolean']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<FieldTypeOrderingFilterObject>>>;
  title?: InputMaybe<Scalars['String']['input']>;
};


export type PharosQueryFormArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type PharosQueryFormActionArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type PharosQueryFormActionContact_ListArgs = {
  recordIds?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};


export type PharosQueryFormActionEscalationArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type PharosQueryFormActionEscalationEventArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type PharosQueryFormActionEscalationEvent_ListArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<FormActionEscalationEventTypeOrderingFilterObject>>>;
  record_In?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  yearMonth?: InputMaybe<Scalars['String']['input']>;
};


export type PharosQueryFormActionEscalation_ListArgs = {
  form?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<FormActionEscalationTypeOrderingFilterObject>>>;
  record?: InputMaybe<Scalars['ID']['input']>;
  recordIdFormIdJsonList?: InputMaybe<Scalars['String']['input']>;
  record_In?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};


export type PharosQueryFormActionScheduleArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type PharosQueryFormActionSchedule_ListArgs = {
  form?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<FormActionScheduleTypeOrderingFilterObject>>>;
  record?: InputMaybe<Scalars['ID']['input']>;
  recordIdFormIdJsonList?: InputMaybe<Scalars['String']['input']>;
  record_In?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  yearMonth?: InputMaybe<Scalars['String']['input']>;
};


export type PharosQueryFormAction_ListArgs = {
  deleted?: InputMaybe<Scalars['Boolean']['input']>;
  form?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<FormActionTypeOrderingFilterObject>>>;
  record?: InputMaybe<Scalars['ID']['input']>;
  record_In?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  reusableSignatures?: InputMaybe<Scalars['Boolean']['input']>;
  yearMonth?: InputMaybe<Scalars['String']['input']>;
};


export type PharosQueryFormStatusMetricArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type PharosQueryFormStatusMetric_ListArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  module?: InputMaybe<Scalars['ID']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<FormStatusMetricTypeOrderingFilterObject>>>;
  visibleToSuiteAdmin?: InputMaybe<Scalars['Boolean']['input']>;
};


export type PharosQueryForm_ListArgs = {
  actionsFormat_In?: InputMaybe<Array<InputMaybe<FormFormActionsFormatChoices>>>;
  id?: InputMaybe<Scalars['ID']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<FormTypeOrderingFilterObject>>>;
};


export type PharosQueryInitialAnswerArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type PharosQueryInitialAnswerPubArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type PharosQueryInitialAnswerPub_ListArgs = {
  form?: InputMaybe<Scalars['ID']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<InitialAnswerPubTypeOrderingFilterObject>>>;
  record?: InputMaybe<Scalars['ID']['input']>;
};


export type PharosQueryInitialAnswer_ListArgs = {
  form?: InputMaybe<Scalars['ID']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<InitialAnswerTypeOrderingFilterObject>>>;
  record?: InputMaybe<Scalars['ID']['input']>;
};


export type PharosQueryMergedPdfArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type PharosQueryMergedPdf_ListArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<MergedPdfTypeOrderingFilterObject>>>;
  recordId?: InputMaybe<Scalars['ID']['input']>;
};


export type PharosQueryModuleArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type PharosQueryModule_ListArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<ModuleTypeOrderingFilterObject>>>;
};


export type PharosQueryNotificationsMetricArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type PharosQueryNotificationsMetric_ListArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  module?: InputMaybe<Scalars['ID']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<NotificationsMetricTypeOrderingFilterObject>>>;
  visibleToSuiteAdmin?: InputMaybe<Scalars['Boolean']['input']>;
};


export type PharosQueryOrganisationArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type PharosQueryOrganisationAdminArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type PharosQueryOrganisationAdmin_ListArgs = {
  annotateCounts?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<AdminOrganisationTypeOrderingFilterObject>>>;
  search?: InputMaybe<Scalars['String']['input']>;
};


export type PharosQueryOrganisation_ListArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<OrganisationTypeOrderingFilterObject>>>;
};


export type PharosQueryPdfArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type PharosQueryPdfGeneratorArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type PharosQueryPdfGenerator_ListArgs = {
  formId?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<PdfGeneratorTypeOrderingFilterObject>>>;
};


export type PharosQueryPdf_ListArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<PdfTypeOrderingFilterObject>>>;
  recordId?: InputMaybe<Scalars['ID']['input']>;
};


export type PharosQueryPublicFormArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type PharosQueryPublicFormPubArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type PharosQueryPublicFormPub_ListArgs = {
  code?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<PublicFormPubTypeOrderingFilterObject>>>;
};


export type PharosQueryPublicForm_ListArgs = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  form?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  module?: InputMaybe<Scalars['ID']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<PublicFormTypeOrderingFilterObject>>>;
  record?: InputMaybe<Scalars['ID']['input']>;
  team?: InputMaybe<Scalars['ID']['input']>;
};


export type PharosQueryPublicRecordCreateLinkArgs = {
  linkCode?: InputMaybe<Scalars['String']['input']>;
  teamCode?: InputMaybe<Scalars['String']['input']>;
};


export type PharosQueryQuickLinkArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type PharosQueryQuickLink_ListArgs = {
  form?: InputMaybe<Scalars['ID']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<QuickLinkTypeOrderingFilterObject>>>;
};


export type PharosQueryRecordArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type PharosQueryRecord_ListArgs = {
  actionDashboardSearch?: InputMaybe<Scalars['String']['input']>;
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  hasActions?: InputMaybe<Scalars['Boolean']['input']>;
  hasEmailAnswer?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  module?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  name_Icontains?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<RecordTypeOrderingFilterObject>>>;
  position?: InputMaybe<Scalars['String']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  team?: InputMaybe<Scalars['ID']['input']>;
};


export type PharosQuerySchemaPublicArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type PharosQuerySchemaPublic_ListArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<PublicSuiteTypeOrderingFilterObject>>>;
};


export type PharosQuerySpecificMetricArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type PharosQuerySpecificMetric_ListArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  module?: InputMaybe<Scalars['ID']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<SpecificMetricTypeOrderingFilterObject>>>;
  visibleToSuiteAdmin?: InputMaybe<Scalars['Boolean']['input']>;
};


export type PharosQueryTableFieldArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type PharosQueryTableField_ListArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  inTemplate?: InputMaybe<Scalars['ID']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  moveDateOnCopy?: InputMaybe<Scalars['Boolean']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<TableFieldTypeOrderingFilterObject>>>;
};


export type PharosQueryTeamArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type PharosQueryTeamAdminArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type PharosQueryTeamAdmin_ListArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  name_Icontains?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<AdminTeamTypeOrderingFilterObject>>>;
  organisation?: InputMaybe<Scalars['ID']['input']>;
};


export type PharosQueryTeam_ListArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  id_Gt?: InputMaybe<Scalars['ID']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<TeamTypeOrderingFilterObject>>>;
};


export type PharosQueryTemplateArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type PharosQueryTemplate_ListArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  module?: InputMaybe<Scalars['ID']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<TemplateTypeOrderingFilterObject>>>;
  position?: InputMaybe<Scalars['String']['input']>;
  position_Icontains?: InputMaybe<Scalars['String']['input']>;
};


export type PharosQueryUserArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type PharosQueryUserTeamArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type PharosQueryUserTeam_ListArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<UserTeamTypeOrderingFilterObject>>>;
};


export type PharosQueryUser_ListArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  hasAccessToTeam?: InputMaybe<Scalars['ID']['input']>;
  isSelf?: InputMaybe<Scalars['Boolean']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<UserTypeOrderingFilterObject>>>;
  search?: InputMaybe<Scalars['String']['input']>;
  topLevelManager?: InputMaybe<Scalars['Boolean']['input']>;
};


export type PharosQueryWebauthnCredentialsArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type PharosQueryWebauthnCredentials_ListArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<WebauthnCredentialsTypeOrderingFilterObject>>>;
  user?: InputMaybe<Scalars['ID']['input']>;
};

export type PieChartSegmentType = {
  __typename?: 'PieChartSegmentType';
  color: Scalars['String']['output'];
  formLinks: Array<MetricFormLink>;
  name: Scalars['String']['output'];
};

export type PublicFormPubType = Node & {
  __typename?: 'PublicFormPubType';
  form: FormPubType;
  formAction?: Maybe<FormActionPubType>;
  /** The ID of the object */
  id: Scalars['ID']['output'];
  record: RecordPubType;
  requestEmailOnSubmit: Scalars['Boolean']['output'];
};

export type PublicFormPubTypeListBase = {
  __typename?: 'PublicFormPubTypeListBase';
  objects: Array<PublicFormPubType>;
  pageInfo: PageInfo;
};

/** An enumeration. */
export enum PublicFormPubTypeOrderingFilter {
  Id = 'id'
}

export type PublicFormPubTypeOrderingFilterObject = {
  direction?: InputMaybe<OrderingDirectionEnum>;
  field?: InputMaybe<PublicFormPubTypeOrderingFilter>;
  modifiers?: InputMaybe<Array<InputMaybe<OrderingModifierEnum>>>;
};

export type PublicFormType = Node & {
  __typename?: 'PublicFormType';
  active: Scalars['Boolean']['output'];
  code: Scalars['String']['output'];
  created: Scalars['String']['output'];
  createdBy?: Maybe<UserType>;
  form: FormType;
  /** The ID of the object */
  id: Scalars['ID']['output'];
  record: RecordType;
};

export type PublicFormTypeListBase = {
  __typename?: 'PublicFormTypeListBase';
  objects: Array<PublicFormType>;
  pageInfo: PageInfo;
};

/** An enumeration. */
export enum PublicFormTypeOrderingFilter {
  Created = 'created',
  Id = 'id'
}

export type PublicFormTypeOrderingFilterObject = {
  direction?: InputMaybe<OrderingDirectionEnum>;
  field?: InputMaybe<PublicFormTypeOrderingFilter>;
  modifiers?: InputMaybe<Array<InputMaybe<OrderingModifierEnum>>>;
};

export type PublicModuleType = Node & {
  __typename?: 'PublicModuleType';
  /** The ID of the object */
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type PublicRecordCreateChoiceType = Node & {
  __typename?: 'PublicRecordCreateChoiceType';
  form: FormType;
  /** The ID of the object */
  id: Scalars['ID']['output'];
  positionName: Scalars['String']['output'];
  template: TemplateType;
};

export type PublicRecordCreateLinkType = Node & {
  __typename?: 'PublicRecordCreateLinkType';
  choices: Array<PublicRecordCreateChoiceType>;
  code: Scalars['String']['output'];
  htmlMessage: Scalars['String']['output'];
  /** The ID of the object */
  id: Scalars['ID']['output'];
  /** defaults to "Name" */
  recordNamePlaceholder?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
};

export type PublicRecordCreateMutation = {
  __typename?: 'PublicRecordCreateMutation';
  publicFormCode?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type PublicSuiteModuleType = Node & {
  __typename?: 'PublicSuiteModuleType';
  createTeamOnSignup: Scalars['Boolean']['output'];
  /** The ID of the object */
  id: Scalars['ID']['output'];
  module: PublicModuleType;
  rank: Scalars['Int']['output'];
};

/** An enumeration. */
export enum PublicSuiteModuleType_Suite_Module_Set_Items_OrderingFilter {
  Id = 'id'
}

export type PublicSuiteModuleType_Suite_Module_Set_Items_OrderingFilterObject = {
  direction?: InputMaybe<OrderingDirectionEnum>;
  field?: InputMaybe<PublicSuiteModuleType_Suite_Module_Set_Items_OrderingFilter>;
  modifiers?: InputMaybe<Array<InputMaybe<OrderingModifierEnum>>>;
};

export type PublicSuiteType = Node & {
  __typename?: 'PublicSuiteType';
  /** The ID of the object */
  id: Scalars['ID']['output'];
  /** Overrides the default logo for the navbar */
  logo?: Maybe<Scalars['DjangoFileType']['output']>;
  moduleSetItems: Array<PublicSuiteModuleType>;
  name: Scalars['String']['output'];
  /** If true, this suite will be assigned on org signup */
  public: Scalars['Boolean']['output'];
  signupSubdomain: Scalars['String']['output'];
};


export type PublicSuiteTypeModuleSetItemsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<PublicSuiteModuleType_Suite_Module_Set_Items_OrderingFilterObject>>>;
};

export type PublicSuiteTypeListBase = {
  __typename?: 'PublicSuiteTypeListBase';
  objects: Array<PublicSuiteType>;
  pageInfo: PageInfo;
};

/** An enumeration. */
export enum PublicSuiteTypeOrderingFilter {
  Id = 'id',
  Name = 'name'
}

export type PublicSuiteTypeOrderingFilterObject = {
  direction?: InputMaybe<OrderingDirectionEnum>;
  field?: InputMaybe<PublicSuiteTypeOrderingFilter>;
  modifiers?: InputMaybe<Array<InputMaybe<OrderingModifierEnum>>>;
};

export type QuickLinkType = Node & {
  __typename?: 'QuickLinkType';
  /** The form where the quick links will be displayed */
  form: FormType;
  /** The ID of the object */
  id: Scalars['ID']['output'];
  /** The quick link will navigate to this form */
  linkToForm: FormType;
  module?: Maybe<ModuleType>;
  moduleNotActivated: Scalars['Boolean']['output'];
  records: Array<RecordType>;
  /** The title which displays in the UI */
  title: Scalars['String']['output'];
};

export type QuickLinkTypeListBase = {
  __typename?: 'QuickLinkTypeListBase';
  objects: Array<QuickLinkType>;
  pageInfo: PageInfo;
};

/** An enumeration. */
export enum QuickLinkTypeOrderingFilter {
  Id = 'id',
  TemplatePosition = 'template_Position'
}

export type QuickLinkTypeOrderingFilterObject = {
  direction?: InputMaybe<OrderingDirectionEnum>;
  field?: InputMaybe<QuickLinkTypeOrderingFilter>;
  modifiers?: InputMaybe<Array<InputMaybe<OrderingModifierEnum>>>;
};

export type RecordFormPair = {
  formId: Scalars['ID']['input'];
  recordId: Scalars['ID']['input'];
};

export type RecordPubType = Node & {
  __typename?: 'RecordPubType';
  archived: Scalars['Boolean']['output'];
  /** The ID of the object */
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  position: Scalars['String']['output'];
  team: TeamPubType;
};

export type RecordType = Node & {
  __typename?: 'RecordType';
  archived: Scalars['Boolean']['output'];
  archivedBy?: Maybe<UserType>;
  formActionEscalationEvents: Array<FormActionEscalationEventType>;
  formActionSchedules: Array<FormActionScheduleType>;
  formActions: Array<FormActionType>;
  /** The ID of the object */
  id: Scalars['ID']['output'];
  image?: Maybe<Scalars['DjangoFileType']['output']>;
  name: Scalars['String']['output'];
  position: Scalars['String']['output'];
  team: TeamType;
  template: TemplateType;
};


export type RecordTypeFormActionEscalationEventsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<FormActionEscalationEventType_Record_Form_Action_Escalation_Events_OrderingFilterObject>>>;
  record_In?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  yearMonth?: InputMaybe<Scalars['String']['input']>;
};


export type RecordTypeFormActionSchedulesArgs = {
  form?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<FormActionScheduleType_Record_Form_Action_Schedules_OrderingFilterObject>>>;
  record?: InputMaybe<Scalars['ID']['input']>;
  recordIdFormIdJsonList?: InputMaybe<Scalars['String']['input']>;
  record_In?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  yearMonth?: InputMaybe<Scalars['String']['input']>;
};


export type RecordTypeFormActionsArgs = {
  deleted?: InputMaybe<Scalars['Boolean']['input']>;
  form?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<FormActionType_Record_Form_Actions_OrderingFilterObject>>>;
  record?: InputMaybe<Scalars['ID']['input']>;
  record_In?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  reusableSignatures?: InputMaybe<Scalars['Boolean']['input']>;
  yearMonth?: InputMaybe<Scalars['String']['input']>;
};

export type RecordTypeListBase = {
  __typename?: 'RecordTypeListBase';
  objects: Array<RecordType>;
  pageInfo: PageInfo;
};

/** An enumeration. */
export enum RecordTypeOrderingFilter {
  Archived = 'archived',
  Id = 'id',
  Name = 'name',
  Position = 'position'
}

export type RecordTypeOrderingFilterObject = {
  direction?: InputMaybe<OrderingDirectionEnum>;
  field?: InputMaybe<RecordTypeOrderingFilter>;
  modifiers?: InputMaybe<Array<InputMaybe<OrderingModifierEnum>>>;
};

export type SelectOptionType = {
  __typename?: 'SelectOptionType';
  color?: Maybe<Scalars['String']['output']>;
  field: FieldType;
  id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
  order: Scalars['Int']['output'];
};

export type SignupOrgInput = {
  name: Scalars['String']['input'];
  suiteId?: InputMaybe<Scalars['ID']['input']>;
};

export type SignupUserInput = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  howYouHeardAboutUs: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type SpecificMetricType = Node & {
  __typename?: 'SpecificMetricType';
  /** The ID of the object */
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  order: Scalars['Int']['output'];
  result: ValueResultType;
};


export type SpecificMetricTypeResultArgs = {
  isSuiteAdminDashboard?: InputMaybe<Scalars['Boolean']['input']>;
  teamId?: InputMaybe<Scalars['ID']['input']>;
};

export type SpecificMetricTypeListBase = {
  __typename?: 'SpecificMetricTypeListBase';
  objects: Array<SpecificMetricType>;
  pageInfo: PageInfo;
};

/** An enumeration. */
export enum SpecificMetricTypeOrderingFilter {
  Id = 'id',
  Order = 'order'
}

export type SpecificMetricTypeOrderingFilterObject = {
  direction?: InputMaybe<OrderingDirectionEnum>;
  field?: InputMaybe<SpecificMetricTypeOrderingFilter>;
  modifiers?: InputMaybe<Array<InputMaybe<OrderingModifierEnum>>>;
};

export type SubmitPublicFormMutation = {
  __typename?: 'SubmitPublicFormMutation';
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type SuiteModuleType = Node & {
  __typename?: 'SuiteModuleType';
  /** If this module has no teams, it will not display anywhere (used for modules that only apply to a specific org) */
  hiddenWhenEmpty: Scalars['Boolean']['output'];
  /** The ID of the object */
  id: Scalars['ID']['output'];
  module: ModuleType;
  rank: Scalars['Int']['output'];
};

/** An enumeration. */
export enum SuiteModuleType_Suite_Module_Set_Items_OrderingFilter {
  Id = 'id',
  Rank = 'rank'
}

export type SuiteModuleType_Suite_Module_Set_Items_OrderingFilterObject = {
  direction?: InputMaybe<OrderingDirectionEnum>;
  field?: InputMaybe<SuiteModuleType_Suite_Module_Set_Items_OrderingFilter>;
  modifiers?: InputMaybe<Array<InputMaybe<OrderingModifierEnum>>>;
};

export type SuiteType = Node & {
  __typename?: 'SuiteType';
  adviceSection?: Maybe<AdviceSectionType>;
  /** The ID of the object */
  id: Scalars['ID']['output'];
  /** Overrides the default logo for the navbar */
  logo?: Maybe<Scalars['DjangoFileType']['output']>;
  moduleSetItems: Array<SuiteModuleType>;
  moduleWord: Scalars['String']['output'];
  /** The title of the suite admin dashboard (displayed to users with admin_of_suite set to True) */
  suiteAdminDashboardTitle?: Maybe<Scalars['String']['output']>;
  welcomeMessageHtml?: Maybe<Scalars['String']['output']>;
};


export type SuiteTypeModuleSetItemsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<SuiteModuleType_Suite_Module_Set_Items_OrderingFilterObject>>>;
};

export type SwitchOrgAdmin = {
  __typename?: 'SwitchOrgAdmin';
  user?: Maybe<UserType>;
};

export type TableFieldPubType = Node & {
  __typename?: 'TableFieldPubType';
  /** Disables editing of field values when using an external link */
  disableWhenPublic: Scalars['Boolean']['output'];
  /** Disables editing of field values form action signing is in progress */
  disableWhenSigning: Scalars['Boolean']['output'];
  disabled?: Maybe<Scalars['Boolean']['output']>;
  /**
   * Disables this field entirely. Useful if you want to freeze
   * all answers in their current state. Preference the use of
   * info blocks + custom PDF element over this field.
   */
  disabledAlways: Scalars['Boolean']['output'];
  field: FieldPubType;
  helpInfo?: Maybe<HelpInfoType>;
  /** The ID of the object */
  id: Scalars['ID']['output'];
  placeholder?: Maybe<Scalars['String']['output']>;
  /** The order this field appears in the form */
  rank: Scalars['Int']['output'];
  selectOptions: Array<SelectOptionType>;
  title: Scalars['String']['output'];
  type: FieldTableFieldTypeChoices;
};

/** An enumeration. */
export enum TableFieldPubType_Field_Table_Fields_OrderingFilter {
  Id = 'id',
  Rank = 'rank',
  Title = 'title'
}

export type TableFieldPubType_Field_Table_Fields_OrderingFilterObject = {
  direction?: InputMaybe<OrderingDirectionEnum>;
  field?: InputMaybe<TableFieldPubType_Field_Table_Fields_OrderingFilter>;
  modifiers?: InputMaybe<Array<InputMaybe<OrderingModifierEnum>>>;
};

export type TableFieldType = Node & {
  __typename?: 'TableFieldType';
  /** Disables editing of field values when using an external link */
  disableWhenPublic: Scalars['Boolean']['output'];
  /** Disables editing of field values form action signing is in progress */
  disableWhenSigning: Scalars['Boolean']['output'];
  disabled?: Maybe<Scalars['Boolean']['output']>;
  /**
   * Disables this field entirely. Useful if you want to freeze
   * all answers in their current state. Preference the use of
   * info blocks + custom PDF element over this field.
   */
  disabledAlways: Scalars['Boolean']['output'];
  field: FieldType;
  helpInfo?: Maybe<HelpInfoType>;
  /** The ID of the object */
  id: Scalars['ID']['output'];
  placeholder?: Maybe<Scalars['String']['output']>;
  /** The order this field appears in the form */
  rank: Scalars['Int']['output'];
  selectOptions: Array<SelectOptionType>;
  title: Scalars['String']['output'];
  type: FieldTableFieldTypeChoices;
};

export type TableFieldTypeListBase = {
  __typename?: 'TableFieldTypeListBase';
  objects: Array<TableFieldType>;
  pageInfo: PageInfo;
};

/** An enumeration. */
export enum TableFieldTypeOrderingFilter {
  Id = 'id',
  Rank = 'rank',
  Title = 'title'
}

export type TableFieldTypeOrderingFilterObject = {
  direction?: InputMaybe<OrderingDirectionEnum>;
  field?: InputMaybe<TableFieldTypeOrderingFilter>;
  modifiers?: InputMaybe<Array<InputMaybe<OrderingModifierEnum>>>;
};

/** An enumeration. */
export enum TableFieldType_Field_Table_Fields_OrderingFilter {
  Id = 'id',
  Rank = 'rank',
  Title = 'title'
}

export type TableFieldType_Field_Table_Fields_OrderingFilterObject = {
  direction?: InputMaybe<OrderingDirectionEnum>;
  field?: InputMaybe<TableFieldType_Field_Table_Fields_OrderingFilter>;
  modifiers?: InputMaybe<Array<InputMaybe<OrderingModifierEnum>>>;
};

export type TeamPubType = Node & {
  __typename?: 'TeamPubType';
  /** The ID of the object */
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  organisation: OrganisationPubType;
};

export type TeamType = Node & {
  __typename?: 'TeamType';
  active: Scalars['Boolean']['output'];
  /** The ID of the object */
  id: Scalars['ID']['output'];
  logo?: Maybe<Scalars['DjangoFileType']['output']>;
  memberId?: Maybe<Scalars['ID']['output']>;
  module: ModuleType;
  name: Scalars['String']['output'];
  organisation: OrganisationType;
  publicRecordCreateCode: Scalars['String']['output'];
  reminderFrequencyDays: Scalars['Int']['output'];
  users: Array<UserType>;
};


export type TeamTypeMemberIdArgs = {
  userId?: InputMaybe<Scalars['ID']['input']>;
};


export type TeamTypeUsersArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  hasAccessToTeam?: InputMaybe<Scalars['ID']['input']>;
  isSelf?: InputMaybe<Scalars['Boolean']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<UserType_Team_Users_OrderingFilterObject>>>;
  search?: InputMaybe<Scalars['String']['input']>;
  topLevelManager?: InputMaybe<Scalars['Boolean']['input']>;
};

export type TeamTypeListBase = {
  __typename?: 'TeamTypeListBase';
  objects: Array<TeamType>;
  pageInfo: PageInfo;
};

/** An enumeration. */
export enum TeamTypeOrderingFilter {
  Id = 'id',
  ModuleName = 'module_Name',
  Name = 'name'
}

export type TeamTypeOrderingFilterObject = {
  direction?: InputMaybe<OrderingDirectionEnum>;
  field?: InputMaybe<TeamTypeOrderingFilter>;
  modifiers?: InputMaybe<Array<InputMaybe<OrderingModifierEnum>>>;
};

/** An enumeration. */
export enum TeamType_Module_Teams_OrderingFilter {
  Id = 'id',
  ModuleName = 'module_Name',
  Name = 'name'
}

export type TeamType_Module_Teams_OrderingFilterObject = {
  direction?: InputMaybe<OrderingDirectionEnum>;
  field?: InputMaybe<TeamType_Module_Teams_OrderingFilter>;
  modifiers?: InputMaybe<Array<InputMaybe<OrderingModifierEnum>>>;
};

/** An enumeration. */
export enum TeamType_Organisation_Teams_OrderingFilter {
  Id = 'id',
  ModuleName = 'module_Name',
  Name = 'name'
}

export type TeamType_Organisation_Teams_OrderingFilterObject = {
  direction?: InputMaybe<OrderingDirectionEnum>;
  field?: InputMaybe<TeamType_Organisation_Teams_OrderingFilter>;
  modifiers?: InputMaybe<Array<InputMaybe<OrderingModifierEnum>>>;
};

/** An enumeration. */
export enum TeamType_User_Teams_OrderingFilter {
  Id = 'id',
  ModuleName = 'module_Name',
  Name = 'name'
}

export type TeamType_User_Teams_OrderingFilterObject = {
  direction?: InputMaybe<OrderingDirectionEnum>;
  field?: InputMaybe<TeamType_User_Teams_OrderingFilter>;
  modifiers?: InputMaybe<Array<InputMaybe<OrderingModifierEnum>>>;
};

export type TemplateType = Node & {
  __typename?: 'TemplateType';
  copyPositionText: Scalars['Boolean']['output'];
  formSet: FormSetType;
  /** The ID of the object */
  id: Scalars['ID']['output'];
  modules: Array<ModuleType>;
  namePlaceholder?: Maybe<Scalars['String']['output']>;
  position: Scalars['String']['output'];
};


export type TemplateTypeModulesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<ModuleType_Template_Modules_OrderingFilterObject>>>;
};

export type TemplateTypeListBase = {
  __typename?: 'TemplateTypeListBase';
  objects: Array<TemplateType>;
  pageInfo: PageInfo;
};

/** An enumeration. */
export enum TemplateTypeOrderingFilter {
  CopyPositionText = 'copyPositionText',
  Id = 'id',
  Position = 'position'
}

export type TemplateTypeOrderingFilterObject = {
  direction?: InputMaybe<OrderingDirectionEnum>;
  field?: InputMaybe<TemplateTypeOrderingFilter>;
  modifiers?: InputMaybe<Array<InputMaybe<OrderingModifierEnum>>>;
};

/** An enumeration. */
export enum TemplateType_Module_Templates_OrderingFilter {
  CopyPositionText = 'copyPositionText',
  Id = 'id',
  Position = 'position'
}

export type TemplateType_Module_Templates_OrderingFilterObject = {
  direction?: InputMaybe<OrderingDirectionEnum>;
  field?: InputMaybe<TemplateType_Module_Templates_OrderingFilter>;
  modifiers?: InputMaybe<Array<InputMaybe<OrderingModifierEnum>>>;
};

/** An enumeration. */
export enum TitleSizeEnum {
  /** Large */
  Large = 'LARGE',
  /** Medium */
  Medium = 'MEDIUM'
}

export type UserSignupMutation = {
  __typename?: 'UserSignupMutation';
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  user?: Maybe<UserType>;
};

export type UserTeamType = Node & {
  __typename?: 'UserTeamType';
  active: Scalars['Boolean']['output'];
  /** The ID of the object */
  id: Scalars['ID']['output'];
  team: TeamType;
  user: UserType;
};

export type UserTeamTypeListBase = {
  __typename?: 'UserTeamTypeListBase';
  objects: Array<UserTeamType>;
  pageInfo: PageInfo;
};

/** An enumeration. */
export enum UserTeamTypeOrderingFilter {
  Id = 'id'
}

export type UserTeamTypeOrderingFilterObject = {
  direction?: InputMaybe<OrderingDirectionEnum>;
  field?: InputMaybe<UserTeamTypeOrderingFilter>;
  modifiers?: InputMaybe<Array<InputMaybe<OrderingModifierEnum>>>;
};

export type UserType = Node & {
  __typename?: 'UserType';
  /** Gives oversite to EVERY organisation within the suite via the suite admin dashboard */
  adminOfSuite: Scalars['Boolean']['output'];
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  howYouHeardAboutUs?: Maybe<Scalars['String']['output']>;
  /** The ID of the object */
  id: Scalars['ID']['output'];
  isActive: Scalars['Boolean']['output'];
  /** Is internal pharos user, ie dean + dave */
  isAdmin: Scalars['Boolean']['output'];
  lastName: Scalars['String']['output'];
  organisation: OrganisationType;
  recordCount: Scalars['Int']['output'];
  seenWelcomeMessage: Scalars['Boolean']['output'];
  teams: Array<TeamType>;
  /** Gives access to all teams within the organisation + user management */
  topLevelManager: Scalars['Boolean']['output'];
  /** This is set when they have been invited. If set, on first login they should be asked for a new password. */
  usingTemporaryPassword: Scalars['Boolean']['output'];
  webauthnCredentials: Array<WebauthnCredentialsType>;
};


export type UserTypeTeamsArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
  id_Gt?: InputMaybe<Scalars['ID']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<TeamType_User_Teams_OrderingFilterObject>>>;
};


export type UserTypeWebauthnCredentialsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<InputMaybe<WebauthnCredentialsType_User_Webauthn_Credentials_OrderingFilterObject>>>;
  user?: InputMaybe<Scalars['ID']['input']>;
};

export type UserTypeListBase = {
  __typename?: 'UserTypeListBase';
  objects: Array<UserType>;
  pageInfo: PageInfo;
};

/** An enumeration. */
export enum UserTypeOrderingFilter {
  FirstName = 'firstName',
  Id = 'id',
  LastName = 'lastName'
}

export type UserTypeOrderingFilterObject = {
  direction?: InputMaybe<OrderingDirectionEnum>;
  field?: InputMaybe<UserTypeOrderingFilter>;
  modifiers?: InputMaybe<Array<InputMaybe<OrderingModifierEnum>>>;
};

/** An enumeration. */
export enum UserType_Team_Users_OrderingFilter {
  FirstName = 'firstName',
  Id = 'id',
  LastName = 'lastName'
}

export type UserType_Team_Users_OrderingFilterObject = {
  direction?: InputMaybe<OrderingDirectionEnum>;
  field?: InputMaybe<UserType_Team_Users_OrderingFilter>;
  modifiers?: InputMaybe<Array<InputMaybe<OrderingModifierEnum>>>;
};

export type ValueResultType = {
  __typename?: 'ValueResultType';
  formLink?: Maybe<MetricFormLink>;
  value?: Maybe<Scalars['String']['output']>;
};

export type WebauthnAdminResetForUser = {
  __typename?: 'WebauthnAdminResetForUser';
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type WebauthnAuthenticate = {
  __typename?: 'WebauthnAuthenticate';
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type WebauthnBeginRegistration = {
  __typename?: 'WebauthnBeginRegistration';
  optionsString?: Maybe<Scalars['String']['output']>;
};

export type WebauthnCompleteRegistration = {
  __typename?: 'WebauthnCompleteRegistration';
  errorText?: Maybe<Scalars['String']['output']>;
};

export type WebauthnCredentialsType = Node & {
  __typename?: 'WebauthnCredentialsType';
  active: Scalars['Boolean']['output'];
  created: Scalars['String']['output'];
  /** The user supplied name for this credential */
  displayName: Scalars['String']['output'];
  /** The ID of the object */
  id: Scalars['ID']['output'];
  /** When this credential was last used to authenticate */
  lastUsed?: Maybe<Scalars['String']['output']>;
};

export type WebauthnCredentialsTypeListBase = {
  __typename?: 'WebauthnCredentialsTypeListBase';
  objects: Array<WebauthnCredentialsType>;
  pageInfo: PageInfo;
};

/** An enumeration. */
export enum WebauthnCredentialsTypeOrderingFilter {
  Id = 'id',
  LastUsed = 'lastUsed'
}

export type WebauthnCredentialsTypeOrderingFilterObject = {
  direction?: InputMaybe<OrderingDirectionEnum>;
  field?: InputMaybe<WebauthnCredentialsTypeOrderingFilter>;
  modifiers?: InputMaybe<Array<InputMaybe<OrderingModifierEnum>>>;
};

/** An enumeration. */
export enum WebauthnCredentialsType_User_Webauthn_Credentials_OrderingFilter {
  Id = 'id',
  LastUsed = 'lastUsed'
}

export type WebauthnCredentialsType_User_Webauthn_Credentials_OrderingFilterObject = {
  direction?: InputMaybe<OrderingDirectionEnum>;
  field?: InputMaybe<WebauthnCredentialsType_User_Webauthn_Credentials_OrderingFilter>;
  modifiers?: InputMaybe<Array<InputMaybe<OrderingModifierEnum>>>;
};

export type Answer_BooleanInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  objects: Array<AnswerBooleanBulkObjectInput>;
};

export type Answer_BooleanPayload = {
  __typename?: 'answer_booleanPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** May contain more than one error for same field. */
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  object?: Maybe<AnswerBooleanType>;
  objects?: Maybe<Array<AnswerBooleanType>>;
};

export type Answer_Boolean_PubInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  objects: Array<AnswerBooleanPubBulkObjectInput>;
};

export type Answer_Boolean_PubPayload = {
  __typename?: 'answer_boolean_pubPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** May contain more than one error for same field. */
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  object?: Maybe<AnswerBooleanPubType>;
  objects?: Maybe<Array<AnswerBooleanPubType>>;
};

export type Answer_DateInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  objects: Array<AnswerDateBulkObjectInput>;
};

export type Answer_DatePayload = {
  __typename?: 'answer_datePayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** May contain more than one error for same field. */
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  object?: Maybe<AnswerDateType>;
  objects?: Maybe<Array<AnswerDateType>>;
};

export type Answer_Date_PubInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  objects: Array<AnswerDatePubBulkObjectInput>;
};

export type Answer_Date_PubPayload = {
  __typename?: 'answer_date_pubPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** May contain more than one error for same field. */
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  object?: Maybe<AnswerDatePubType>;
  objects?: Maybe<Array<AnswerDatePubType>>;
};

export type Answer_DecimalInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  objects: Array<AnswerDecimalBulkObjectInput>;
};

export type Answer_DecimalPayload = {
  __typename?: 'answer_decimalPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** May contain more than one error for same field. */
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  object?: Maybe<AnswerDecimalType>;
  objects?: Maybe<Array<AnswerDecimalType>>;
};

export type Answer_Decimal_PubInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  objects: Array<AnswerDecimalPubBulkObjectInput>;
};

export type Answer_Decimal_PubPayload = {
  __typename?: 'answer_decimal_pubPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** May contain more than one error for same field. */
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  object?: Maybe<AnswerDecimalPubType>;
  objects?: Maybe<Array<AnswerDecimalPubType>>;
};

export type Answer_FileInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  objects: Array<AnswerFileBulkObjectInput>;
};

export type Answer_FilePayload = {
  __typename?: 'answer_filePayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** May contain more than one error for same field. */
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  object?: Maybe<AnswerFileType>;
  objects?: Maybe<Array<AnswerFileType>>;
};

export type Answer_File_PubInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  objects: Array<AnswerFilePubBulkObjectInput>;
};

export type Answer_File_PubPayload = {
  __typename?: 'answer_file_pubPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** May contain more than one error for same field. */
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  object?: Maybe<AnswerFilePubType>;
  objects?: Maybe<Array<AnswerFilePubType>>;
};

export type Answer_TextInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  objects: Array<AnswerTextBulkObjectInput>;
};

export type Answer_TextPayload = {
  __typename?: 'answer_textPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** May contain more than one error for same field. */
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  object?: Maybe<AnswerTextType>;
  objects?: Maybe<Array<AnswerTextType>>;
};

export type Answer_Text_PubInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  objects: Array<AnswerTextPubBulkObjectInput>;
};

export type Answer_Text_PubPayload = {
  __typename?: 'answer_text_pubPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** May contain more than one error for same field. */
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  object?: Maybe<AnswerTextPubType>;
  objects?: Maybe<Array<AnswerTextPubType>>;
};

export type EmailInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  createdBy?: InputMaybe<Scalars['ID']['input']>;
  excludeFromAddressBook?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  regardingContentType?: InputMaybe<Scalars['String']['input']>;
  regardingObjectId?: InputMaybe<Scalars['ID']['input']>;
  sendToContentType?: InputMaybe<Scalars['String']['input']>;
  sendToEmail?: InputMaybe<Scalars['String']['input']>;
  sendToObjectId?: InputMaybe<Scalars['ID']['input']>;
  typeString?: InputMaybe<Scalars['String']['input']>;
  /** A message supplied by the creator that will appear in the email */
  userSuppliedContext?: InputMaybe<Scalars['String']['input']>;
};

export type EmailPayload = {
  __typename?: 'emailPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  created?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<Scalars['ID']['output']>;
  /** May contain more than one error for same field. */
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  excludeFromAddressBook?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  object?: Maybe<EmailType>;
  regardingContentType?: Maybe<Scalars['String']['output']>;
  regardingObjectId?: Maybe<Scalars['ID']['output']>;
  sendToContentType?: Maybe<Scalars['String']['output']>;
  sendToEmail?: Maybe<Scalars['String']['output']>;
  sendToObjectId?: Maybe<Scalars['ID']['output']>;
  typeString?: Maybe<Scalars['String']['output']>;
  /** A message supplied by the creator that will appear in the email */
  userSuppliedContext?: Maybe<Scalars['String']['output']>;
};

export type Field_NotificationInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** (-3) means notification goes out 3 days BEFORE the entered date */
  delta?: InputMaybe<Scalars['Int']['input']>;
  fieldContentType?: InputMaybe<Scalars['String']['input']>;
  fieldId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  /** If false send to specifc notification users. If true, send to all users with access */
  sendToOrganisation?: InputMaybe<Scalars['Boolean']['input']>;
};

export type Field_NotificationPayload = {
  __typename?: 'field_notificationPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** (-3) means notification goes out 3 days BEFORE the entered date */
  delta?: Maybe<Scalars['Int']['output']>;
  /** May contain more than one error for same field. */
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  fieldContentType?: Maybe<Scalars['String']['output']>;
  fieldId?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  object?: Maybe<FieldNotificationType>;
  /** If false send to specifc notification users. If true, send to all users with access */
  sendToOrganisation?: Maybe<Scalars['Boolean']['output']>;
};

export type Field_Notification_UserInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  fieldNotification?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  user?: InputMaybe<Scalars['ID']['input']>;
};

export type Field_Notification_UserPayload = {
  __typename?: 'field_notification_userPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** May contain more than one error for same field. */
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  fieldNotification?: Maybe<Scalars['ID']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  object?: Maybe<FieldNotificationUserType>;
  user?: Maybe<Scalars['ID']['output']>;
};

export type Form_ActionInput = {
  /** If a custom email is specified. Assigned anon email answers will not use this field. */
  assignedAnonEmail?: InputMaybe<Scalars['String']['input']>;
  assignedAnonEmailAnswer?: InputMaybe<Scalars['ID']['input']>;
  /** If a custom name is specified. Assigned anon email answers will not use this field. */
  assignedAnonName?: InputMaybe<Scalars['String']['input']>;
  assignedUser?: InputMaybe<Scalars['ID']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  completedOn?: InputMaybe<Scalars['DateTime']['input']>;
  deleted?: InputMaybe<Scalars['Boolean']['input']>;
  form?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** This stores form.actions_format on a per-action basis to differentiate minor behaviors. */
  originFormat?: InputMaybe<Origin_Format>;
  priority?: InputMaybe<Scalars['Int']['input']>;
  record?: InputMaybe<Scalars['ID']['input']>;
  requestSignature?: InputMaybe<Scalars['Boolean']['input']>;
  reuseSignatureFormActionId?: InputMaybe<Scalars['ID']['input']>;
  signatureImage?: InputMaybe<Scalars['Upload']['input']>;
  /** Note to display next to signature in pdf, e.g Director */
  signatureNote?: InputMaybe<Scalars['String']['input']>;
  /** Text used to generate signature from font */
  signatureText?: InputMaybe<Scalars['String']['input']>;
  /** A message supplied by the creator that will appear in the email */
  userSuppliedContext?: InputMaybe<Scalars['String']['input']>;
};

export type Form_ActionPayload = {
  __typename?: 'form_actionPayload';
  /** If a custom email is specified. Assigned anon email answers will not use this field. */
  assignedAnonEmail?: Maybe<Scalars['String']['output']>;
  assignedAnonEmailAnswer?: Maybe<Scalars['ID']['output']>;
  /** If a custom name is specified. Assigned anon email answers will not use this field. */
  assignedAnonName?: Maybe<Scalars['String']['output']>;
  assignedUser?: Maybe<Scalars['ID']['output']>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  completedOn?: Maybe<Scalars['DateTime']['output']>;
  deleted?: Maybe<Scalars['Boolean']['output']>;
  /** May contain more than one error for same field. */
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  form?: Maybe<Scalars['ID']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  object?: Maybe<FormActionType>;
  /** This stores form.actions_format on a per-action basis to differentiate minor behaviors. */
  originFormat?: Maybe<Origin_Format>;
  priority?: Maybe<Scalars['Int']['output']>;
  record?: Maybe<Scalars['ID']['output']>;
  requestSignature?: Maybe<Scalars['Boolean']['output']>;
  /** Note to display next to signature in pdf, e.g Director */
  signatureNote?: Maybe<Scalars['String']['output']>;
  /** Text used to generate signature from font */
  signatureText?: Maybe<Scalars['String']['output']>;
  /** A message supplied by the creator that will appear in the email */
  userSuppliedContext?: Maybe<Scalars['String']['output']>;
};

export type Form_Action_BulkBulkInput = {
  /** If a custom email is specified. Assigned anon email answers will not use this field. */
  assignedAnonEmail?: InputMaybe<Scalars['String']['input']>;
  assignedAnonEmailAnswer?: InputMaybe<Scalars['ID']['input']>;
  /** If a custom name is specified. Assigned anon email answers will not use this field. */
  assignedAnonName?: InputMaybe<Scalars['String']['input']>;
  assignedUser?: InputMaybe<Scalars['ID']['input']>;
  completedOn?: InputMaybe<Scalars['DateTime']['input']>;
  deleted?: InputMaybe<Scalars['Boolean']['input']>;
  form?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** This stores form.actions_format on a per-action basis to differentiate minor behaviors. */
  originFormat?: InputMaybe<Scalars['String']['input']>;
  priority?: InputMaybe<Scalars['Int']['input']>;
  record?: InputMaybe<Scalars['ID']['input']>;
  requestSignature?: InputMaybe<Scalars['Boolean']['input']>;
  reuseSignatureFormActionId?: InputMaybe<Scalars['ID']['input']>;
  signatureImage?: InputMaybe<Scalars['Upload']['input']>;
  /** Note to display next to signature in pdf, e.g Director */
  signatureNote?: InputMaybe<Scalars['String']['input']>;
  /** Text used to generate signature from font */
  signatureText?: InputMaybe<Scalars['String']['input']>;
  /** A message supplied by the creator that will appear in the email */
  userSuppliedContext?: InputMaybe<Scalars['String']['input']>;
};

export type Form_Action_BulkInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  objects?: InputMaybe<Array<InputMaybe<Form_Action_BulkBulkInput>>>;
};

export type Form_Action_BulkPayload = {
  __typename?: 'form_action_bulkPayload';
  /** If a custom email is specified. Assigned anon email answers will not use this field. */
  assignedAnonEmail?: Maybe<Scalars['String']['output']>;
  assignedAnonEmailAnswer?: Maybe<Scalars['ID']['output']>;
  /** If a custom name is specified. Assigned anon email answers will not use this field. */
  assignedAnonName?: Maybe<Scalars['String']['output']>;
  assignedUser?: Maybe<Scalars['ID']['output']>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  completedOn?: Maybe<Scalars['DateTime']['output']>;
  deleted?: Maybe<Scalars['Boolean']['output']>;
  /** May contain more than one error for same field. */
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  form?: Maybe<Scalars['ID']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  objects?: Maybe<Array<FormActionType>>;
  /** This stores form.actions_format on a per-action basis to differentiate minor behaviors. */
  originFormat?: Maybe<Scalars['String']['output']>;
  priority?: Maybe<Scalars['Int']['output']>;
  record?: Maybe<Scalars['ID']['output']>;
  requestSignature?: Maybe<Scalars['Boolean']['output']>;
  /** Note to display next to signature in pdf, e.g Director */
  signatureNote?: Maybe<Scalars['String']['output']>;
  /** Text used to generate signature from font */
  signatureText?: Maybe<Scalars['String']['output']>;
  /** A message supplied by the creator that will appear in the email */
  userSuppliedContext?: Maybe<Scalars['String']['output']>;
};

export type Form_Action_EscalationInput = {
  /** If a custom email is specified. Assigned anon email answers will not use this field. */
  assignedAnonEmail?: InputMaybe<Scalars['String']['input']>;
  assignedAnonEmailAnswer?: InputMaybe<Scalars['ID']['input']>;
  /** If a custom name is specified. Assigned anon email answers will not use this field. */
  assignedAnonName?: InputMaybe<Scalars['String']['input']>;
  assignedUser?: InputMaybe<Scalars['ID']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  deleted?: InputMaybe<Scalars['Boolean']['input']>;
  deltaDays?: InputMaybe<Scalars['Int']['input']>;
  form?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  overwriteExisting?: InputMaybe<Scalars['Boolean']['input']>;
  record?: InputMaybe<Scalars['ID']['input']>;
};

export type Form_Action_EscalationPayload = {
  __typename?: 'form_action_escalationPayload';
  /** If a custom email is specified. Assigned anon email answers will not use this field. */
  assignedAnonEmail?: Maybe<Scalars['String']['output']>;
  assignedAnonEmailAnswer?: Maybe<Scalars['ID']['output']>;
  /** If a custom name is specified. Assigned anon email answers will not use this field. */
  assignedAnonName?: Maybe<Scalars['String']['output']>;
  assignedUser?: Maybe<Scalars['ID']['output']>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  deleted?: Maybe<Scalars['Boolean']['output']>;
  deltaDays?: Maybe<Scalars['Int']['output']>;
  /** May contain more than one error for same field. */
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  form?: Maybe<Scalars['ID']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  object?: Maybe<FormActionEscalationType>;
  record?: Maybe<Scalars['ID']['output']>;
};

export type Form_Action_Escalation_BulkBulkInput = {
  /** If a custom email is specified. Assigned anon email answers will not use this field. */
  assignedAnonEmail?: InputMaybe<Scalars['String']['input']>;
  assignedAnonEmailAnswer?: InputMaybe<Scalars['ID']['input']>;
  /** If a custom name is specified. Assigned anon email answers will not use this field. */
  assignedAnonName?: InputMaybe<Scalars['String']['input']>;
  assignedUser?: InputMaybe<Scalars['ID']['input']>;
  deleted?: InputMaybe<Scalars['Boolean']['input']>;
  deltaDays?: InputMaybe<Scalars['Int']['input']>;
  form?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  overwriteExisting?: InputMaybe<Scalars['Boolean']['input']>;
  record?: InputMaybe<Scalars['ID']['input']>;
};

export type Form_Action_Escalation_BulkInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  objects?: InputMaybe<Array<InputMaybe<Form_Action_Escalation_BulkBulkInput>>>;
};

export type Form_Action_Escalation_BulkPayload = {
  __typename?: 'form_action_escalation_bulkPayload';
  /** If a custom email is specified. Assigned anon email answers will not use this field. */
  assignedAnonEmail?: Maybe<Scalars['String']['output']>;
  assignedAnonEmailAnswer?: Maybe<Scalars['ID']['output']>;
  /** If a custom name is specified. Assigned anon email answers will not use this field. */
  assignedAnonName?: Maybe<Scalars['String']['output']>;
  assignedUser?: Maybe<Scalars['ID']['output']>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  deleted?: Maybe<Scalars['Boolean']['output']>;
  deltaDays?: Maybe<Scalars['Int']['output']>;
  /** May contain more than one error for same field. */
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  form?: Maybe<Scalars['ID']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  objects?: Maybe<Array<FormActionEscalationType>>;
  record?: Maybe<Scalars['ID']['output']>;
};

export type Form_Action_PubInput = {
  /** If a custom email is specified. Assigned anon email answers will not use this field. */
  assignedAnonEmail?: InputMaybe<Scalars['String']['input']>;
  assignedAnonEmailAnswer?: InputMaybe<Scalars['ID']['input']>;
  /** If a custom name is specified. Assigned anon email answers will not use this field. */
  assignedAnonName?: InputMaybe<Scalars['String']['input']>;
  assignedUser?: InputMaybe<Scalars['ID']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  completedOn?: InputMaybe<Scalars['DateTime']['input']>;
  deleted?: InputMaybe<Scalars['Boolean']['input']>;
  form?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /** This stores form.actions_format on a per-action basis to differentiate minor behaviors. */
  originFormat?: InputMaybe<Origin_Format>;
  priority?: InputMaybe<Scalars['Int']['input']>;
  record?: InputMaybe<Scalars['ID']['input']>;
  requestSignature?: InputMaybe<Scalars['Boolean']['input']>;
  reuseSignatureFormActionId?: InputMaybe<Scalars['ID']['input']>;
  signatureImage?: InputMaybe<Scalars['Upload']['input']>;
  /** Note to display next to signature in pdf, e.g Director */
  signatureNote?: InputMaybe<Scalars['String']['input']>;
  /** Text used to generate signature from font */
  signatureText?: InputMaybe<Scalars['String']['input']>;
  /** A message supplied by the creator that will appear in the email */
  userSuppliedContext?: InputMaybe<Scalars['String']['input']>;
};

export type Form_Action_PubPayload = {
  __typename?: 'form_action_pubPayload';
  /** If a custom email is specified. Assigned anon email answers will not use this field. */
  assignedAnonEmail?: Maybe<Scalars['String']['output']>;
  assignedAnonEmailAnswer?: Maybe<Scalars['ID']['output']>;
  /** If a custom name is specified. Assigned anon email answers will not use this field. */
  assignedAnonName?: Maybe<Scalars['String']['output']>;
  assignedUser?: Maybe<Scalars['ID']['output']>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  completedOn?: Maybe<Scalars['DateTime']['output']>;
  deleted?: Maybe<Scalars['Boolean']['output']>;
  /** May contain more than one error for same field. */
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  form?: Maybe<Scalars['ID']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  object?: Maybe<FormActionPubType>;
  /** This stores form.actions_format on a per-action basis to differentiate minor behaviors. */
  originFormat?: Maybe<Origin_Format>;
  priority?: Maybe<Scalars['Int']['output']>;
  record?: Maybe<Scalars['ID']['output']>;
  requestSignature?: Maybe<Scalars['Boolean']['output']>;
  /** Note to display next to signature in pdf, e.g Director */
  signatureNote?: Maybe<Scalars['String']['output']>;
  /** Text used to generate signature from font */
  signatureText?: Maybe<Scalars['String']['output']>;
  /** A message supplied by the creator that will appear in the email */
  userSuppliedContext?: Maybe<Scalars['String']['output']>;
};

export type Form_Action_ScheduleInput = {
  /** If a custom email is specified. Assigned anon email answers will not use this field. */
  assignedAnonEmail?: InputMaybe<Scalars['String']['input']>;
  assignedAnonEmailAnswer?: InputMaybe<Scalars['ID']['input']>;
  /** If a custom name is specified. Assigned anon email answers will not use this field. */
  assignedAnonName?: InputMaybe<Scalars['String']['input']>;
  assignedUser?: InputMaybe<Scalars['ID']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  deleted?: InputMaybe<Scalars['Boolean']['input']>;
  dueAt?: InputMaybe<Scalars['DateTime']['input']>;
  form?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  overwriteExistingUnlessIdentical?: InputMaybe<Scalars['Boolean']['input']>;
  record?: InputMaybe<Scalars['ID']['input']>;
  requestSignature?: InputMaybe<Scalars['Boolean']['input']>;
  unitAmount?: InputMaybe<Scalars['Int']['input']>;
  unitName?: InputMaybe<Scalars['String']['input']>;
  /** A message supplied by the creator that will appear in the email */
  userSuppliedContext?: InputMaybe<Scalars['String']['input']>;
};

export type Form_Action_SchedulePayload = {
  __typename?: 'form_action_schedulePayload';
  /** If a custom email is specified. Assigned anon email answers will not use this field. */
  assignedAnonEmail?: Maybe<Scalars['String']['output']>;
  assignedAnonEmailAnswer?: Maybe<Scalars['ID']['output']>;
  /** If a custom name is specified. Assigned anon email answers will not use this field. */
  assignedAnonName?: Maybe<Scalars['String']['output']>;
  assignedUser?: Maybe<Scalars['ID']['output']>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  deleted?: Maybe<Scalars['Boolean']['output']>;
  dueAt?: Maybe<Scalars['DateTime']['output']>;
  /** May contain more than one error for same field. */
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  form?: Maybe<Scalars['ID']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  object?: Maybe<FormActionScheduleType>;
  record?: Maybe<Scalars['ID']['output']>;
  requestSignature?: Maybe<Scalars['Boolean']['output']>;
  unitAmount?: Maybe<Scalars['Int']['output']>;
  unitName?: Maybe<Scalars['String']['output']>;
  /** A message supplied by the creator that will appear in the email */
  userSuppliedContext?: Maybe<Scalars['String']['output']>;
};

export type Form_SaveInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  form: Scalars['ID']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  publicForm?: InputMaybe<Scalars['ID']['input']>;
  record: Scalars['ID']['input'];
};

export type Form_SavePayload = {
  __typename?: 'form_savePayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  created?: Maybe<Scalars['DateTime']['output']>;
  /** May contain more than one error for same field. */
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  form?: Maybe<Scalars['ID']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  object?: Maybe<FormSaveType>;
  publicForm?: Maybe<Scalars['ID']['output']>;
  record?: Maybe<Scalars['ID']['output']>;
};

export type Form_Save_BulkBulkInput = {
  form: Scalars['ID']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  publicForm?: InputMaybe<Scalars['ID']['input']>;
  record: Scalars['ID']['input'];
};

export type Form_Save_BulkInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  objects?: InputMaybe<Array<InputMaybe<Form_Save_BulkBulkInput>>>;
};

export type Form_Save_BulkPayload = {
  __typename?: 'form_save_bulkPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  created?: Maybe<Scalars['DateTime']['output']>;
  /** May contain more than one error for same field. */
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  form?: Maybe<Scalars['ID']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  objects?: Maybe<Array<FormSaveType>>;
  publicForm?: Maybe<Scalars['ID']['output']>;
  record?: Maybe<Scalars['ID']['output']>;
};

export type Form_Save_PubInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  form: Scalars['ID']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  publicForm?: InputMaybe<Scalars['ID']['input']>;
  record: Scalars['ID']['input'];
};

export type Form_Save_PubPayload = {
  __typename?: 'form_save_pubPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  created?: Maybe<Scalars['DateTime']['output']>;
  /** May contain more than one error for same field. */
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  form?: Maybe<Scalars['ID']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  object?: Maybe<FormSavePubType>;
  publicForm?: Maybe<Scalars['ID']['output']>;
  record?: Maybe<Scalars['ID']['output']>;
};

export type Form_Save_Pub_BulkBulkInput = {
  form: Scalars['ID']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  publicForm?: InputMaybe<Scalars['ID']['input']>;
  record: Scalars['ID']['input'];
};

export type Form_Save_Pub_BulkInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  objects?: InputMaybe<Array<InputMaybe<Form_Save_Pub_BulkBulkInput>>>;
};

export type Form_Save_Pub_BulkPayload = {
  __typename?: 'form_save_pub_bulkPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  created?: Maybe<Scalars['DateTime']['output']>;
  /** May contain more than one error for same field. */
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  form?: Maybe<Scalars['ID']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  objects?: Maybe<Array<FormSavePubType>>;
  publicForm?: Maybe<Scalars['ID']['output']>;
  record?: Maybe<Scalars['ID']['output']>;
};

export type Hidden_FieldInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  deleted?: InputMaybe<Scalars['Boolean']['input']>;
  field: Scalars['ID']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  team?: InputMaybe<Scalars['ID']['input']>;
};

export type Hidden_FieldPayload = {
  __typename?: 'hidden_fieldPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  deleted?: Maybe<Scalars['Boolean']['output']>;
  /** May contain more than one error for same field. */
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  field?: Maybe<Scalars['ID']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  object?: Maybe<HiddenFieldType>;
  team?: Maybe<Scalars['ID']['output']>;
};

export type Merged_PdfInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  createdBy?: InputMaybe<Scalars['ID']['input']>;
  name: Scalars['String']['input'];
  pdfIds: Array<InputMaybe<Scalars['ID']['input']>>;
};

export type Merged_PdfPayload = {
  __typename?: 'merged_pdfPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  createdBy?: Maybe<Scalars['ID']['output']>;
  /** May contain more than one error for same field. */
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  name?: Maybe<Scalars['String']['output']>;
  object?: Maybe<MergedPdfType>;
  pdfIds?: Maybe<Array<Maybe<Scalars['ID']['output']>>>;
};

export type OrganisationInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  logo?: InputMaybe<Scalars['Upload']['input']>;
  name: Scalars['String']['input'];
};

export type OrganisationPayload = {
  __typename?: 'organisationPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** May contain more than one error for same field. */
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  object?: Maybe<OrganisationType>;
};

/** An enumeration. */
export enum Origin {
  /** FORM_ACTION */
  FormAction = 'FORM_ACTION',
  /** PUBLIC_FORM */
  PublicForm = 'PUBLIC_FORM',
  /** RESET */
  Reset = 'RESET',
  /** USER */
  User = 'USER'
}

/** An enumeration. */
export enum Origin_Format {
  /** Customisable */
  Customisable = 'CUSTOMISABLE',
  /** Pdf Creation */
  PdfCreation = 'PDF_CREATION'
}

export type PdfInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  createdBy?: InputMaybe<Scalars['ID']['input']>;
  form?: InputMaybe<Scalars['ID']['input']>;
  fromPublicLink?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  origin?: InputMaybe<Origin>;
  pdfGenerator?: InputMaybe<Scalars['ID']['input']>;
  record?: InputMaybe<Scalars['ID']['input']>;
};

export type PdfPayload = {
  __typename?: 'pdfPayload';
  clientMutationId?: Maybe<Scalars['String']['output']>;
  created?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<Scalars['ID']['output']>;
  /** May contain more than one error for same field. */
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  form?: Maybe<Scalars['ID']['output']>;
  fromPublicLink?: Maybe<Scalars['Boolean']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  object?: Maybe<PdfType>;
  origin?: Maybe<Origin>;
  pdfGenerator?: Maybe<Scalars['ID']['output']>;
  record?: Maybe<Scalars['ID']['output']>;
};

export type Public_FormInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  form?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  record?: InputMaybe<Scalars['ID']['input']>;
};

export type Public_FormPayload = {
  __typename?: 'public_formPayload';
  active?: Maybe<Scalars['Boolean']['output']>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  code?: Maybe<Scalars['String']['output']>;
  created?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<Scalars['ID']['output']>;
  /** May contain more than one error for same field. */
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  form?: Maybe<Scalars['ID']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  object?: Maybe<PublicFormType>;
  record?: Maybe<Scalars['ID']['output']>;
};

export type RecordInput = {
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  archivedBy?: InputMaybe<Scalars['ID']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  copyRecord?: InputMaybe<Scalars['ID']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  image?: InputMaybe<Scalars['Upload']['input']>;
  moveDatesTo?: InputMaybe<Scalars['Int']['input']>;
  moveDatesType?: InputMaybe<MoveDatesType>;
  name?: InputMaybe<Scalars['String']['input']>;
  position?: InputMaybe<Scalars['String']['input']>;
  team?: InputMaybe<Scalars['ID']['input']>;
  template?: InputMaybe<Scalars['ID']['input']>;
};

export type RecordPayload = {
  __typename?: 'recordPayload';
  archived?: Maybe<Scalars['Boolean']['output']>;
  archivedBy?: Maybe<Scalars['ID']['output']>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** May contain more than one error for same field. */
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  object?: Maybe<RecordType>;
  position?: Maybe<Scalars['String']['output']>;
  team?: Maybe<Scalars['ID']['output']>;
  template?: Maybe<Scalars['ID']['output']>;
};

export type TeamInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  logo?: InputMaybe<Scalars['Upload']['input']>;
  module?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organisation?: InputMaybe<Scalars['ID']['input']>;
  reminderFrequencyDays?: InputMaybe<Scalars['Int']['input']>;
};

export type TeamPayload = {
  __typename?: 'teamPayload';
  active?: Maybe<Scalars['Boolean']['output']>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** May contain more than one error for same field. */
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  id?: Maybe<Scalars['ID']['output']>;
  module?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  object?: Maybe<TeamType>;
  organisation?: Maybe<Scalars['ID']['output']>;
  publicRecordCreateCode?: Maybe<Scalars['String']['output']>;
  reminderFrequencyDays?: Maybe<Scalars['Int']['output']>;
  users?: Maybe<Scalars['String']['output']>;
};

export type UserInput = {
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  currentPassword?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  howYouHeardAboutUs?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  organisation?: InputMaybe<Scalars['ID']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  seenWelcomeMessage?: InputMaybe<Scalars['Boolean']['input']>;
};

export type UserPayload = {
  __typename?: 'userPayload';
  /** Gives oversite to EVERY organisation within the suite via the suite admin dashboard */
  adminOfSuite?: Maybe<Scalars['Boolean']['output']>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  /** May contain more than one error for same field. */
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  firstName?: Maybe<Scalars['String']['output']>;
  howYouHeardAboutUs?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  /** Is internal pharos user, ie dean + dave */
  isAdmin?: Maybe<Scalars['Boolean']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  object?: Maybe<UserType>;
  organisation?: Maybe<Scalars['ID']['output']>;
  seenWelcomeMessage?: Maybe<Scalars['Boolean']['output']>;
  teams?: Maybe<Scalars['String']['output']>;
  /** Gives access to all teams within the organisation + user management */
  topLevelManager?: Maybe<Scalars['Boolean']['output']>;
  /** This is set when they have been invited. If set, on first login they should be asked for a new password. */
  usingTemporaryPassword?: Maybe<Scalars['Boolean']['output']>;
};

export type User_TeamInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  team?: InputMaybe<Scalars['ID']['input']>;
  user?: InputMaybe<Scalars['ID']['input']>;
};

export type User_TeamPayload = {
  __typename?: 'user_teamPayload';
  active?: Maybe<Scalars['Boolean']['output']>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** May contain more than one error for same field. */
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  id?: Maybe<Scalars['ID']['output']>;
  object?: Maybe<UserTeamType>;
  team?: Maybe<Scalars['ID']['output']>;
  user?: Maybe<Scalars['ID']['output']>;
};

export type Webauthn_CredentialsInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
};

export type Webauthn_CredentialsPayload = {
  __typename?: 'webauthn_credentialsPayload';
  active?: Maybe<Scalars['Boolean']['output']>;
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** May contain more than one error for same field. */
  errors?: Maybe<Array<Maybe<ErrorType>>>;
  id?: Maybe<Scalars['ID']['output']>;
  object?: Maybe<WebauthnCredentialsType>;
};
