import React, { useState } from 'react'
import { message, Modal } from 'antd'
import gql from 'graphql-tag'
import buttonStyle from '@sass/common/button.module.sass'
import { RowType } from '../../../common/const'
import { useFormActionManagerDeactivateMutation } from './__gen'
import { useAppDispatch } from '@/utils/hooks'
import { showNotification } from '@/state/user-interface'

gql`
	mutation formActionManagerDeactivate($recordFormPairs: [RecordFormPair!]!) {
		formAction_BulkDeactivate(recordFormPairs: $recordFormPairs) {
			success
			schedulesCount
			actionsCount
			escalationsCount
		}
	}
`

type Props = {
	// record: { id: string; name: string }
	// form: { id: string; title: string }
	selectedRows: Set<string>
	rows: RowType[]
	resetList: () => void
}

export const DeleteButton = ({ selectedRows, rows, resetList }: Props) => {
	const [isModalOpen, setIsModalOpen] = useState(false)
	const dispatch = useAppDispatch()

	const [formActionManagerDeactivate, { loading }] = useFormActionManagerDeactivateMutation()

	const doMutation = async() => {
		const resp = await formActionManagerDeactivate({
			variables: {
				recordFormPairs: Array.from(selectedRows).map((rf) => ({
					recordId: rf.split('|')[0],
					formId: rf.split('|')[1],
				})),
			},
		})
		setIsModalOpen(false)
		if (resp.data?.formAction_BulkDeactivate?.success) {
			dispatch(showNotification({
				type: 'positive',
				content: (
					`
						Delete successful
						(${resp.data?.formAction_BulkDeactivate?.schedulesCount} schedules,
						${resp.data?.formAction_BulkDeactivate?.actionsCount} actions,
						${resp.data?.formAction_BulkDeactivate?.escalationsCount} escalations)
					`
				),
			}))
		} else {
			dispatch(showNotification({
				type: 'error',
				content: 'Deleted failed',
			}))
		}
		resetList()
	}

	return (
		<>
			<button
				className={buttonStyle.greySolid}
				disabled={selectedRows.size === 0}
				onClick={() => setIsModalOpen(true)}
			>
				Delete
			</button>
			<Modal
				title="Are you sure?"
				open={isModalOpen}
				onOk={doMutation}
				onCancel={() => setIsModalOpen(false)}
			>
				<p>This will clear the actions, escalations and schedules for {selectedRows.size} forms.</p>
				<p>Are you sure you want to continue?</p>
			</Modal>
		</>
	)
}
