import React from 'react'
import { useFormContext } from '@/state/main-form/reducer'
import { FormDropdown } from './dropdown'
import ExternalLink from './external-link'
import { FormActions } from './form-actions'
import { CompleteFormActionButton } from './form-actions/complete-button'
import { FormValidation } from './form-validation-sidebar'
import style from './index.module.sass'
import PdfSection from './pdf-section'
import QuickLinks from './quick-links'
import { SavingIndicator } from './saving-indicator'
import { SubmitButtonDeprecated } from './submit-button-deprecated'

export const FormControlPanel = () => {
	const formContext = useFormContext()

	if (formContext.publicForm?.code) {
		return (
			<>
				<div className={style.buttonRow}>
					{formContext.publicForm.formAction ?
						<CompleteFormActionButton formAction={formContext.publicForm.formAction} />
					:	<SubmitButtonDeprecated />}
					<SavingIndicator />
				</div>
				<FormValidation />
			</>
		)
	} else {
		return (
			<>
				{!formContext.record.archived && (
					<div className={style.buttonRow}>
						<SavingIndicator />
						<FormDropdown />
					</div>
				)}
				{formContext.form.allowPublicLinks && (
					<div>
						<ExternalLink readOnly={formContext.record.archived} />
					</div>
				)}

				<FormValidation />

				<FormActions />

				<PdfSection
					recordId={formContext.record.id}
					formId={formContext.form.id}
					readOnly={formContext.record.archived}
				/>

				<QuickLinks formId={formContext.form.id} />
			</>
		)
	}
}
