import React from 'react'
import { addDays, addMonths } from 'date-fns'
import { FormActionFormActionScheduleUnitNameChoices } from '@/__gen/types'
import { FormActionDashboardTemplatesQuery } from '../../../__gen'
import { getMonths } from '../../../common/const'
import { FormActionDashboardDataQuery } from '../../__gen'
import { monthIdFromString, monthKey } from '../../../common/const'
import { Line, emptyMonthMap } from './line'
import { MonthData } from './segment'

type Record = NonNullable<FormActionDashboardDataQuery['record_List']>['objects'][number]

const UNIT_NAME_TO_FUNC = {
	DAYS: addDays,
	MONTHS: addMonths,
} satisfies { [k in FormActionFormActionScheduleUnitNameChoices]: any }

export type ActionLineProps = {
	record: Record
	form: NonNullable<
		FormActionDashboardTemplatesQuery['template_List']
	>['objects'][number]['formSet']['forms'][number]
	data: {
		formActions: Record['formActions']
		formActionEscalationEvents: Record['formActionEscalationEvents']
		formActionSchedules: Record['formActionSchedules']
	}
	startYear: number
	startMonth: number
}

export const ActionLine = ({ record, form, data, startYear, startMonth }: ActionLineProps) => {
	const actions = data?.formActions.filter((a) => a.record.id == record.id && a.form.id == form.id) || []
	const schedules =
		data?.formActionSchedules.filter((a) => a.record.id == record.id && a.form.id == form.id) || []
	const escalations =
		data?.formActionEscalationEvents.filter((a) => a.record.id == record.id && a.form.id == form.id) || []

	const thisMonth = monthKey({ year: new Date().getFullYear(), month: new Date().getMonth() + 1 })

	const monthMap: { [id: string]: MonthData } = {}

	const months = getMonths({ startMonth, startYear })

	months.forEach((month) => {
		monthMap[monthKey(month)] = emptyMonthMap()
	})

	actions.forEach((action) => {
		const completedOnMonth = action.completedOn ? monthIdFromString(action.completedOn) : null
		if (completedOnMonth && monthMap[completedOnMonth]) {
			monthMap[completedOnMonth].completedOn[action.id] = action
		} else if (monthMap[thisMonth]) {
			// add overdue for today if visible
			monthMap[thisMonth].overdue[action.id] = action
		}

		const createdMonth = monthIdFromString(action.created)
		if (monthMap[createdMonth]) {
			monthMap[createdMonth].created[action.id] = action
		}

		for (let key in monthMap) {
			if (createdMonth < key && (!completedOnMonth || key < completedOnMonth) && key < thisMonth) {
				monthMap[key].span[action.id] = action
			}
		}
	})

	const startDate = new Date(startYear, (startMonth-1 + 12) % 12, 1)
	const endDate = new Date(startYear + 1, (startMonth - 1 + 12) % 12, 1)

	schedules.forEach((schedule) => {
		const addFunc = UNIT_NAME_TO_FUNC[schedule.unitName]
		const firstDueAt = new Date(schedule.firstDueAt)

		// Wind forwards
		let c1 = 0
		let forwards = new Date(schedule.dueAt)
		while (c1 <= 365) {
			c1++ // Add extra limit in case
			if (forwards >= endDate) {
				break
			}
			if (forwards >= startDate && forwards > firstDueAt) {
				const dueAtMonth = monthIdFromString(forwards.toUTCString())
				monthMap[dueAtMonth].dueInFuture[schedule.id] = schedule
			}
			
			forwards = addFunc(forwards, schedule.unitAmount)
		}
		// Wind backwards
		let c2 = 0
		let backwards = new Date(schedule.dueAt)
		while (c2 <= 365) {
			c2++ // Add extra limit in case
			if (backwards < startDate || backwards < firstDueAt) {
				break
			}
			if (backwards < endDate) {
				const dueAtMonth = monthIdFromString(backwards.toUTCString())
				monthMap[dueAtMonth].dueInFuture[schedule.id] = schedule
			}
			
			backwards = addFunc(backwards, -schedule.unitAmount)
		}
	})

	escalations.forEach((e) => {
		const dueAtMonth = monthIdFromString(e.created)
		monthMap[dueAtMonth].escalation[e.id] = e
	})

	return (
		<Line months={months} monthMap={monthMap} />
	)
}
