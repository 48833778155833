import React, { useEffect, useState, useRef, useCallback } from 'react'
import c from 'classnames'

import { useAppDispatch, useAppSelector } from '@/utils/hooks'
import { toggleFullScreenField } from '@/state/user-interface'
import { useFormContext } from '@/state/main-form/reducer'
import buttonStyle from '@sass/common/button.module.sass'
import iconStyle from '@sass/common/icon.module.sass'
import { Title } from './title'
import { RowHeaders } from './answers/row-headers'
import { SortableRows } from './answers/sortable-rows'
import style from './index.module.sass'
import { RootState } from '@/state/redux-store'
import { addAnswerRowThunk } from '@/state/answer/thunks'
import { FieldTypeEnum } from '@/state/main-form/types'
import { selectAnswersForField } from '@/state/answer/selectors'

const MIN_COL_WIDTH = 115

type Props = {
	forwardRef: React.RefObject<HTMLDivElement>
	highlight: boolean,
	fieldId: string,
}


export const Field = ({ forwardRef, highlight, fieldId}: Props) => {
	const formContext = useFormContext()
	const field = formContext.fields[fieldId]

	const numActiveRanks = useAppSelector((state: RootState) => new Set(
		selectAnswersForField(state, fieldId).filter(a => !a.deactivated).map(a => a.rank)
		).size)
	const fullScreenField = useAppSelector(state => state.userInterface.fullScreenField)
	const dispatch = useAppDispatch()
	const row = useRef<HTMLDivElement | null>(null)
	const tableFields = field.tableFields

	const doAddAnswerRow = useCallback(() => {
		dispatch(addAnswerRowThunk(field.id))
	}, [field, formContext])
		
	const fieldIsDisabled = !!(
		formContext.record.archived
		|| field.disabledAlways
		|| (
			field.disableWhenSigning && (
				formContext.form.lockedFromFormAction
				|| !!field.disabledForFormActions.length
			)
		)
		|| (
			formContext.publicForm?.code && (
				formContext.form.publicLinkIsReadOnly
				|| field.disableWhenPublic
			)
		)
	)

	// Default to 1, tables will recalulate after render. This avoids rendering twice.
	const [tableFieldShowColumns, setTableFieldShowColumns] = useState(1)

	const getTableFieldShowColumns = useCallback(() => {
		// hide columns if there are too many to fit
		let showColumns = Object.keys(tableFields).length
		if (fullScreenField) {
			return showColumns
		}
		if (row && row.current) {
			const totalMinWidth = Object.keys(tableFields).length * MIN_COL_WIDTH
			const extraSpace = row.current.clientWidth - 60 - totalMinWidth
			if (extraSpace < 0) {
				const hideCount = Math.ceil(-extraSpace / MIN_COL_WIDTH)
				showColumns -= hideCount
			}
		}
		return showColumns
	},[field, fullScreenField, row])

	const checkTableFieldShowColumns = useCallback(() => {
		if (field.type === FieldTypeEnum.Table) {
			const next = getTableFieldShowColumns()
			if (next != tableFieldShowColumns) {
				setTableFieldShowColumns(next)
			}
		}
	}, [getTableFieldShowColumns, setTableFieldShowColumns])

	const doToggleFullScreenField = useCallback(
		() => dispatch(toggleFullScreenField(fullScreenField ? null : field.id)),
		[fullScreenField, field]
	)

	useEffect(
		() => {
			window.addEventListener('resize', checkTableFieldShowColumns)
			return () => {
				window.removeEventListener('resize', checkTableFieldShowColumns)
			}
		},
		[]
	)

	useEffect(
		() => {
			checkTableFieldShowColumns()
		},
		[fullScreenField]
	)

	if (field.hiddenFieldId) {
		return null
	}

	const inline = (field.type == FieldTypeEnum.Boolean || field.type == FieldTypeEnum.Radio)
	const rowClass = c({ [style.inlineFieldRow]: inline })

	const sortAllowed = !(
		(field.disableEditingRowsWhenPublic && formContext.publicForm?.code)
		|| fieldIsDisabled
	)

	const hidingTableColumns = field.type==FieldTypeEnum.Table &&
			tableFieldShowColumns < field.tableFields.length

	const showDeleteButton = sortAllowed && numActiveRanks > 1 && !hidingTableColumns

	return (
		<div ref={forwardRef}>
			<div className={rowClass}>
				<Title
					fieldId={field.id}
					toggleFullScreenField={doToggleFullScreenField}
					highlight={highlight}
				/>
				<div className={style.fieldContentContainer} ref={row}>
					<div className={style.fieldContent}>
						{field.type == FieldTypeEnum.Table &&
							<RowHeaders fieldId={field.id} tableFieldShowColumns={tableFieldShowColumns} />
						}
						<SortableRows
							fieldId={field.id}
							tableFieldShowColumns={tableFieldShowColumns}
							fieldIsDisabled={fieldIsDisabled}
							showDeleteButton={showDeleteButton}
							sortEnabled={sortAllowed && numActiveRanks > 1}
						/>
					</div>
					{tableFieldShowColumns < field.tableFields.length &&
						<div className={style.more} onClick={doToggleFullScreenField}>
							<button className={c(style.moreButton, buttonStyle.primarySolid, buttonStyle.small)}>More</button>
						</div>
					}
				</div>
			</div>
			{ field.allowMultipleAnswers && sortAllowed &&
				<button className={c(buttonStyle.secondary, style.iconAdd, iconStyle.add)} onClick={doAddAnswerRow}></button>
			}
		</div>
	)
}