import * as Types from '@/__gen/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FormActionDashboardTemplatesQueryVariables = Types.Exact<{
  moduleId: Types.Scalars['ID']['input'];
}>;


export type FormActionDashboardTemplatesQuery = { __typename: 'PharosQuery', template_List: { __typename: 'TemplateTypeListBase', objects: Array<{ __typename: 'TemplateType', id: string, formSet: { __typename: 'FormSetType', id: string, forms: Array<{ __typename: 'FormType', id: string, title: string }> } }> } | null };


export const FormActionDashboardTemplatesDocument = gql`
    query formActionDashboardTemplates($moduleId: ID!) {
  template_List(module: $moduleId) {
    objects {
      id
      formSet {
        id
        forms(
          actionsFormat_In: [CUSTOMISABLE, PDF_CREATION]
          orderBy: [{field: category_Rank, modifiers: [NULLS_FIRST]}, {field: rank}]
        ) {
          id
          title
        }
      }
    }
  }
}
    `;

/**
 * __useFormActionDashboardTemplatesQuery__
 *
 * To run a query within a React component, call `useFormActionDashboardTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFormActionDashboardTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFormActionDashboardTemplatesQuery({
 *   variables: {
 *      moduleId: // value for 'moduleId'
 *   },
 * });
 */
export function useFormActionDashboardTemplatesQuery(baseOptions: Apollo.QueryHookOptions<FormActionDashboardTemplatesQuery, FormActionDashboardTemplatesQueryVariables> & ({ variables: FormActionDashboardTemplatesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FormActionDashboardTemplatesQuery, FormActionDashboardTemplatesQueryVariables>(FormActionDashboardTemplatesDocument, options);
      }
export function useFormActionDashboardTemplatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FormActionDashboardTemplatesQuery, FormActionDashboardTemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FormActionDashboardTemplatesQuery, FormActionDashboardTemplatesQueryVariables>(FormActionDashboardTemplatesDocument, options);
        }
export function useFormActionDashboardTemplatesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FormActionDashboardTemplatesQuery, FormActionDashboardTemplatesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FormActionDashboardTemplatesQuery, FormActionDashboardTemplatesQueryVariables>(FormActionDashboardTemplatesDocument, options);
        }
export type FormActionDashboardTemplatesQueryHookResult = ReturnType<typeof useFormActionDashboardTemplatesQuery>;
export type FormActionDashboardTemplatesLazyQueryHookResult = ReturnType<typeof useFormActionDashboardTemplatesLazyQuery>;
export type FormActionDashboardTemplatesSuspenseQueryHookResult = ReturnType<typeof useFormActionDashboardTemplatesSuspenseQuery>;
export type FormActionDashboardTemplatesQueryResult = Apollo.QueryResult<FormActionDashboardTemplatesQuery, FormActionDashboardTemplatesQueryVariables>;