import React from 'react'
import _pick from 'lodash/pick'
import gql from "graphql-tag"
import { Formik, Field, FormikProps } from 'formik'
import * as Yup from 'yup'

import { SpinnerButton } from '@/components/common/button'
import { TextField, ImageField } from '@/components/common/formik'
import { useGlobalContext } from '@/components/context'
import { showNotification } from '@/state/user-interface'
import { useAppDispatch } from '@/utils/hooks'
import formStyle from '@sass/common/form.module.sass'
import { useSaveOrganisationMutation } from './__gen/form'


gql`
	mutation saveOrganisation(
		$id: ID!,
		$name: String!,
		$logo: Upload
	) {
		organisation_Update(input: {
			id: $id
			name: $name,
			logo: $logo,
		}) {
			object {
				id
				name
				logo
			}
		}
	}
`

type ValuesType = {
	name: string
	logo: File | null
}

const AddTeam = () => {
	const [mutate, {loading}] = useSaveOrganisationMutation()
	const { organisation } = useGlobalContext()
	const dispatch = useAppDispatch()
	return (
		<Formik
			initialValues={_pick(organisation, ['id', 'name', 'logo'])}
			validationSchema={Yup.object().shape({
				name: Yup.string().required('Required'),
				logo: ImageField.validator
			})}
			onSubmit={(values, actions) => {
				mutate({variables: { ...values, id: organisation.id }}).then(() => {
					actions.resetForm()
					dispatch(showNotification({
						'content': 'Organisation saved',
					}))
				}).catch((r) => {
					console.error(r)
					actions.setFieldError('all', 'There was a problem saving data')
				}).finally(() => {
					actions.setSubmitting(false)
				})
			}}
		>
			{/* This is probably easy. Came up in typescript upgrade
			//@ts-ignore TODO: */}
			{ (props: FormikProps<ValuesType>) => {
				const { handleSubmit } = props
				return (
					<div className={formStyle.pageFormContainer}>
						<form onSubmit={handleSubmit} className={formStyle.commonForm}>
							<Field
								name="name"
								placeholder={"Name"}
								component={TextField}
							/>
							<Field
								title="logo"
								name="logo"
								component={ImageField}
							/>
							<div
								style={{display: 'flex', justifyContent: 'space-around', width: 200}}
								className={formStyle.formButton}
							>
								<SpinnerButton loading={loading} type="submit">
									Save
								</SpinnerButton>
							</div>
						</form>
					</div>
				)
			}}
		</Formik>
	)
}

export default AddTeam