import * as Types from '@/__gen/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ApiVersionDetailsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ApiVersionDetailsQuery = { __typename: 'PharosQuery', apiVersionDetails: { __typename: 'ApiVersionDetails', id: string } };


export const ApiVersionDetailsDocument = gql`
    query apiVersionDetails {
  apiVersionDetails {
    id
  }
}
    `;

/**
 * __useApiVersionDetailsQuery__
 *
 * To run a query within a React component, call `useApiVersionDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useApiVersionDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApiVersionDetailsQuery({
 *   variables: {
 *   },
 * });
 */
export function useApiVersionDetailsQuery(baseOptions?: Apollo.QueryHookOptions<ApiVersionDetailsQuery, ApiVersionDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ApiVersionDetailsQuery, ApiVersionDetailsQueryVariables>(ApiVersionDetailsDocument, options);
      }
export function useApiVersionDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApiVersionDetailsQuery, ApiVersionDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ApiVersionDetailsQuery, ApiVersionDetailsQueryVariables>(ApiVersionDetailsDocument, options);
        }
export function useApiVersionDetailsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ApiVersionDetailsQuery, ApiVersionDetailsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ApiVersionDetailsQuery, ApiVersionDetailsQueryVariables>(ApiVersionDetailsDocument, options);
        }
export type ApiVersionDetailsQueryHookResult = ReturnType<typeof useApiVersionDetailsQuery>;
export type ApiVersionDetailsLazyQueryHookResult = ReturnType<typeof useApiVersionDetailsLazyQuery>;
export type ApiVersionDetailsSuspenseQueryHookResult = ReturnType<typeof useApiVersionDetailsSuspenseQuery>;
export type ApiVersionDetailsQueryResult = Apollo.QueryResult<ApiVersionDetailsQuery, ApiVersionDetailsQueryVariables>;