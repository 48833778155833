import React from 'react'
import dayjs from 'dayjs'
import gql from 'graphql-tag'
import Placeholder from '@/components/common/placeholder'
import { ModeType, RowType, getRecordFormKey } from '../../common/const'
import { EditModeTitle } from '../../page-title/edit-mode-title'
import { FormActionDashboardToUpdateQuery, useFormActionDashboardToUpdateQuery } from './__gen'
import style from './index.module.sass'
import { TableInner } from './table-inner'

gql`
	query formActionDashboardToUpdate($recordIdFormIdJsonList: String!, $recordIds: [ID!]!) {
		formActionSchedule_List(limit: 5000, offset: 0, recordIdFormIdJsonList: $recordIdFormIdJsonList) {
			objects {
				id
				firstDueAt
				assignedAnonEmail
				assignedAnonName
				dueAt
				unitAmount
				unitName
				requestSignature
				userSuppliedContext
				assignedAnonEmailAnswer {
					id
					content
					contactName
				}
				record {
					id
				}
				form {
					id
				}
			}
			pageInfo {
				hasNextPage
				total
			}
		}
		formActionEscalation_List(limit: 5000, offset: 0, recordIdFormIdJsonList: $recordIdFormIdJsonList) {
			objects {
				id
				deltaDays
				assignedAnonEmailAnswer {
					id
					content
					contactName
				}
				record {
					id
				}
				form {
					id
				}
			}
			pageInfo {
				hasNextPage
				total
			}
		}
		formActionContact_List(recordIds: $recordIds) {
			id
			objId
			email
			name
			fieldTitle
		}
	}
`

type Props = {
	allRows: RowType[]
	selectedRows: Set<string>
	setMode: (mode: ModeType) => void
	resetList: () => void
}

const transformAnswerOptions = (
	answerOptions: NonNullable<FormActionDashboardToUpdateQuery['formActionContact_List']>,
) => {
	const out: Record<string, NonNullable<FormActionDashboardToUpdateQuery['formActionContact_List']>> = {}
	answerOptions.forEach((c) => {
		if (!out[c.objId]) {
			out[c.objId] = []
		}
		out[c.objId].push(c)
	})
	return out
}

export const EditTable = ({ allRows, selectedRows, setMode, resetList }: Props) => {
	const rows = allRows.filter((r) => selectedRows.has(getRecordFormKey(r)))

	const { data, loading } = useFormActionDashboardToUpdateQuery({
		variables: {
			recordIdFormIdJsonList: JSON.stringify(rows.map((r) => ({ recordId: r.record.id, formId: r.form.id }))),
			recordIds: Array.from(new Set(rows.map((r) => r.record.id))),
		},
	})

	const escalationsMap: {
		[key: string]: NonNullable<
			FormActionDashboardToUpdateQuery['formActionEscalation_List']
		>['objects'][number]
	} = {}
	data?.formActionEscalation_List?.objects.forEach((e) => {
		const key = getRecordFormKey({ record: e.record, form: e.form })
		escalationsMap[key] = e
	})

	return (
		<div className={style.container}>
			<EditModeTitle />
			{loading ?
				[...Array(8).keys()].map((i) => <Placeholder key={i} style={{ margin: '10px 0' }} />)
			: !data?.formActionSchedule_List || !data.formActionContact_List ?
				'error'
			:	<TableInner
					rows={rows}
					setMode={setMode}
					resetList={resetList}
					schedules={Object.fromEntries(
						data.formActionSchedule_List.objects.map((s) => {
							const key = getRecordFormKey({ record: s.record, form: s.form })
							return [
								key,
								{
									dueAt: dayjs(s.dueAt),
									unitAmount: s.unitAmount,
									unitName: s.unitName,
									requestSignature: s.requestSignature,
									userSuppliedContext: s.userSuppliedContext,
									assignedAnonEmailAnswer: s.assignedAnonEmailAnswer?.id || null,
									escalationEmailAnswer: escalationsMap[key]?.assignedAnonEmailAnswer?.id || null,
									escalationDeltaDays: escalationsMap[key]?.deltaDays || null,
								},
							]
						}),
					)}
					answerOptions={transformAnswerOptions(data.formActionContact_List)}
				/>
			}
		</div>
	)
}
