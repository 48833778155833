import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Empty } from 'antd'
import gql from 'graphql-tag'
import Placeholder from '@/components/common/placeholder'
import { useGlobalContext } from '@/components/context'
import generalStyle from '@sass/common/general.module.sass'
import { FormActionDashboardTemplatesQuery } from '../__gen'
import { ModeType, RowType } from '../common/const'
import { Row } from '../common/row'
import { useFormActionDashboardDataLazyQuery } from './__gen'
import { EditTable } from './edit-table'
import { SelectTable } from './select-table'
import { ActionLineProps } from './select-table/action-line'
import { LineLoading } from './select-table/action-line/line'

gql`
	query formActionDashboardData(
		$moduleId: ID!
		$teamId: ID
		$offset: Int!
		$yearMonth: String!
		$hasActions: Boolean
		$actionDashboardSearch: String!
	) {
		record_List(
			limit: 3
			offset: $offset
			module: $moduleId
			team: $teamId
			archived: false
			hasActions: $hasActions
			actionDashboardSearch: $actionDashboardSearch
			orderBy: [{ field: name, modifiers: [CASE_INSENSITIVE] }]
		) {
			objects {
				id
				name
				template {
					id
				}
				formActions(yearMonth: $yearMonth) {
					id
					completedOn
					created
					deleted
					record {
						id
					}
					form {
						id
					}
					assignedUser {
						id
						firstName
						lastName
						email
					}
					assignedAnonEmail
					assignedAnonName
					assignedAnonEmailAnswer {
						id
						content
						contactName
					}
				}
				formActionSchedules(yearMonth: $yearMonth) {
					id
					firstDueAt
					assignedAnonEmail
					assignedAnonName
					dueAt
					unitAmount
					unitName
					assignedAnonEmailAnswer {
						id
						content
						contactName
					}
					assignedUser {
						id
						firstName
						lastName
						email
					}
					record {
						id
					}
					form {
						id
					}
				}
				formActionEscalationEvents(yearMonth: $yearMonth) {
					id
					created
					record {
						id
					}
					form {
						id
					}
					assignedUser {
						id
						firstName
						lastName
						email
					}
					assignedAnonEmail
					assignedAnonName
					assignedAnonEmailAnswer {
						id
						content
						contactName
					}
				}
			}
			pageInfo {
				hasNextPage
				total
			}
		}
	}
`

type Templates = NonNullable<FormActionDashboardTemplatesQuery['template_List']>['objects']

const makeEmptyData = () => {
	const emptyData: ActionLineProps['data'] = {
		formActions: [],
		formActionEscalationEvents: [],
		formActionSchedules: [],
	}
	return emptyData
}

// When we are 30 cards from the bottom, load more
const LOAD_MORE_AT = 30

type TableProps = {
	startYear: number
	startMonth: number
	templates: { [id: string]: Templates[number] }
	search: string
	filterRowsWithoutData: boolean
	mode: ModeType
	setMode: (mode: ModeType) => void
	selectedRows: Set<string>
	setSelectedRows: (rows: Set<string>) => void
	resetList: () => void
}

export const FiltersAndFetch = ({
	templates,
	startYear,
	startMonth,
	search,
	filterRowsWithoutData,
	mode,
	setMode,
	selectedRows,
	setSelectedRows,
	resetList,
}: TableProps) => {
	const globalContext = useGlobalContext()
	useEffect(() => {
		if (mode == 'view') {
			setSelectedRows(new Set())
		}
	}, [mode, setSelectedRows])
	const [errors, setErrors] = useState<React.ReactNode[]>([])
	const [recordCount, setRecordCount] = useState(0)
	const [rows, setRows] = useState<RowType[]>([])
	const [hasNextpage, setHasNextPage] = useState(true)

	const [fetchRecords, recordQuery] = useFormActionDashboardDataLazyQuery({
		notifyOnNetworkStatusChange: true,
	})

	const fetchChain = useCallback(async () => {
		const recordData = await fetchRecords({
			variables: {
				moduleId: globalContext.currentModule.id,
				teamId: globalContext.currentTeam?.id,
				offset: recordCount,
				yearMonth: `${startYear}-${startMonth}`,
				hasActions: filterRowsWithoutData,
				actionDashboardSearch: search,
			},
		})
		if (!recordData.data?.record_List) {
			return
		}

		let currErrors: React.ReactNode[] = []

		let prev: null | string = null
		let nextRows: RowType[] = [...rows]
		recordData.data.record_List?.objects.forEach((record) => {
			const template = templates[record.template.id]
			if (!template) {
				currErrors.push(
					<div key={`${record.id}-${record.template.id}`}>
						<b>{record.name}</b> has template <b>{record.template.id}</b> which doesn't exist in{' '}
						{globalContext.currentModule.name}.
					</div>,
				)
				return
			}
			const actionData: { [id: string]: ActionLineProps['data'] } = {}
			record.formActions.forEach((a) => {
				const k = a.form.id
				if (!(k in actionData)) {
					actionData[k] = makeEmptyData()
				}
				actionData[k].formActions.push(a)
			})
			record.formActionSchedules.forEach((a) => {
				const k = a.form.id
				if (!(k in actionData)) {
					actionData[k] = makeEmptyData()
				}
				actionData[k].formActionSchedules.push(a)
			})
			record.formActionEscalationEvents.forEach((a) => {
				const k = a.form.id
				if (!(k in actionData)) {
					actionData[k] = makeEmptyData()
				}
				actionData[k].formActionEscalationEvents.push(a)
			})

			template.formSet.forms.forEach((form) => {
				const data = actionData[form.id]
				if (!filterRowsWithoutData || data) {
					if (
						!search ||
						record.name.toLowerCase().includes(search.toLowerCase()) ||
						form.title.toLowerCase().includes(search.toLowerCase())
					) {
						nextRows.push({ record, form, showName: prev != record.id, data: actionData[form.id] })
						prev = record.id
					}
				}
			})
		})
		if (currErrors) {
			setErrors([...errors, ...currErrors])
		}

		setRows(nextRows)
		setRecordCount((prev) => prev + (recordData.data?.record_List?.objects.length || 0))
		setHasNextPage(recordData.data.record_List?.pageInfo.hasNextPage || false)
	}, [fetchRecords, setRows, rows, recordCount, setRecordCount])

	useEffect(() => {
		fetchChain()
	}, [])

	const observer = useRef<IntersectionObserver | null>(null)

	const loadMoreRef = useCallback(
		(node: HTMLDivElement) => {
			if (recordQuery.loading) {
				return
			}
			if (observer.current) {
				observer.current.disconnect()
			}

			observer.current = new IntersectionObserver((entries) => {
				if (entries[0].isIntersecting || entries[0].boundingClientRect.top < 0) {
					// Run function if the card is viewable, or above the bounding port
					if (hasNextpage) {
						fetchChain()
					}
				}
			})

			if (node) observer.current.observe(node)
		},
		[recordQuery.loading, hasNextpage, fetchChain],
	)

	return (
		<>
			{rows.length === 0 ?
				null // Empty state is shown below
			: mode === 'view' || mode === 'select' ?
				<SelectTable
					rows={rows}
					startYear={startYear}
					startMonth={startMonth}
					selectedRows={selectedRows}
					setSelectedRows={setSelectedRows}
					loadMoreRow={Math.max(rows.length - LOAD_MORE_AT, 0)}
					loadMoreRef={loadMoreRef}
					mode={mode}
					setMode={setMode}
					hasNextpage={hasNextpage}
					resetList={resetList}
				/>
			:	<EditTable allRows={rows} selectedRows={selectedRows} setMode={setMode} resetList={resetList} />}
			{recordQuery.loading &&
				Array.from(Array(6)).map((_, i) => (
					<Row key={i} left={<Placeholder />} right={<LineLoading />} />
				))}
			{!recordQuery.loading && rows.length == 0 && (
				<>
					<br />
					<br />
					<Empty />
				</>
			)}
			{errors.length > 0 && (
				<div className={generalStyle.muted}>
					<br />
					Hidden:
					<br />
					{errors}
				</div>
			)}
		</>
	)
}
