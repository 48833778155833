import React from 'react'
import c from 'classnames'
import style from './index.module.sass'

type Props = {
	style?: React.CSSProperties
	className?: string
}

export default (props: Props) => {
	return (
		<div className={c(style.placeholder, props.className)} style={props.style} />
	)
}