import React from 'react'
import { Modal, Tabs } from 'antd'
import { Route } from 'react-router-dom'
import { useGlobalContext } from '@/components/context'
import { PDF_CONTENT_TYPE } from '@/utils/constants'
import history from '@/utils/history'
import { emailModalHistoryUrl, emailModalSendUrl, formUrl } from '@/utils/url'
import EmailHistory from './history'
import EmailSend from './send'

type Props = {
	recordId: string
	formId: string
	regardingObjectId: string
	regardingObjectContentType: string
}

const TABS = {
	SEND: 'send',
	HISTORY: 'history',
}

const EmailModal = (props: Props) => {
	const globalContext = useGlobalContext()
	const historyRoute = emailModalHistoryUrl({
		...globalContext,
		...props,
	})
	const sendRoute = emailModalSendUrl({
		...globalContext,
		...props,
	})

	return (
		<Modal
			title={props.regardingObjectContentType == PDF_CONTENT_TYPE ? 'Pdf' : 'External link'}
			open
			maskClosable
			onCancel={() =>
				history.push(formUrl({ recordId: props.recordId, formId: props.formId, ...globalContext }))
			}
			footer={null}
		>
			<Tabs
				defaultActiveKey={window.location.pathname.includes('send') ? TABS.SEND : TABS.HISTORY}
				onChange={(key) => history.push(key === TABS.SEND ? sendRoute : historyRoute)}
				items={[
					{
						label: 'Send email',
						key: TABS.SEND,
						children: <Route path={sendRoute} component={() => <EmailSend {...props} />} />,
					},
					{
						label: 'Email history',
						key: TABS.HISTORY,
						children: <Route path={historyRoute} component={() => <EmailHistory {...props} />} />,
					},
				]}
			/>
		</Modal>
	)
}

export default EmailModal
