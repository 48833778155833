import gql from 'graphql-tag'
import React from 'react'
import { selectFormIsDirtyFromUserInteraction } from '@/state/answer/selectors'
import { getAnswersThunk } from '@/state/answer/thunks'
import { useFormContext } from '@/state/main-form/reducer'
import { showModal } from '@/state/modal/actions'
import { showNotification } from '@/state/user-interface'
import { useAppDispatch, useAppSelector } from '@/utils/hooks'
import buttonStyle from '@sass/common/button.module.sass'
import { useResetFormAnswersMutation } from './__gen/use-reset-form'

gql`
	mutation resetFormAnswers($formId: ID!, $recordId: ID!) {
		form_ResetAnswers(formId: $formId, recordId: $recordId) {
			success
			message
		}
	}
`

export const useResetForm = () => {
	const showSaveWarning = useAppSelector(selectFormIsDirtyFromUserInteraction)
	const formContext = useFormContext()
	const dispatch = useAppDispatch()
	const variables = {
		formId: formContext.form.id,
		recordId: formContext.record.id,
	}
	const [resetFormAnswersMutation] = useResetFormAnswersMutation({ variables })

	const showRestFormModal = () => {
		dispatch(
			showModal({
				title: 'Are you sure?',
				content: (
					<p>
						Resetting the form will delete data from the form: <b>{formContext.record.name}</b>. This action
						will reset all of the specific data for{' '}
						<b>
							{formContext.record.name} - {formContext.record.position}
						</b>
						. All other data will be retained. A final pdf of the current data will be created before the
						Reset is completed. Click <b>Reset Form</b> to continue.
					</p>
				),
				confirmClass: buttonStyle.red,
				confirmText: 'Reset Form',
				confirmAction: async () => {
					const r = await resetFormAnswersMutation()
					if (r.errors || !r.data?.form_ResetAnswers?.success) {
						dispatch(
							showNotification({
								content: `Error resetting form. If this issue persists, please contact support.`,
								type: 'error',
							}),
						)
						return
					}
					await dispatch(getAnswersThunk(variables))
					dispatch(
						showNotification({
							content: `This form has been successfully reset`,
						}),
					)
				},
			}),
		)
	}

	const showUnsavedChangesModal = () => {
		dispatch(
			showModal({
				title: 'Unable to reset form: Unsaved changes',
				content: `Please save the form or revert your changes by refreshing the page.`,
				cancelText: 'Cancel',
			}),
		)
	}

	const showResetModal = () => {
		if (showSaveWarning) {
			showUnsavedChangesModal()
		} else {
			showRestFormModal()
		}
	}
	return showResetModal
}
