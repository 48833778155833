import React from 'react'
import c from 'classnames'

import { AnswerTextBaseProps } from '../types'
import commonStyle from '../common.module.sass'
import style from './index.module.sass'
import { TextArea } from '@/components/common/inputs'
import { updateAnswersThunk } from '@/state/answer/thunks'
import { useAppDispatch } from '@/utils/hooks'


const AnswerTextLong = ({ answer, field, disabled }: AnswerTextBaseProps) => {
	const dispatch = useAppDispatch()

	if (disabled) {
		return (
			<div className={commonStyle.answerTextDisabled}>
				{answer.content || <i>No content</i>}
			</div>
		)
	}
	return (
		<TextArea
			className={c(style.textarea)}
			placeholder={field.placeholder || undefined}
			onChange={(e) => dispatch(updateAnswersThunk({ answers: [{ ...answer, content: e.target.value, }] }))}
			value={answer.content || ""}
		/>
	)
}


export default AnswerTextLong