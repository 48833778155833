import React from 'react'

import { SinglePanel } from '@/components/common/panel'

export const PublicFormSuccess = () => {
  	return (
		<SinglePanel type="thin">
			<br/>
			<h1>Thanks! <br/>Your form has been submitted.</h1>
		</SinglePanel>
  	)
}
