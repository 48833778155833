import gql from 'graphql-tag'

export const QUERY_MAIN_FORM_PUBLIC = gql`
	query mainFormPublic($publicFormCode: ID!) {
		publicFormPub(id: $publicFormCode) {
			id
			requestEmailOnSubmit
			formAction {
				id
				requestSignature
				form {
					pdfGenerators {
						id
						name
					}
				}
				isScheduled
			}
			record {
				id
				name
				archived
				position
				team {
					id
					organisation {
						id
					}
				}
			}
			form {
				id
				title
				publicLinkIsReadOnly
				publicLinkSubmitButtonText
				actionsFormat
				allowPublicLinks
				allowReset
				lockedFromFormAction(publicFormCode: $publicFormCode)
				hideControlPanel
				infoBlocks {
					id
					title
					titleSize
					contentHtml
					rank
					hasPaddingBefore
					hasPaddingAfter
					conditionalOption {
						id
						label
						field {
							id
						}
					}
				}
				fields(orderBy: [{field: rank}]) {
					id
					rank
					title
					type
					subtitle
					contentType
					placeholder
					allowMultipleAnswers
					conditionalOption {
						id
						label
						field {
							id
						}
					}
					disabledAlways
					disableWhenPublic
					disableWhenSigning
					disableEditingRowsWhenPublic
					disabledForFormActions(publicFormCode: $publicFormCode) {
						id
						record {
							id
							name
						}
						form {
							id
							title
						}
					}
					mandatory
					subsectionHeading
					hiddenFieldId
					placeholder
					canHide
					tableFields(orderBy: [{field: rank}]) {
						id
						title
						rank
						type
						disabledAlways
						disableWhenPublic
						disableWhenSigning
						placeholder
						helpInfo {
							id
							htmlContent
						}
						selectOptions {
							id
							label
							color
							order
						}
					}
					helpInfo {
						id
						htmlContent
					}
					selectOptions {
						id
						label
						color
						order
					}
				}
			}
		}
		initialAnswerPub_List {
			objects {
				id
				fieldId
				tableFieldId
				rank
				contentText
				contentDate
				contentBoolean
				contentDecimal
				contentFile
				contentFileName
			}
		}
	}
`
