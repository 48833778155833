import React, { useRef, useState } from 'react'
import _defer from 'lodash/defer'
import Select, { SelectInstance } from 'react-select'
import { Input } from '@/components/common/inputs'
import SimpleIconButton from '@/components/common/simple-icon-button'
import { updateAnswersThunk } from '@/state/answer/thunks'
import { FieldTypeEnum } from '@/state/main-form/types'
import { useAppDispatch } from '@/utils/hooks'
import iconStyle from '@sass/common/icon.module.sass'
import commonStyle from '../common.module.sass'
import { AnswerTextBaseProps } from '../types'
import style from './index.module.sass'
import { IOption, OTHER_OPTION, Option, colourStyles } from './option'

const AnswerDropDown = (props: AnswerTextBaseProps) => {
	const textInput = useRef<HTMLInputElement>(null)
	const innerSelect = useRef<SelectInstance<IOption>>(null)
	const dispatch = useAppDispatch()
	let [options, setOptions] = useState(() => {
		const options: IOption[] = props.field.selectOptions.map((o) => ({
			...o,
			value: o.label,
			labelDisplay: o.label,
		}))
		const noAnswerMatches =
			props.answer.content && !props.field.selectOptions.find((o) => o.label == props.answer.content)

		if (props.field.type == FieldTypeEnum.DropDownOther) {
			options.push(OTHER_OPTION)
		} else if (noAnswerMatches) {
			console.error(`Answer "${props.answer.content}" doesn't match an option for field ${props.field.title}`)
			options.push(OTHER_OPTION)
		}
		return options
	})
	let selected = options.find((o: IOption) => o.label == props.answer.content) || null

	let [isOther, setIsOther] = useState(props.answer.content && !selected)

	const reset = () => {
		dispatch(updateAnswersThunk({ answers: [{ ...props.answer, content: '' }] }))
		setIsOther(false)
		_defer(() => innerSelect.current?.focus())
	}

	const onDropdownChanged = (selectedOption: readonly IOption[] | IOption | null) => {
		selectedOption = selectedOption as IOption
		if (Array.isArray(selectedOption)) {
			throw 'No array allowed mate!'
		}
		if (selectedOption === OTHER_OPTION) {
			dispatch(updateAnswersThunk({ answers: [{ ...props.answer, content: '' }] }))
			setIsOther(true)
			_defer(() => textInput.current?.focus())
		} else if (selectedOption) {
			dispatch(updateAnswersThunk({ answers: [{ ...props.answer, content: selectedOption.label || '' }] }))
			setIsOther(false)
		}
	}

	return (
		props.disabled ?
			<div className={commonStyle.answerTextDisabled}>
				{props.answer.content || <i className={commonStyle.noContent}>No content</i>}
			</div>
		: isOther ?
			<div className={style.answerDropdownOtherContainer}>
				<Input
					className={commonStyle.formInput}
					ref={textInput}
					onChange={(e) =>
						dispatch(updateAnswersThunk({ answers: [{ ...props.answer, content: e.target.value }] }))
					}
					placeholder={props.field.placeholder || undefined}
					value={props.answer.content || ''}
				/>
				<SimpleIconButton iconClass={iconStyle.list} onClick={reset} title="See selectable options" />
			</div>
		:	<div className={style.answerDropdown}>
				<Select
					ref={innerSelect}
					isMulti={false}
					options={options}
					value={selected}
					onChange={onDropdownChanged}
					styles={colourStyles}
					placeholder={'Select...'}
					components={{ Option: Option }}
					isClearable={false}
					openMenuOnFocus
				/>
			</div>
	)
}

export default AnswerDropDown
