import React from 'react'
import c from 'classnames'
import { Link } from 'react-router-dom'
import { TitleSizeEnum } from '@/__gen/types'
import { useGlobalContext } from '@/components/context'
import { formUrl } from '@/utils/url'
import generalStyle from '@sass/common/general.module.sass'
import style from './index.module.sass'

type Props = {
	title: string
	titleSize: TitleSizeEnum
	contentHtml: string
	hasPaddingBefore: boolean
	hasPaddingAfter: boolean
	recordId: string
	isFirst: boolean
}

const OPEN_TAG = '<FormLink:'
const CLOSE_TAG = '</FormLink>'

export const InfoBlock = ({
	title,
	contentHtml,
	titleSize,
	recordId,
	isFirst,
	hasPaddingBefore,
	hasPaddingAfter,
}: Props) => {
	const globalContext = useGlobalContext()
	const reduceLink = (accumulator: React.ReactNode[], text: string) => {
		const matches = text.match(/^[0-9]+/g)
		const id = matches && matches[0]
		const split_text = text.split(CLOSE_TAG)
		const linkText = split_text[0].replace(/^[0-9]+./g, '')

		const nodes = [
			split_text.length > 0 && id ?
				<Link className={generalStyle.classicAnchor} to={formUrl({ recordId, formId: id, ...globalContext })}>
					{linkText}
				</Link>
			:	null,
			split_text.length > 1 ? <span dangerouslySetInnerHTML={{ __html: split_text[1] }} /> : null,
		]

		return [...accumulator, ...nodes]
	}

	const linky_bits = contentHtml.split(OPEN_TAG)

	const start =
		linky_bits.length > 0 ? <span dangerouslySetInnerHTML={{ __html: linky_bits.shift() || '' }} /> : null
	const html = linky_bits.reduce(reduceLink, [start])

	return (
		<div
			className={c(style.container, {
				[style.removeTopPadding]: isFirst || !hasPaddingBefore,
				[style.removeBottomPadding]: !hasPaddingAfter,
			})}
		>
			{title && <div className={c(style.title, { [style.titleLarge]: titleSize === 'LARGE' })}>{title}</div>}
			{contentHtml && (
				<div className={style.contentHtml}>
					{html.map((h, i) => (
						<React.Fragment key={i}>{h}</React.Fragment>
					))}
				</div>
			)}
		</div>
	)
}
