import React, { useState } from 'react'
import { SignatureType } from './common'
import { Modal } from 'antd'
import { SpinnerButton } from '@/components/common/button'

import history from '@/utils/history'
import { Signature } from './signature'
import { formUrl, publicFormSuccessUrl, publicFormUrl } from '@/utils/url'
import { useGlobalContext } from '@/components/context'
import { useFormContext } from '@/state/main-form/reducer'
import { useComplete } from '../query'

import style from './index.module.sass'
import { useAppDispatch } from '@/utils/hooks'
import { showErrorsAndFlush } from '@/state/answer/thunks'


type Props = {
	formAction: {
		id: string;
		requestSignature: boolean;
		form: {
			pdfGenerators: {
				id: string
				name: string
			}[]
		}
		isScheduled: boolean
	}
}


export type { SignatureType } from './common'

export const CompleteModal = ({ formAction }: Props) => {
	const dispatch = useAppDispatch()
	const globalContext = useGlobalContext()
	const formContext = useFormContext()
	const code = formContext.publicForm?.code || null
	const [signature, setSignature] = useState<SignatureType | null>(null)
	const { completeMutation, loading } = useComplete({ id: formAction.id, code, signature })

	const backUrl = formContext.publicForm?.code
		? publicFormUrl(formContext.publicForm.code)
		: formUrl({...globalContext, formId: formContext.form.id, recordId: formContext.record.id})

	const onSubmit = async () => {
		const valid = await dispatch(showErrorsAndFlush({}))

		if (!valid) {
			return
		}

		const resp = await completeMutation()
		if (resp.errors) {
			alert('Error completing action')
			return
		}

		const doneUrl = formContext.publicForm?.code ? publicFormSuccessUrl() : backUrl
		history.push(doneUrl)
	}

	return (
		<Modal
			title={formAction.requestSignature ? 'Adopt & Sign' : 'Complete Form'}
			open
			width={650}
			footer={null}
			onCancel={() => history.push(backUrl)}
		>
			<div className={style.modalContainer}>
				<p>
					Complete your action for <b>{formContext.form.title}</b>.&nbsp;
					{formContext.publicForm?.code &&
						<> After submitting you will no longer be able to view or edit this document. </>
					}
					{formAction.isScheduled && <>This form will reset when all action are completed and the next schedule cycle begins.</>}
					&nbsp;
				</p>

				{formAction.requestSignature &&
					<Signature setSignature={setSignature} signature={signature} pdfGenerators={formAction.form.pdfGenerators} />
				}

				<div style={{ marginTop: 10, width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
					<SpinnerButton
						loading={loading}
						onClick={onSubmit}
						disabled={formAction.requestSignature && !signature}
					>
						{formAction.requestSignature ? "Adopt and Sign" : "Submit"}
					</SpinnerButton>
				</div>
			</div>
		</Modal>
	)
}