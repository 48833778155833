import React, { useContext } from 'react'
import c from 'classnames'
import gql from "graphql-tag"

import { useRouter } from '@/components/context'
import { showNotification } from '@/state/user-interface'
import { useAppDispatch } from '@/utils/hooks'
import { userManagementAddUrl } from '@/utils/url'
import buttonStyle from '@sass/common/button.module.sass'
import iconStyle from '@sass/common/icon.module.sass'
import { UserListContext } from '../context'
import { useDeleteUserMutation } from './__gen/delete-button'
import { UserToManageQuery } from './__gen/edit'

type UserType = NonNullable<UserToManageQuery["user"]>


gql`
	mutation deleteUser(
		$id: ID!,
	) {
		user_Update(input: {
			id: $id,
			isActive: false
		}) {
			object {
				id
				isActive
			}
		}
	}
`

type Props = {
	user: UserType
}

export default (props: Props) => {
	const [deleteUser, {}] = useDeleteUserMutation()
	const dispatch = useAppDispatch()
	const router = useRouter()
	const refetchList = useContext(UserListContext)

	return (
		<div>
			<button
				type="button"
				className={c(buttonStyle.small, buttonStyle.grey, iconStyle.bin)}
				onClick={() => deleteUser({variables: {id: props.user.id}}).then(r => {
					if(r.errors) {
						dispatch(showNotification({content: `Error`, type: 'error'}))
					} else {
						router.history.push(userManagementAddUrl())
						refetchList()
						dispatch(showNotification({content: `User deleted`}))
					}
				})}
			>
				Delete
			</button>
		</div>
	)
}