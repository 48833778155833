import React from 'react'
import { Route, RouteProps } from 'react-router-dom'

import { useGlobalContext } from '@/components/context'
import { teamAggregatedTableUrl, teamConcatenatedTableUrl, teamMetricsUrl, calendarUrl, formActionMetricsUrl, GLOBAL_CONTEXT_PARAMS, mapUrl, AnalyticsRoutes } from '@/utils/url'
import ModuleDashboard from './dashboard/module-dashboard'
import AggregatedTable from './aggregated-table'
import Calendar from './calendar'
import Map from './map'
import ConcatenatedTable from './concatenated-table'
import style from './index.module.sass'
import TopRow from './top-row'
import { FormActionManagement } from './form-action-management'


// We use a typed map here to ensure we stay in sync with ANALYTICS_ROUTE_TRANSLATION
export const ROUTES: {[k in AnalyticsRoutes]: RouteProps} = {
	ModuleDashboard: {
		path: teamMetricsUrl(GLOBAL_CONTEXT_PARAMS),
		component: ModuleDashboard
	},
	FormActionMetrics: {
		path: formActionMetricsUrl({startYear: ':startYear', startMonth: ':startMonth', ...GLOBAL_CONTEXT_PARAMS}),
		component: FormActionManagement
	},
	Calendar: {
		path: calendarUrl({...GLOBAL_CONTEXT_PARAMS, month: ':month', year: ':year'}),
		component: Calendar
	},
	Map: {
		path: mapUrl(GLOBAL_CONTEXT_PARAMS),
		component: Map
	},
	ConcatenatedTable: {
		path: teamConcatenatedTableUrl({ tableId: ':id', ...GLOBAL_CONTEXT_PARAMS}),
		component: ConcatenatedTable
	},
	AggregatedTable: {
		path: teamAggregatedTableUrl({ tableId: ':id', ...GLOBAL_CONTEXT_PARAMS}),
		component: AggregatedTable
	},
}

const Analytics = () => {
	const globalContext = useGlobalContext()

	return (
		<div className={style.container} key={globalContext.currentTeam && globalContext.currentTeam.id || undefined}>
			<TopRow />
			{Object.values(ROUTES).map((r, i) => <Route key={i} {...r} />)}
		</div>
	)
}

export default Analytics